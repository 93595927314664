import React, { useEffect, useState, useCallback } from 'react';
import { CustomerDataDropDown, ProjectsData } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import { Button } from '../../../components/button';
import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/16/solid';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/dropdown';
import { Input, InputGroup } from '../../../components/input';
import FunnelIcon from '@heroicons/react/20/solid/FunnelIcon';
import { Select } from '../../../components/select';
import { projectStatusFilterMenu } from '../../../utils/data';
import ProjectList from './projectList';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getProjectsList, getProjectsListByStatus, restoreProjectDeleted } from '../../../utils/apicall/projectsApi';
import { getCustomerTitle } from '../../../utils/function';

const ProjectDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<ProjectsData[]>([]);
  const [projectsDeleted, setProjectsDeleted] = useState<ProjectsData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredProjects, setFilteredProjects] = useState<ProjectsData[]>([]);
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>('0');

  const loadProjects = useCallback(async () => {
    try {
      const data = await getProjectsList();
      setProjects(data);
      setFilteredProjects(data);
    } catch (error) {
      console.error('Failed to fetch projects', error);
    }
  }, []);

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer', error);
    }
  }, []);

  const handleCreate = () => {
    navigate(CONFIG.urls.getProjectAddUrl());
  };


  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const valueToFilter = e.target.value;
    setSelectedFilter(valueToFilter);
    
  };



  const loadListByStatus = useCallback(async (status: string) => {
    try {
      if (status === 'trashed') {
        const data = await getProjectsListByStatus("trashed");
        setProjectsDeleted(data);
        setFilteredProjects(data);
      } else if (status === '0') {
        const data = await getProjectsList();
        setFilteredProjects(data);
      } else {
        const data = await getProjectsListByStatus(status);
        setFilteredProjects(data);
      }
    } catch (error) {
      console.error('Failed to fetch Project List By status', error);
    }
  }, []);

  const restoreProjectDel = (projectid: number) => async () => {
    try {
      await restoreProjectDeleted(projectid);
      loadListByStatus('trashed');
    } catch (error) {
      console.error('Failed to restore Task', error);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
  setSearchQuery(query);

  if (query) {
    const filtered = projects.filter((project) => {
      const customerTitle = getCustomerTitle(project.customer_id!, customerdropdown);

      return (
        (project.title && project.title.toLowerCase().includes(query)) ||
        (customerTitle && customerTitle.toLowerCase().includes(query)) ||
        (project.domain && project.domain.toLowerCase().includes(query))
      );
    });
    setFilteredProjects(filtered);
  } else {
    setFilteredProjects(projects);
  }
  };

  useEffect(() => {
    loadProjects();
    loadCustomerDropdown();
  }, [loadProjects, loadCustomerDropdown]);

;


  useEffect(() => {
        loadListByStatus(selectedFilter);
  }, [loadListByStatus, selectedFilter]);


  const heading = selectedFilter === 'trashed' ? 'Διαγραμμένα Έργα' : 'Έργα';

  return (
    <div >
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>{heading}</Heading>
        <div className="flex">
          <Button plain onClick={handleCreate}><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
            <DropdownMenu anchor="bottom end">
              <DropdownItem onClick={(e: { preventDefault: () => any; }) => e.preventDefault()}>
                <Select
                  id="filterbystatus"
                  name="filterbystatus"
                  value={selectedFilter}
                  onChange={handleFilterChange}
                >
                  {projectStatusFilterMenu.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </Select>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
      <div className="flex mt-4 mb-4">
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            type="search"
            name="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search..."
          />
        </InputGroup>
      </div>
      
      {selectedFilter === 'trashed' ? (
        projectsDeleted.length > 0 ? (
          <ProjectList
            filteredData={projectsDeleted}
            customerdropdown={customerdropdown}
            dataDeleted={true}
            onRestoreData={(ID: any) => restoreProjectDel(ID!)}
          />
        ) : (
          <div>No deleted projects</div>
        )
      ) : (
        filteredProjects.length > 0 ? (
          <ProjectList
            filteredData={filteredProjects}
            customerdropdown={customerdropdown}
            dataDeleted={false}
          />
        ) : (
          <div>No projects found</div>
        )
      )}
    </div>
  );
};

export default ProjectDashboard;
