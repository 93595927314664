import React, { useState } from 'react';
import { InputWithDatalist } from '../components/input-datalist';
 // Adjust the import path as needed

const UserPage: React.FC = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<string | number | null>('2');

  const list = [
    { id: '1', value: 'Customer 1' },
    { id: '2', value: 'Customer 2' },
    { id: '3', value: 'Customer 3' }
    // Add more customers as needed
  ];

  const handleChange = (e: { target: { name: string; value: string | number; } }) => {
    const { value } = e.target;
    console.log('setSelectedCustomer(value);---', value);
    setSelectedCustomer(value);
  };

  // Find the current value based on the selectedCustomer ID
  const currentValue = list.find(option => option.id === selectedCustomer)?.value || '';

  return (
    <div>
      <InputWithDatalist
        name="customer_id"
        id="customer_id"
        placeholder="Select a customer"
        aria-label="customer selection"
        options={list} // Use the list of options
        onChange={handleChange}
        value={currentValue} // Set the value based on selectedCustomer
        required
      />
    </div>
  );
};

export default UserPage;
