import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Badge } from '../../../components/badge';
import { Button } from '../../../components/button';
import {  getCustomerTitle, getProjectStatusName, getProjectStatusColor } from '../../../utils/function';
import {  CustomerDataDropDown, ProjectsData } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { CONFIG } from '../../../utils/config';
import { TextLink } from '../../../components/text';

interface ProjectListProps {
  filteredData: ProjectsData[];
  customerdropdown:CustomerDataDropDown[];
  dataDeleted?: boolean;
  onRestoreData?: (ID: number) => void;
}

enum SortableColumn {
  Title = 'title',
  Customer = 'customer',
  Status = 'status'
}

const ProjectList: React.FC<ProjectListProps> = ({ filteredData,customerdropdown, dataDeleted, onRestoreData }) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedProjects = React.useMemo(() => {
    let sortableProjects = [...filteredData];
    if (sortConfig !== null) {
      sortableProjects.sort((a, b) => {
        let aValue: string = '';
        let bValue: string = '';
        switch (sortConfig.key) {
          case SortableColumn.Title:
            aValue = a.title ?? '';
            bValue = b.title ?? '';
            break;
          case SortableColumn.Customer:
            const customerA = getCustomerTitle(a.customer_id!, customerdropdown);
            const customerB = getCustomerTitle(b.customer_id!, customerdropdown);
            aValue = customerA;
            bValue = customerB;
            break;
          case SortableColumn.Status:
            aValue = getProjectStatusName(a.status.toString()!);
            bValue = getProjectStatusName(b.status.toString()!);
            break;
          default:
            return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProjects;
  }, [filteredData, sortConfig, customerdropdown]);
  
  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>ID</TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Title)} className={getClassNamesFor(SortableColumn.Title)}>
              Τίτλος
              {sortConfig?.key === SortableColumn.Title && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Customer)} className={getClassNamesFor(SortableColumn.Customer)}>
              Πελάτης
              {sortConfig?.key === SortableColumn.Customer && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader>Domain</TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Status)} className={getClassNamesFor(SortableColumn.Status)}>
              Status
              {sortConfig?.key === SortableColumn.Status && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader className="relative w-0">
              <span className="sr-only">Actions</span>
            </TableHeader>
            {dataDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedProjects.map((item) => (
            <TableRow key={item.id}>
             
              
              {dataDeleted && onRestoreData ? (
                <>
                 <TableCell className="font-medium">{item.id}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.title}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{getCustomerTitle(item.customer_id!, customerdropdown)}</TableCell>
                </>
              ) : (
                <>
                 <TableCell className="font-medium"><TextLink href={CONFIG.urls.getProjectViewUrl(item.id!)}>{item.id}</TextLink></TableCell>
                  <TableCell className="font-medium whitespace-normal"><TextLink href={CONFIG.urls.getProjectViewUrl(item.id!)}>{item.title}</TextLink></TableCell>
                  <TableCell className="font-medium whitespace-normal"><TextLink href={CONFIG.urls.getCustomerViewUrl(item.customer_id!)}>{getCustomerTitle(item.customer_id!, customerdropdown)}</TextLink></TableCell>
                </>
              )}
              
              <TableCell className="font-medium whitespace-normal">{item.domain}</TableCell>
              <TableCell className="font-medium whitespace-normal"><Badge color={getProjectStatusColor(item.status!)!}>{getProjectStatusName(item.status.toString()!)}</Badge></TableCell>
              
              {dataDeleted && onRestoreData ? (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreData(item.id!) }}>Restore</Button>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                    <Dropdown>
                      <DropdownButton plain aria-label="More options">
                        <EllipsisVerticalIcon />
                      </DropdownButton>
                      <DropdownMenu anchor="bottom end">
                        <DropdownItem href={CONFIG.urls.getProjectViewUrl(item.id!)}>View</DropdownItem>
                        <DropdownItem href={CONFIG.urls.getProjectEditUrl(item.id!)}>Edit</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ProjectList;
