import React, { useEffect, useState } from 'react';
import AlertViewContent from './dalertViewContentList'; // Adjust the import path as needed
import { CustomerDataDropDown, DalertData } from '../../../utils/types';
import { dalertsStatus } from '../../../utils/data';
import { TabNavigation, TabSection, TabItem, TabSpacer } from '../../../components/tab-navigation';
import { Heading } from '../../../components/heading';
import { Button } from '../../../components/button';
import { PlusIcon } from '@heroicons/react/16/solid';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getAlertsDataStatus } from '../../../utils/apicall/dalertsApi';
import AlertViewContentList from './dalertViewContentList';

const AlertViewTab: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>(dalertsStatus[0].value);
  const [Dalerts5, setDalerts5] = useState<DalertData[]>([]);
  const [Dalerts9, setDalerts9] = useState<DalertData[]>([]);
  const [Dalerts10, setDalerts10] = useState<DalertData[]>([]);
  const [Dalerts99, setDalerts99] = useState<DalertData[]>([]);
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);

  const renderContent = () => {
    switch (activeTab) {
      case dalertsStatus[0].value:
        return <AlertViewContentList filteredData={Dalerts5} customerdropdown={customerdropdown} type={dalertsStatus[0].value} />;
      case dalertsStatus[1].value:
        return <AlertViewContentList filteredData={Dalerts9} customerdropdown={customerdropdown} type={dalertsStatus[1].value} />;
      case dalertsStatus[2].value:
        return <AlertViewContentList filteredData={Dalerts10} customerdropdown={customerdropdown} type={dalertsStatus[2].value} />;
      case dalertsStatus[3].value:
        return <AlertViewContentList filteredData={Dalerts99} customerdropdown={customerdropdown} type={dalertsStatus[3].value} />;
      default:
        return null;
    }
  };

  const loadAlertsData = async (id: string) => {
    try {
      const data = await getAlertsDataStatus(id);
      switch (id) {
        case "5":
          setDalerts5(data);
          break;
        case "9":
          setDalerts9(data);
          break;
        case "10":
          setDalerts10(data);
          break;
        case "99":
          setDalerts99(data);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Failed to fetch alert', error);
    }
  };

  const loadCustomerDropdown = async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer data', error);
    }
  };

  useEffect(() => {
    loadAlertsData("5");
    loadAlertsData("9");
    loadAlertsData("10");
    loadAlertsData("99");
    loadCustomerDropdown();
  }, []);

  return (
    <div>
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Ειδοποιητήρια</Heading>
          </div>
    <TabNavigation>
      <TabSection>
        {dalertsStatus.map(tab => (
          <TabItem
            key={tab.value}
            current={activeTab === tab.value}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.label}
          </TabItem>
        ))}
      </TabSection>
      <TabSpacer />
    </TabNavigation>
    <div className="tab-content">
      {renderContent()}
    </div>
  </div>
  );
};

export default AlertViewTab;
