import React, { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import UserLayout from './UserLayout';
import AdminLayout from './AdminLayout';
import DefaultLayout from './DefaultLayout';
import { RootState } from '../redux/reducers';

interface LayoutWrapperProps {
  children: ReactNode;
}

const LayoutWrapper: React.FC<LayoutWrapperProps> = ({ children }) => {
  const role = useSelector((state: RootState) => state.auth.role);
  
  useEffect(() => {
    console.log('LayoutWrapper rendered. Role:', role);
  }, [role]);

  
  if (role === 'admin') {
    return <AdminLayout>{children}</AdminLayout>;
  }else if (role === 'user') {
    return <UserLayout>{children}</UserLayout>;
  }else if (role === 'client') {
    return <UserLayout>{children}</UserLayout>;
  }else if (role === 'guest') {
    return <DefaultLayout>{children}</DefaultLayout>;
  }else{
    return <DefaultLayout>{children}</DefaultLayout>;
  }

 
};

export default LayoutWrapper;