import axios from 'axios';
import {  UserData } from '../types';
import { apiClient } from './apiClient';

//**********************************************/
// ****  GET USER DATA
//**********************************************/
export const getUserData = async (token: string) => {
  try {
    const response = await apiClient.get<UserData>('/user');

    return response.data;

  } catch (error) {
    
    return null;
  }
};


