import axios from 'axios';
import {DalertData, HostingDataDropDown, SitesData } from '../types';
import { apiClient } from './apiClient';


  /**********************************************/
// ****  Get Sites List
//**********************************************/

export const getSitesList = async (): Promise<SitesData[]> => {
  try {
    const response = await apiClient.get<SitesData[]>('/admin/sites');

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

  /**********************************************/
// **** View Single Details
//**********************************************/


export const getSitesDetails = async (id: string): Promise<SitesData> => {
  try {
    const response = await apiClient.get<SitesData>(`/admin/sites/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
   
    console.error(error)
    throw error;
    };
  };

  export const getSitesDetails1 = async (id: string): Promise<SitesData> => {
    try {
      const response = await apiClient.get<SitesData>(`/admin/sites/${id}`);
      const data = response.data;
      return data;
    } catch (error) {
     
      console.error(error)
      throw error;
      };
    };

//**********************************************/
// ****  Add Sites
//**********************************************/

export const CreateSites = async (item: any) => {
  try {
    const response = await apiClient.post('/admin/sites', item);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};
//**********************************************/
// ****  Add Hosting Data DropDown
//**********************************************/
export const getHostingDataDropDown = async (): Promise<HostingDataDropDown[]> => {
  try {
    const response = await apiClient.get<HostingDataDropDown[]>('/admin/hostings-dropdown');

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};


//**********************************************/
// ****  UPDATE ADMINS Subscription DATA 
//**********************************************/
export const updateSites = async (id: number, item: any): Promise<SitesData> => {
  try {
    console.log("updateSites ", id);
    console.log("updateSites ",item);
    
    const response = await apiClient.patch<SitesData>(`/admin/sites/${id}`, item);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};