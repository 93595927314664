import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerDataDropDown, HostingDataDropDown, mailogsData, DalertDetailData, DalertData } from '../../../utils/types';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { DescriptionList, DescriptionTerm, DescriptionDetails } from '../../../components/description-list';
import { Heading } from '../../../components/heading';
import { TextLink } from '../../../components/text';
import { PlusIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Button } from '../../../components/button';
import { getAlertsDetails, createInvoice, renewSubscriptions } from '../../../utils/apicall/dalertsApi';
import DAlertsDetailList from './dalertDetailList';
import AlertMailogsList from './dalertMailLogList';
import WaitAlert from '../../common/alertdialog/waitAlert';
import { processError } from '../../../utils/error/errorUtils';
import { defaultDalertData } from '../../../utils/data';
import { getHostingDataDropDown } from '../../../utils/apicall/sitesApi';
import { getCustomerTitle, FormatDisplayDateString } from '../../../utils/function';
import QuestionAlert from '../../common/alertdialog/questionAlert';

const AlertsView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [hostingdropdown, setHostingDropdown] = useState<HostingDataDropDown[]>([]);
  const [isDeleteDataOpen, setDeleteDataOpen] = useState(false);
  const [DataID, setDataID] = useState<number>(0);
  const [data, setData] = useState<DalertData>(defaultDalertData);
  const [dalertdetailData, setDalertdetailData] = useState<DalertDetailData[]>([]);
  const [mailogsData, setMailogsData] = useState<mailogsData[]>([]);
  const [isAlertCreateInvoiceOpen, setIsAlertCreateInvoiceOpen] = useState(false);
  const [isWaitAlertOpen, setIsWaitAlertOpen] = useState(false);
  const [TitleAlert, setTitleAlert] = useState<string>("");
  const [DescriptionAlert, setDescriptionAlert] = useState<string>("");
  const [titleButtonAccept, setTitleButtonAccept] = useState<string>("");
  const [titleButtonCancel, setTitleButtonCancel] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [isDisable, setIsDisable] = useState<string>(""); // Loading state
  const [isAlertRenewOpen, setIsAlertRenewOpen] =  useState(false); // Loading state

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer dropdown', error);
    }
  }, []);

  const loadDataDetails = useCallback(async (id: string) => {
    try {
      const data = await getAlertsDetails(id);
      setData(data);
      if (data) {
        setDalertdetailData(data.dalert_detail!);
        setMailogsData(data.mailogs!);
      }
      console.log('Alerts data:', data);
    } catch (error) {
      console.error('Failed to fetch Alerts', error);
    }
  }, []);

  const loadHostingDropdown = useCallback(async () => {
    try {
      const data = await getHostingDataDropDown();
      setHostingDropdown(data);
    } catch (error) {
      console.error('Failed to fetch hosting', error);
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadCustomerDropdown();
      loadHostingDropdown();
      loadDataDetails(id);
    }
  }, [id, loadCustomerDropdown, loadHostingDropdown, loadDataDetails]);


 //******OPEN CREATE INVOICE ALERT ************/
  const handleOpenCreateInvoiceAlert = () => {
    setTitleAlert("Δημιουργία Τιμολογίου");
    setDescriptionAlert(`Εστε βέβαιοι ότι θέλετε να δημιουργήσετε τιμολόγιο για το ειδοποιητήριο ID ${id}? Εαν θέλετε να προχωρήσετε, πατήστε επιβεβαίωση.`);
    setTitleButtonCancel("Ακύρωση");
    setTitleButtonAccept("Επιβεβαίωση");
    setIsAlertCreateInvoiceOpen(true);
  };
//******CLOSE CREATE INVOICE ALERT ************/
  const handleCloseCreateInvoiceAlert = () => {
    setIsAlertCreateInvoiceOpen(false);
  };
 //******OPEN WAIT ALERT ************/
  const handleOpenWaitAlert = () => {
    setTitleAlert("Δημιουργία Τιμολογίου");
    setDescriptionAlert("Παρακαλώ περιμένετε ...");
    setTitleButtonCancel(""); // Hide the cancel button during the waiting period
    setTitleButtonAccept("Οκ"); // Button to close the alert after completion
    setIsDisable("true");
    setIsWaitAlertOpen(true);
  };
//******CLOSE WAIT ALERT ************/
  const handleCloseWaitAlert = () => {
    setIsWaitAlertOpen(false);
  };
  //******OPEN RENEW ************/




  const handleOpenRenewAlert  = () => {
    setTitleAlert("Ανανέωση Συνδρομών");
    setDescriptionAlert(`Εστε βέβαιοι ότι θέλετε να ανανεώσετε τις συνδρομές για το ειδοποιητήριο ID ${id}? Εαν θέλετε να προχωρήσετε, πατήστε επιβεβαίωση.`);
    setTitleButtonCancel("Ακύρωση");
    setTitleButtonAccept("Επιβεβαίωση");
    setIsAlertRenewOpen(true);
  };
//******CLOSE Renew ALERT ************/
const handleCloseRenewAlert = () => {
  setIsAlertRenewOpen(false);
};

 //******OPEN WAIT ALERT FOR RENEW  ************/
 const handleOpenWaitRenewAlert = () => {
  setTitleAlert("Ανανέωση Συνδρομών");
  setDescriptionAlert("Παρακαλώ περιμένετε ...");
  setTitleButtonCancel(""); // Hide the cancel button during the waiting period
  setTitleButtonAccept("Οκ"); // Button to close the alert after completion
  setIsDisable("true");
  setIsWaitAlertOpen(true);
};


//******RENEW SUBSCRIPTION ************/
const handleAcceptRenewAlert = () => {
  handleCloseRenewAlert();
  handleOpenWaitRenewAlert();
  if (id) {
    renewSubscriptionAlert(id);
    //createInvoiceAlert(id);
  }
};
  
   const renewSubscriptionAlert = async (alertId: string) => {
    setErrorMessage('');
    setSuccessMessage('');
   try {
    console.log(alertId);
    const response = await renewSubscriptions(alertId);
     if (response.message) {
         setDescriptionAlert('');
         setSuccessMessage(response.message);
         setIsDisable("false");
    }else{
      setDescriptionAlert('');
      setSuccessMessage(response);
      setIsDisable("false");
    }
  
  
   } catch (error) {
     processError(error, setErrors, setErrorMessage, setSuccessMessage);
     
     setDescriptionAlert('');
     setSuccessMessage('');
     setIsDisable("false");
   } finally {
  

     
   }
 };
//******END RENEW SUBSCRIPTION ************/

 //******CREATE INVOICE ************/
  const handleAcceptCreateInvoiceAlert = () => {
    handleCloseCreateInvoiceAlert();
    handleOpenWaitAlert();
    if (id) {
    
      createInvoiceAlert(id);
    }
  };

 
  const createInvoiceAlert = async (alertId: string) => {
     setErrorMessage('');
     setSuccessMessage('');
    try {
    console.log(alertId);
   const response = await createInvoice(alertId);
      if (response.success) {
          setDescriptionAlert('');
          setSuccessMessage("Δημιουργήθηκε το τιμολόγιο");
          setIsDisable("false");
      }else{
        setDescriptionAlert('');
        setSuccessMessage(response);
        setIsDisable("false");
      }
    } catch (error) {
      processError(error, setErrors, setErrorMessage, setSuccessMessage);
      
      setDescriptionAlert('');
      setSuccessMessage('');
       setIsDisable("false");
    } finally {
   
 
      
    }
  };

 //******END CREATE INVOICE ************/

  return (
    <div className="container mx-0 p-4">
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>{data.id}</Heading>
        <div className="flex gap-1">
          <Button
            color="blue"
            onClick={handleOpenCreateInvoiceAlert}
          >
            Έκδοση Τιμολογίου
          </Button>
          <Button color="green"  onClick={handleOpenRenewAlert}>Ανανέωση Συνδρομών</Button>
        </div>
       
     
        <QuestionAlert
          open={isAlertCreateInvoiceOpen}
          onClose={() => handleCloseCreateInvoiceAlert()}
          title={TitleAlert}
          description={DescriptionAlert}
          onCreateAccept={handleAcceptCreateInvoiceAlert}
          titlebuttonAccept={titleButtonAccept}
          titlebuttonCancel={titleButtonCancel}
        />
      
        <WaitAlert
          open={isWaitAlertOpen}
          onClose={() => handleCloseWaitAlert()}
          title={TitleAlert}
          description={DescriptionAlert}
          isDisabled ={isDisable}
          id={parseInt(id || '')}
          onCreateAccept={handleCloseWaitAlert}
          titlebuttonAccept={titleButtonAccept}
          successMessage= {successMessage||''}
          errorMessage= {errorMessage||''}
        />
      
        <QuestionAlert
          open={isAlertRenewOpen}
          onClose={() => handleCloseRenewAlert()}
          title={TitleAlert}
          description={DescriptionAlert}
          onCreateAccept={handleAcceptRenewAlert}
          titlebuttonAccept={titleButtonAccept}
          titlebuttonCancel={titleButtonCancel}
        />    
      
      </div>

      <DescriptionList>
        <DescriptionTerm>Πελάτης</DescriptionTerm>
        <DescriptionDetails>
          <TextLink href={CONFIG.urls.getCustomerViewUrl(data.customer_id!)}>{getCustomerTitle(data.customer_id, customerdropdown)}</TextLink>
        </DescriptionDetails>
        <DescriptionTerm>Ημ.Λήξης</DescriptionTerm>
        <DescriptionDetails>{FormatDisplayDateString(data.due_date)}</DescriptionDetails>
        <DescriptionTerm>Σύνολο</DescriptionTerm>
        <DescriptionDetails>{data.total_due} €</DescriptionDetails>
        <DescriptionTerm>ΦΠΑ</DescriptionTerm>
        <DescriptionDetails>{data.vat_total} €</DescriptionDetails>
        <DescriptionTerm>Γενικό σύνολο </DescriptionTerm>
        <DescriptionDetails>{data.total} €</DescriptionDetails>
      </DescriptionList>

      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>Λεπτομέρειες</Heading>
        <div className="-mx-3 -my-1.5 sm:-mx-2.5" style={{ display: 'none' }}>
          <Button plain><PlusIcon /></Button>
        </div>
      </div>

      <DAlertsDetailList
        filteredData={dalertdetailData}
        dataDeleted={false}
      />

      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>Mail Log</Heading>
        <div className="-mx-3 -my-1.5 sm:-mx-2.5" style={{ display: 'none' }}>
          <Button plain><PlusIcon /></Button>
        </div>
      </div>

      <AlertMailogsList
        filteredData={mailogsData}
        dataDeleted={false}
      />
    </div>
  );
};

export default AlertsView;
