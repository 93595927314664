// NotFound.tsx
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className="text-center mt-10">
      <h1 className="text-4xl font-bold">404 - H σελίδα δεν βρέθηκε</h1>
      <p className="text-lg mt-4">H σελίδα που ψάχνετε δεν βρέθηκε.</p>
    </div>
  );
};

export default NotFound;