import React, { useCallback, useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import {   SitesData } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import { FieldGroup, FieldSameLine, Fieldset, Label, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { Select } from '../../../components/select';
import { defaultSitesData, sitesType } from '../../../utils/data';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { processError } from '../../../utils/error/errorUtils';
import { getSitesDetails, getHostingDataDropDown, updateSites } from '../../../utils/apicall/sitesApi';
import { Textarea } from '../../../components/textarea';
import ComboboxComponent from '../../../components/comboboxNew';
import { CONFIG } from '../../../utils/config';

interface DropdownOption {
  id: number;
  value: string;
}

const SitesEdit: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {id} = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] =useState<any>();
  const [hostingdropdown, setHostingDropdown] =useState<any>();
  const [data, setData] = useState<SitesData>(defaultSitesData);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [siteID, setSiteID] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);
  const [selectedHosting, setSelectedHosting] = useState<DropdownOption | null>(null);

//******************************** */
//  GET CUSTOMER DROPDOWN
//******************************** */
 

const loadCustomerDropdown = useCallback(async () => {
  try {
    const data = await getCustomersDataDropDown();
    if (data && Array.isArray(data)) {
      const options = data.map(customer => ({
        id: customer.id,
        value: customer.title,
      }));
      setCustomerDropdown(options);
    }
  } catch (error) {
    console.error('Failed to fetch customer dropdown', error);
  }
}, []);


  const loadHostingDropdown = useCallback(async () => {
    try {
      const data = await getHostingDataDropDown();
      const options = data.map(data => ({
        id: data.id,
        value: data.title,
      }));
      setHostingDropdown(options);
    } catch (error) {
      console.error('Failed to fetch hosting', error);
    }
  }, []);

//******************************** */
//  GET SUBSCRIPTION
//*********************************/
  const loadDataDetails = useCallback(async (id: string) => {
    try {
    
      const data = await getSitesDetails(id);
      
       setData(data);
       console.log(customerdropdown);
       console.log(hostingdropdown);
       const selectedCustomerOption = customerdropdown.find((customer: { id: number; }) => customer.id === data.customer_id) || null;
       const selectedHostingOption = hostingdropdown.find((hosting: { id: number; }) => hosting.id === data.hosting_id) || null;
 
       setSelectedCustomer(selectedCustomerOption);
       setSelectedHosting(selectedHostingOption);


       console.log(selectedHostingOption);
       console.log(selectedCustomerOption);
       console.log(data.customer_id);
    } catch (error) {
      console.error('Failed to fetch sites', error);
      // Handle error by setting subscription to an empty array
    }
  }, [customerdropdown, hostingdropdown]);



//******************************** */
// Handle Change
//******************************** */
  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    let valueToPass: string | number = value;

      console.log('handleChange  valueToPass'+name, valueToPass);
      setData({
        ...data,
        [name]: value
      });



}




//******************* */
// UPDATE FORM
//******************* */
  // save date
  
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
   
    e.preventDefault();
    
      const payload: SitesData = {
        ...data,
        id: parseInt(id!),
        customer_id: selectedCustomer?.id!,
        hosting_id: selectedHosting?.id!,
      };
      console.log('payload site ', payload);

    /*  const payload =  {
        "id": 139,
        "customer_id": 100,
        "type": 3,
        "domain": "test145.gr",
        "notes": "test14",
        "hosting_id": 2
    };*/

      try {
        const response =  await updateSites(parseInt(id!), payload);
         // Assuming 'response' contains the project data including 'projectid'
       const projectid = response?.id;
       console.log('id ', response);


       if (projectid) {
        if (id) {
          const sitesViewUrl = CONFIG.urls.getSitesViewUrl(id);
          window.location.href = sitesViewUrl;
        }
        setSuccessMessage("Ενημερώθηκε");
        setErrorMessage(null);
       }
       
       } catch (error) {
         console.error('Failed to create site', error);
               
        processError(error, setErrors, setErrorMessage, setSuccessMessage);
       }
     

  
  };

  //******************* */
  // LOAD ALL FUNCTION
  //******************* */

  //******************* */
  // LOAD ALL FUNCTION
  //******************* */
  useEffect(() => {
    const fetchData = async () => {
      await loadCustomerDropdown();
      await loadHostingDropdown();
    };

    fetchData();
  }, [loadCustomerDropdown, loadHostingDropdown]);

  useEffect(() => {
    if (id && Array.isArray(customerdropdown) && Array.isArray(hostingdropdown)) {
      setSiteID(id);
      loadDataDetails(id);
      console.log('Failed to fetch hosting', );
    }
  }, [id, customerdropdown, hostingdropdown, loadDataDetails]);

  return (
        
    <div className="container mx-0 p-4">
    <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-8  dark:border-white/10">
      <Heading level={2}>Επεξεργασία</Heading>
    </div>

    <form onSubmit={handleSubmit}>
      <Fieldset>

        {successMessage && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
            <span className="block sm:inline">{successMessage}</span>
          </div>
        )}
        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
            <span className="block sm:inline">{errorMessage}</span>
          </div>
        )}
        <FieldGroup>
          <FieldSameLine>
            <Label htmlFor="customer_id">Πελάτης</Label>
            <ComboboxComponent
            id="customer_id"
            name="customer_id"
              options={customerdropdown}
              selectedOption={selectedCustomer}
              setSelectedOption={setSelectedCustomer}
              />
              {errors &&  errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}      

          </FieldSameLine>

          <FieldSameLine>
            <Label>
              Τύπος
            </Label>
            <Select
              id="type"
              name="type"
              value={data.type! || ''}
              onChange={handleChange}
              invalid={errors && errors.hasOwnProperty('type')}
            >
              {sitesType.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.label}
                </option>
              ))}
            </Select>
            {errors && errors.hasOwnProperty('type') && <ErrorMessage>{errors.type[0]}</ErrorMessage>}
          </FieldSameLine>

          <FieldSameLine>
            <Label>Domain</Label>
            <Input
              id="domain"
              name="domain"
              value={data.domain! || ''}
              onChange={handleChange}
              invalid={errors && errors.hasOwnProperty('domain')} />
           {errors && errors.hasOwnProperty('domain') && <ErrorMessage>{errors.domain[0]}</ErrorMessage>}
          </FieldSameLine>
          <FieldSameLine>
            <Label>Σημειώσεις</Label>
            <Textarea
              id="notes"
              name="notes"
              data-slot="control"
              value={data.notes! || ''}
              onChange={handleChange}
              invalid={errors && errors.hasOwnProperty('notes')} />
           {errors && errors.hasOwnProperty('notes') && <ErrorMessage>{errors.notes[0]}</ErrorMessage>}
          </FieldSameLine>
          <FieldSameLine>
            <Label htmlFor="hosting_id">Hosting</Label>
            <ComboboxComponent
              id="hosting_id"
              name="hosting_id"
              options={hostingdropdown}
              selectedOption={selectedHosting}
              setSelectedOption={setSelectedHosting}
              />
            {errors && errors.hasOwnProperty('hosting_id') && <ErrorMessage>{errors.hosting_id[0]}</ErrorMessage>}
            
          </FieldSameLine>
          <FieldSameLine>
            <Button type="submit">
              Αποθήκευση
            </Button>
            {validationMessage && <p className="text-red-500">{validationMessage}</p>}
          </FieldSameLine>
        </FieldGroup>
      </Fieldset>

    </form>
  </div>
);
};


export default SitesEdit;
