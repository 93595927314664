import React, { useEffect, useState } from 'react';
import { TasksData } from '../../../utils/types';
import { ErrorMessage, Field, FieldGroup, Fieldset, Label } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { Button } from '../../../components/button';
import { defaultTaskData, taskStatus } from '../../../utils/data';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '../../../components/dialog';
import { Heading } from '../../../components/heading';
import { BackendDate } from '../../../utils/function';
import { CreateTask } from '../../../utils/apicall/tasksApi';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { processError } from '../../../utils/error/errorUtils';

interface TaskAddProps {
  project_id: number;
  open: boolean;
  onClose: () => void;
  onAddSuccess: () => void;
}

const TaskAddPopup: React.FC<TaskAddProps> = ({ open, onClose, onAddSuccess, project_id }) => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [newtask, setNewtask] = useState<TasksData>(defaultTaskData);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  useEffect(() => {
    if (open) {
      
      const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
      setNewtask({
        ...defaultTaskData,
        start_date: today,
        due_date: today,
      });
      setValidationMessage('');
      setSuccessMessage(null);
      setErrorMessage(null);
    }
  }, [open]);

  const validateForm = () => {
    setValidationMessage('');
    // Add more validation logic if needed
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewtask({
      ...newtask,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {


      const formattedStartDate = BackendDate(newtask.start_date!) || '';
      const formattedEndDate = BackendDate(newtask.end_date!) || '';
      const formattedDueDate = BackendDate(newtask.due_date!) || '';
      // If status is 0, set it to 1
      const formattedStatus = newtask.status == 0 ? 1 : newtask.status;

      const payload: TasksData = {
        ...newtask,
        project_id:project_id,
        start_date: formattedStartDate,
        due_date: formattedDueDate,
        end_date: formattedEndDate,
        status: formattedStatus, // Use the formattedStatus here
      };

      try {
        setSuccessMessage(null); // Clear any previous success messages
        const response = await CreateTask(project_id, payload);
        console.log('response', response);
        setSuccessMessage('Task created successfully');
        setTimeout(() => {
          onClose(); // Close the dialog after the delete operation is successful
          onAddSuccess(); // Refresh the subscription list
        }, 100); // Optional delay for displaying success message
       
      } catch (error) {
        console.error('Failed to create task', error);
        processError(error, setErrors, setErrorMessage, setSuccessMessage);
    
      }
    }
  };

  return (
    <div className="container mx-0 p-4">
      <Dialog open={open} onClose={onClose} size="xl">
        <DialogTitle>
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Νέo Task</Heading>
            <Button
              onClick={onClose}
              plain
              className="absolute top-0 right-4 p-4 w-8 h-8 flex items-center justify-center  rounded-full shadow-md"
            >
              <XMarkIcon className="w-10 h-10 " style={{ color: '#1E90FF' }}/>
            </Button>
          </div>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogBody>
            <Fieldset>
              {successMessage && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                  <span className="block sm:inline">{successMessage}</span>
                </div>
              )}
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <FieldGroup>
                <Field>
                  <Label htmlFor="title">Τίτλος*</Label>
                  <Input
                    id="title"
                    type="text"
                    name="title"
                    value={newtask.title || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('title')}
                  />
                  {errors && errors.hasOwnProperty('title') && <ErrorMessage>{errors.title[0]}</ErrorMessage>}
                </Field>

                <Field>
                  <Label htmlFor="description">Περιγραφή</Label>
                  <Input
                    id="description"
                    type="text"
                    name="description"
                    value={newtask.description || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('description')}
                  />
                  {errors && errors.hasOwnProperty('description') && <ErrorMessage>{errors.description[0]}</ErrorMessage>}
                </Field>

                <Field>
                  <Label htmlFor="status">Status</Label>
                  <Select
                    id="status"
                    name="status"
                    value={newtask.status || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('status')}
                  >
                    {taskStatus.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Select>
                  {errors && errors.hasOwnProperty('status') && <ErrorMessage>{errors.status[0]}</ErrorMessage>}
                </Field>

                <Field>
                  <Label htmlFor="start_date">Ημ/νία Έναρξης</Label>
                  <Input
                    id="start_date"
                    type="date"
                    name="start_date"
                    value={newtask.start_date || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('start_date')}
                  />
                  {errors && errors.hasOwnProperty('start_date') && <ErrorMessage>{errors.start_date[0]}</ErrorMessage>}
                </Field>

                <Field>
                  <Label htmlFor="due_date">Ημ/νία Προθεσμίας</Label>
                  <Input
                    id="due_date"
                    type="date"
                    name="due_date"
                    value={newtask.due_date || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('due_date')}
                  />
                  {errors && errors.hasOwnProperty('due_date') && <ErrorMessage>{errors.due_date[0]}</ErrorMessage>}
                </Field>

                <Field>
                  <Label htmlFor="end_date">Ημ/νία Ολοκλήρωσης</Label>
                  <Input
                    id="end_date"
                    type="date"
                    name="end_date"
                    value={newtask.end_date || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('end_date')}
                  />
                  {errors && errors.hasOwnProperty('end_date') && <ErrorMessage>{errors.end_date[0]}</ErrorMessage>}
                </Field>
              </FieldGroup>
            </Fieldset>
          </DialogBody>

          <DialogActions>
          
            <Button type="submit">Αποθήκευση</Button>
            {validationMessage && <p className="text-red-500">{validationMessage}</p>}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default TaskAddPopup;
