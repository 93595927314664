import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Badge } from '../../../components/badge';
import { Button } from '../../../components/button';
import { FormatDisplayDateString, getTaskStatusColor, getTaskStatusName } from '../../../utils/function';
import { TasksData } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { TextButton } from '../../../components/text';

interface TaskListProps {
  tasks: TasksData[];
  tasksDeleted?: boolean;
  onEditTask: (taskId: number) => void;
  onUpdateTaskStatus: (taskId: number) => void;
  onRestoreTask?: (taskId: number) => void;
}

enum SortableColumn {
  Title = 'title',
  StartDate = 'start_date'
}

const TaskList: React.FC<TaskListProps> = ({ tasks, tasksDeleted, onEditTask, onUpdateTaskStatus, onRestoreTask }) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedTasks = React.useMemo(() => {
    let sortableTasks = [...tasks];
    if (sortConfig !== null) {
      sortableTasks.sort((a, b) => {
        let aValue: string = '';
        let bValue: string = '';
        switch (sortConfig.key) {
          case SortableColumn.Title:
            aValue = a.title ?? '';
            bValue = b.title ?? '';
            break;
          case SortableColumn.StartDate:
            aValue = a.start_date ?? '';
            bValue = b.start_date ?? '';
            break;
          default:
            return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableTasks;
  }, [tasks, sortConfig]);

  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>ID</TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Title)} className={getClassNamesFor(SortableColumn.Title)}>
              Τίτλος
              {sortConfig?.key === SortableColumn.Title && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader>Status</TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.StartDate)} className={getClassNamesFor(SortableColumn.StartDate)}>
              Ημ/νία Έναρξης
              {sortConfig?.key === SortableColumn.StartDate && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader>Ημ/νία λήξης</TableHeader>
            {tasksDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedTasks.map((item) => (
            <TableRow key={item.id}>
              {tasksDeleted && onRestoreTask ? (
                <>
                  <TableCell className="font-medium">{item.id}</TableCell>
                  <TableCell className="font-medium">{item.title}</TableCell>
                  <TableCell className="font-medium">
                    <Badge color={getTaskStatusColor(item.status!)}>{getTaskStatusName(item.status.toString())}</Badge>
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell className="font-medium">
                    <TextButton onClick={() => onEditTask(item.id!)}>{item.id}</TextButton>
                  </TableCell>
                  <TableCell className="font-medium">
                    <TextButton onClick={() => onEditTask(item.id!)}>{item.title}</TextButton>
                  </TableCell>
                  <TableCell className="font-medium">
                    <Badge color={getTaskStatusColor(item.status!)} onClick={() => onUpdateTaskStatus(item.id!)}>
                      {getTaskStatusName(item.status.toString())}
                    </Badge>
                  </TableCell>
                </>
              )}
              <TableCell className="font-medium">{FormatDisplayDateString(item.start_date!)}</TableCell>
              <TableCell className="font-medium">{FormatDisplayDateString(item.due_date!)}</TableCell>
              {tasksDeleted && onRestoreTask && (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreTask(item.id!) }}>Restore</Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TaskList;
