import React, { useState } from 'react';
import { CustomerDataDropDown, DalertData } from '../../../utils/types';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '../../../components/table';
import { FormatDisplayDateString, getCustomerTitle, getDalertsStatusName } from '../../../utils/function';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid';



interface AlertViewContentProps {
  filteredData: DalertData[];
  customerdropdown: CustomerDataDropDown[];
  type: string;

}

enum SortableColumn {
  Id = 'id',
  Due_date = 'due_date',
  Total_due ='total_due',
  Vat_total ='vat_total',
  Total = 'total',
}


const AlertViewContent: React.FC<AlertViewContentProps> = ({ filteredData,customerdropdown, type }) => {

  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue: any;
        let bValue: any;
  
        switch (sortConfig.key) {
          case SortableColumn.Id:
            aValue = a.id || 0;
            bValue = b.id || 0;
            break;
          case SortableColumn.Due_date:
            aValue = new Date(a.due_date ?? '').getTime();
            bValue = new Date(b.due_date ?? '').getTime();
            break;
            case SortableColumn.Total_due:
            aValue = parseFloat(a.total_due as any) || 0;
            bValue = parseFloat(b.total_due as any) || 0;
            break;
            case SortableColumn.Vat_total:
              aValue = parseFloat(a.vat_total as any) || 0;
              bValue = parseFloat(b.vat_total as any) || 0;
              break;
          case SortableColumn.Total:
            aValue = parseFloat(a.total as any) || 0;
            bValue = parseFloat(b.total as any) || 0;
            break;
          default:
            return 0;
        }
  
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig, customerdropdown]);

  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <div >
      {sortedData.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
              <TableHeader onClick={() => requestSort(SortableColumn.Id)} className={getClassNamesFor(SortableColumn.Id)}>
              ID
              {sortConfig?.key === SortableColumn.Id && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Due_date)} className={getClassNamesFor(SortableColumn.Due_date)}>
            Ημερομηνία
              {sortConfig?.key === SortableColumn.Due_date && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>     
            <TableHeader onClick={() => requestSort(SortableColumn.Total_due)} className={getClassNamesFor(SortableColumn.Total_due)}>
            Ποσό
              {sortConfig?.key === SortableColumn.Total_due && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Vat_total)} className={getClassNamesFor(SortableColumn.Vat_total)}>
            Συν. ΦΠΑ
              {sortConfig?.key === SortableColumn.Vat_total && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Total)} className={getClassNamesFor(SortableColumn.Total)}>
            Συν. Ποσό 
              {sortConfig?.key === SortableColumn.Total && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
              
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className="font-medium">{item.id}</TableCell>
                  <TableCell className="font-medium">{FormatDisplayDateString(item.due_date)}</TableCell>
                  <TableCell className="font-medium">{item.total_due} €</TableCell>
                  <TableCell className="font-medium">{item.vat_total} €</TableCell>
                  <TableCell className="font-medium">{item.total} €</TableCell>
                  <TableCell className="font-medium">{getDalertsStatusName(item.status.toString())}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default AlertViewContent;
