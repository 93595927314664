import React, { useEffect, useState, useCallback } from 'react';
import {  ProjectsData } from '../../../utils/types';
import { Field, FieldGroup, Fieldset, Label, Legend, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { Button } from '../../../components/button';
import { Heading } from '../../../components/heading';
import { defaultProjectsData, projectStatus } from '../../../utils/data';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { CreateProject } from '../../../utils/apicall/projectsApi';
import { BackendDate, mapCustomersToDropdownOptions } from '../../../utils/function';
import { validateFormProject } from '../../../utils/formValidation';
import { CONFIG } from '../../../utils/config';
import { processError } from '../../../utils/error/errorUtils';
import { Textarea } from '../../../components/textarea';
import ComboboxComponent from '../../../components/comboboxNew';

interface DropdownOption {
  id: number;
  value: string;
}
const ProjectAdd: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [newproject, setNewProject] = useState<ProjectsData>(defaultProjectsData)
  const [customerdropdown, setCustomerDropdown] = useState<any>();

  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<DropdownOption | null>(null);

const loadCustomerDropdown = useCallback(async () => {
  try {
    const data = await getCustomersDataDropDown();
    if (data && Array.isArray(data)) {
      const options = data.map(customer => ({
        id: customer.id,
        value: customer.title,
      }));
      setCustomerDropdown(options);
    }
  } catch (error) {
    console.error('Failed to fetch customer dropdown', error);
  }
}, []);


useEffect(() => {
  loadCustomerDropdown();
 
 
}, []);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    let valueToPass: string | number = value;

    setNewProject({
      ...newproject,
      [name]: valueToPass,
    });
  };


  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (selectedCustomer ) {
      const selectedCustomerId = selectedCustomer.id;
      const payload: ProjectsData = {
        ...newproject,
        customer_id: selectedCustomerId!,
        start_date: BackendDate(newproject.start_date!),
        due_date: BackendDate(newproject.due_date!),
        end_date: BackendDate(newproject.end_date!),
      };

      try {
       const response =  await CreateProject(payload);
        // Assuming 'response' contains the project data including 'projectid'
      const projectid = response?.id;
      console.log('id ', response);
      if (projectid) {
        const projectViewUrl = CONFIG.urls.getProjectViewUrl(projectid);
        window.location.href = projectViewUrl;
      }
        setSuccessMessage(null);
        setErrorMessage(null);
      } catch (error) {
        console.error('Failed to create project', error);
        processError(error, setErrors, setErrorMessage, setSuccessMessage);
      }
    }
  };


  return (

      <div >
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-8  dark:border-white/10">
            <Heading level={2}>Νέo Project</Heading>
        </div> 

        <form onSubmit={handleSubmit} >
        <Fieldset>
       
            {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{successMessage}</span>
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
        <FieldGroup>
          
       
          <Field>
          <Label>Τίτλος*</Label>
            <Input
              id="title"
              type="text"
              name="title"
              value={newproject.title!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('title')}
            />
              {errors.hasOwnProperty('title') && <ErrorMessage>{errors.title[0]}</ErrorMessage>}
             
          </Field>

         
          
          <Field>
          <Label htmlFor="customer_id">Πελάτης*</Label>
              <ComboboxComponent
                  id="customer_id"
                  name="customer_id"
                    options={customerdropdown}
                    selectedOption={selectedCustomer}
                    setSelectedOption={setSelectedCustomer}
                   
                    />
             {errors && errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}
          </Field>

       
          <Field>
          <Label>Περιγραφή</Label>
            <Textarea
              id="description"
              name="description"
              value={newproject.description!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('description')}
            />
             {errors && errors.hasOwnProperty('description') && <ErrorMessage>{errors.description[0]}</ErrorMessage>}
          </Field>
          <Field>

            <Label>lead_id</Label>
            <Input
              id="lead_id"
              type="text"
              name="lead_id"
              value={newproject.lead_id!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('lead_id')}
            />
               {errors && errors.hasOwnProperty('lead_id') && <ErrorMessage>{errors.lead_id[0]}</ErrorMessage>}
          </Field>
         
          <Field>

          <Label>contract_id</Label>
          <Input
            id="contract_id"
            type="text"
            name="contract_id"
            value={newproject.contract_id!|| ''}
            onChange={handleChange}
            invalid= {errors && errors.hasOwnProperty('contract_id')}
          />
          {errors && errors.hasOwnProperty('contract_id') && <ErrorMessage>{errors.contract_id[0]}</ErrorMessage>}
          </Field>
        
          <Field>
              <Label>
              proposal_id
              </Label>
              <Input
                id="proposal_id"
                type="text"
                name="proposal_id"
                value={newproject.proposal_id!|| ''}
                onChange={handleChange}
                invalid= {errors && errors.hasOwnProperty('proposal_id')}
              />
               {errors && errors.hasOwnProperty('proposal_id') && <ErrorMessage>{errors.proposal_id[0]}</ErrorMessage>}
          </Field>
          <Field>
          <Label>netvalue</Label>
            <Input
              id="netvalue"
              type="text"
              name="netvalue"
              value={newproject.netvalue!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('netvalue')}
            />
            {errors && errors.hasOwnProperty('netvalue') && <ErrorMessage>{errors.netvalue[0]}</ErrorMessage>}
          </Field>

          <Field>
          <Label>Κόστος</Label>
            <Input
              id="costvalue"
              type="text"
              name="costvalue"
              value={newproject.costvalue!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('costvalue')}
            />
             {errors && errors.hasOwnProperty('costvalue') && <ErrorMessage>{errors.costvalue[0]}</ErrorMessage>}
          </Field>
          <Field>
          <Label>Περιγραφή κόστους</Label>
            <Input
              id="cost_description"
              type="text"
              name="cost_description"
              value={newproject.cost_description!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('cost_description')}
            />
             {errors && errors.hasOwnProperty('cost_description') && <ErrorMessage>{errors.cost_description[0]}</ErrorMessage>}
          </Field>

          <Field>
            <Label>
              Status
            </Label>
                <Select
                    id="status"
                    name="status"
                    value={newproject.status!|| ''}
                    onChange={handleChange}
                    invalid= {errors && errors.hasOwnProperty('status')}
                  >
                    {projectStatus.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
            </Select>
            {errors && errors.hasOwnProperty('status') && <ErrorMessage>{errors.status[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label >
              Ημερομηνία Έναρξης
            </Label>
            <Input
              id="start_date"
              type="date"
              name="start_date"
              value={newproject.start_date!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('start_date')}
            />
               {errors && errors.hasOwnProperty('start_date') && <ErrorMessage>{errors.start_date[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label >
            Ημερομηνία Προθεσμίας
            </Label>
            <Input
              id="due_date"
              type="date"
              name="due_date"
              value={newproject.due_date!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('due_date')}
            />
              {errors && errors.hasOwnProperty('due_date') && <ErrorMessage>{errors.due_date[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label >
              Ημερομηνία Λήξης
            </Label>
            <Input
              id="end_date"
              type="date"
              name="end_date"
              value={newproject.end_date!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('end_date')}
            />
               {errors && errors.hasOwnProperty('end_date') && <ErrorMessage>{errors.end_date[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label >
              Σημειώσεις
            </Label>
            <Textarea
              id="notes"
              name="notes"
              value={newproject.notes!|| ''}
              onChange={handleChange}
              invalid= {errors && errors.hasOwnProperty('notes')}
            />
             {errors && errors.hasOwnProperty('notes') && <ErrorMessage>{errors.notes[0]}</ErrorMessage>}
          </Field>
         
          <Field>
            <Button type="submit">
              Υποβολή
            </Button>
            {validationMessage && <p className="text-red-500">{validationMessage}</p>}
          </Field>
        </FieldGroup>
      </Fieldset>

    </form>
    </div>
  );
};


export default ProjectAdd;
