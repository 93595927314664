import React, { useState } from 'react';
import { Field, FieldGroup, Fieldset, Label, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { Heading } from '../../../components/heading';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '../../../components/dialog';
import { syncCustomerData } from '../../../utils/apicall/customersApi';
import { processError } from '../../../utils/error/errorUtils';


interface CustomerAddProps {
  open: boolean;
  onClose: () => void;
  onAddSuccess: () => void;
}

const CustomerAdd: React.FC<CustomerAddProps> = ({ open, onClose, onAddSuccess }) => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [afm, setAFM] = useState<string>('');
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const validateForm = () => {
    if (afm.trim() === '') {
      setValidationMessage('The field is empty.');
      return false;
    }
    setValidationMessage('');
    return true;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAFM(value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      console.log('Form submitted:', afm);
      await syncCustomerData(afm);
     // handleSuccessPopup(setSuccessMessage, setErrorMessage, setErrors, onClose, onAddSuccess);
    } catch (error) {
      processError(error, setErrors, setErrorMessage, setSuccessMessage);
    }
  };

  return (
    <div >
      <Dialog open={open} onClose={onClose} size="xl">
        <DialogTitle>
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Νέoς Πελάτης</Heading>
          </div>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogBody>
            <Fieldset>
              {successMessage && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                  <span className="block sm:inline">{successMessage}</span>
                </div>
              )}
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <FieldGroup>
                <Field>
                  <Label htmlFor="afm">ΑΦΜ*</Label>
                  <Input
                    id="afm"
                    type="text"
                    name="afm"
                    value={afm}
                    onChange={handleChange}
                    invalid= {errors && errors.hasOwnProperty('afm')}
                    />
                      {errors && errors.hasOwnProperty('afm') && <ErrorMessage>{errors.afm[0]}</ErrorMessage>}
                </Field>
              </FieldGroup>
            </Fieldset>
          </DialogBody>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit">Υποβολή</Button>
            {validationMessage && <p className="text-red-500">{validationMessage}</p>}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CustomerAdd;
