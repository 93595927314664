import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Badge } from '../../../components/badge';
import { Button } from '../../../components/button';
import {  getCustomerTitle, getProjectStatusName, getProjectStatusColor, FormatDisplayDateString, getContractStatusColor } from '../../../utils/function';
import {  CustomerDataDropDown, ProjectsData, ContractsData } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { CONFIG } from '../../../utils/config';
import { TextLink } from '../../../components/text';

interface ContractListProps {
  filteredData: ContractsData[];
  customerdropdown:CustomerDataDropDown[];
  dataDeleted?: boolean;
  onRestoreData?: (projectId: number) => void;
}

enum SortableColumn {
  Description = 'description',
  Customer = 'customer',
  Status = 'status'
}

const ContractList: React.FC<ContractListProps> = ({ filteredData, customerdropdown, dataDeleted, onRestoreData}) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData= React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue: string = '';
        let bValue: string = '';
        switch (sortConfig.key) {
          case SortableColumn.Description:
            aValue = a.description ?? '';
            bValue = b.description ?? '';
            break;
          case SortableColumn.Customer:
            const customerA = getCustomerTitle(a.customer_id!, customerdropdown);
            const customerB = getCustomerTitle(b.customer_id!, customerdropdown);
            aValue = customerA;
            bValue = customerB;
            break;
          case SortableColumn.Status:
            aValue = getProjectStatusName(a.status.toString()!);
            bValue = getProjectStatusName(b.status.toString()!);
            break;
          default:
            return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig, customerdropdown]);
  
  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>ID</TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Customer)} className={getClassNamesFor(SortableColumn.Customer)}>
              Πελάτης
              {sortConfig?.key === SortableColumn.Customer && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Description)} className={getClassNamesFor(SortableColumn.Description)}>
            Περιγραφή
              {sortConfig?.key === SortableColumn.Description && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader >
              Ημερομηνία Συμβολαίου 
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Status)} className={getClassNamesFor(SortableColumn.Status)}>
              Status
              {sortConfig?.key === SortableColumn.Status && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader className="relative w-0">
              <span className="sr-only">Actions</span>
            </TableHeader>
            {dataDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.id}>
             
              
              {dataDeleted && onRestoreData ? (
                <>
              <TableCell className="font-medium">{item.id}</TableCell>
              <TableCell className="font-medium">{getCustomerTitle(Number(item.customer_id!), customerdropdown)}</TableCell>
              <TableCell className="font-medium">{item.description}</TableCell>
            
                </>
              ) : (
                <>
                 <TableCell className="font-medium"><TextLink href={CONFIG.urls.getContractViewUrl(item.id!)}>{item.id}</TextLink></TableCell>
                 <TableCell className="font-medium"><TextLink href={CONFIG.urls.getCustomerViewUrl(item.customer_id!)}>{getCustomerTitle(Number(item.customer_id!), customerdropdown)}</TextLink></TableCell>
                 <TableCell className="font-medium"><TextLink href={CONFIG.urls.getContractViewUrl(item.id!)}>{item.description}</TextLink></TableCell>
                </>
              )}
              
              <TableCell className="font-medium">{FormatDisplayDateString(item.datein!)}</TableCell>
              <TableCell className="font-medium whitespace-normal"><Badge color={getContractStatusColor(item.status!)!}>{getProjectStatusName(item.status.toString()!)}</Badge></TableCell>
              
              {dataDeleted && onRestoreData ? (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreData(item.id!) }}>Restore</Button>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                    <Dropdown>
                      <DropdownButton plain aria-label="More options">
                        <EllipsisVerticalIcon />
                      </DropdownButton>
                      <DropdownMenu anchor="bottom end">
                        <DropdownItem href={CONFIG.urls.getContractViewUrl(item.id!)}>View</DropdownItem>
                        <DropdownItem href={CONFIG.urls.getContractEditUrl(item.id!)}>Edit</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ContractList;
