import { Alert, AlertDescription, AlertActions, AlertTitle } from "../../../components/alert";
import { Button } from "../../../components/button";



interface WaitAlertProps {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  isDisabled: string;
  id: number;
  onCreateAccept: () => void;
  titlebuttonAccept: string;
  successMessage: string;
  errorMessage: string;
}

   const WaitAlert: React.FC<WaitAlertProps> = ({ open, onClose, title, description, id,titlebuttonAccept,isDisabled, errorMessage, successMessage
,    onCreateAccept }) => {
    // Convert the string isDisabled to a boolean
    const isButtonDisabled = isDisabled.toLowerCase() === 'true';

    const handleConfirm = () => {
    onCreateAccept();
    onClose(); // Close the alert after successful deletion
  };

  return (
    <Alert open={open} onClose={() => { /* Prevent closing */ }} size="lg">
    <AlertTitle>{title}</AlertTitle>
    {successMessage && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{successMessage}</span>
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}
    <AlertDescription>
      {description}
    </AlertDescription>
    <AlertActions>
      <Button onClick={handleConfirm} disabled={isButtonDisabled}>
        {titlebuttonAccept}
      </Button>
    </AlertActions>
  </Alert>
  );
};


export default WaitAlert;

