import { useState, useEffect } from 'react'
import './login.css'
import { BrowserRouter as Routers, Routes , Route, Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '../../utils/authContext';
import { Button } from '../../components/button';
import { loginApiCall } from '../../utils/apicall/loginApi';



const Login: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loginauth } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    // If the user is already authenticated, redirect to the dashboard
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();


    try {
      const token = await  loginApiCall(email, password); // Implement your login API call
      if (token) {
        await loginauth(token); // Call the login method from the auth context
        navigate('/dashboard'); // Navigate to the dashboard after login
      }
    } catch (error) {
      console.error('Login failed', error);
      setEmailError("Invalid email or password");
      setPasswordError("Invalid email or password");
      // Handle login error (e.g., show error message)
    }
  };



    return(
        <div className="login-box">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="user-box">
            <input
              type="email"
              value={email}
              placeholder="Enter email address here"
              onChange={(e) => setEmail(e.target.value)}
              className="user-box"
            />
            <label className="errorLabel">{emailError}</label>
          </div>
          <div className="user-box">
            <input

              value={password}
              placeholder="Enter password here"
              onChange={(e) => setPassword(e.target.value)}
              className="user-box"
            />
            <label className="errorLabel">{passwordError}</label>
          </div>
          <Button type="submit">Login</Button>
        </form>
      </div>
        
)
}

export default Login