import React, { useEffect, useState } from 'react';
import { CustomerData } from '../../../utils/types';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '../../../components/table';
import { Button } from '../../../components/button';
import PlusIcon from '@heroicons/react/16/solid/PlusIcon';
import { Heading } from '../../../components/heading';
import { EllipsisHorizontalIcon, MagnifyingGlassIcon, ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { CONFIG } from '../../../utils/config';
import { TextLink } from '../../../components/text';
import { Input, InputGroup } from '../../../components/input';
import CustomerAdd from './customerAdd';
import { getCustomersData } from '../../../utils/apicall/customersApi';

const ListCustomers: React.FC = () => {
  const [customers, setCustomers] = useState<CustomerData[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<CustomerData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isAddCustomerOpen, setAddCustomerOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortField, setSortField] = useState<'id' |'title' | 'debit'| 'email'>('id');

  const handleOpenAddCustomer = () => {
    setAddCustomerOpen(true);
  };

  const handleCloseAddCustomer = () => {
    setAddCustomerOpen(false);
  };

  const handleAddCustomerSuccess = () => {
    loadCustomers();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query) {
      const filtered = customers.filter((customer) =>
        customer.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers(customers);
    }
  };

  const handleSort = (field: 'id' |'title' | 'debit'| 'email' ) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(order);
    setSortField(field);

    const sorted = [...filteredCustomers].sort((a, b) => {

      if (field === 'id') {
        const aID = a.id || 0;
        const bID =b.id || 0;
        return order === 'asc' ? aID - bID : bID - aID;
      } else if (field === 'title') {
        return order === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
      } else if (field === 'debit') {
        const aDebit = parseFloat(a.debit) || 0;
        const bDebit = parseFloat(b.debit) || 0;
        return order === 'asc' ? aDebit - bDebit : bDebit - aDebit;
      }else{
        return order === 'asc' ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email);
      }
    });
    setFilteredCustomers(sorted);
  };

  const loadCustomers = async () => {
    try {
      const data = await getCustomersData();
      if (data.length > 0) {
        setCustomers(data);
        setFilteredCustomers(data);
      }
    } catch (error) {
      console.error('An error occurred while fetching customer data.');
    }
  };

  useEffect(() => {
    loadCustomers();
  }, []);

  return (
  
      <><div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <Heading level={2}>Πελάτες</Heading>
      <div className="flex">
        <Button plain onClick={handleOpenAddCustomer}><PlusIcon /></Button>

      </div>
      <div className={`popup-container ${isAddCustomerOpen ? 'open' : ''}`}>
        <CustomerAdd open={isAddCustomerOpen} onClose={handleCloseAddCustomer} onAddSuccess={handleAddCustomerSuccess} />
      </div>

    </div><div className="flex mt-4 mb-4">
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            type="search"
            name="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search..." />
        </InputGroup>
      </div><Table>
        <TableHead>
          <TableRow>
            <TableHeader onClick={() => handleSort('id')} className="cursor-pointer">
              ID {sortField === 'id' && (sortOrder === 'asc' ? <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />)}
            </TableHeader>

            <TableHeader onClick={() => handleSort('title')} className="cursor-pointer">
              Τίτλος {sortField === 'title' && (sortOrder === 'asc' ? <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />)}
            </TableHeader>
            <TableHeader onClick={() => handleSort('email')} className="cursor-pointer">
              Email {sortField === 'email' && (sortOrder === 'asc' ? <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />)}
            </TableHeader>
            <TableHeader onClick={() => handleSort('debit')} className="cursor-pointer">
              Υπόλοιπο {sortField === 'debit' && (sortOrder === 'asc' ? <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />)}
            </TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredCustomers.map((item) => (
            <TableRow key={item.id}>
              <TableCell className="font-medium"><TextLink href={CONFIG.urls.getCustomerViewUrl(item.id!)}>{item.id}</TextLink></TableCell>
              <TableCell className="font-medium whitespace-normal"><TextLink href={CONFIG.urls.getCustomerViewUrl(item.id!)}>{item.title}</TextLink></TableCell>
              <TableCell className="font-medium whitespace-normal">{item.email}</TableCell>
              <TableCell className="font-medium whitespace-normal">{item.debit}</TableCell>
              <TableCell>
                <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                  <Dropdown>
                    <DropdownButton plain aria-label="More options">
                      <EllipsisVerticalIcon />
                    </DropdownButton>
                    <DropdownMenu anchor="bottom end">
                      <DropdownItem href={CONFIG.urls.getCustomerViewUrl(item.id!)}>View</DropdownItem>
                
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table></>
 
  );
};

export default ListCustomers;
