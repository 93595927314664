import React, { useEffect, useState } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid'
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../components/dropdown';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '../../components/table';
import { TextLink,Text, Strong } from '../../components/text';
import { Heading } from '../../components/heading';
import axios from 'axios';
import { useAuth } from '../../utils/authContext';

interface CardProps {
  bgColor: string;
  icon: JSX.Element;
  title: string;
  value: string | number;
  link: string;
  linkText: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;
  // Add other fields as needed
}



interface DashboardProps {
  users: User[];

  
 
}

const Card: React.FC<CardProps> = ({ bgColor, icon, title, value, link, linkText }) => (
  <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
    <dt>
      <div className={`absolute ${bgColor} rounded-md p-3`}>
        {icon}
      </div>
      <p className="ml-16 text-sm font-medium text-gray-500 truncate">{title}</p>
    </dt>
    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
      <p className="text-2xl font-semibold text-gray-900">{value}</p>
      <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
        <div className="text-sm">
          <a href={link} className="font-medium text-indigo-600 hover:text-indigo-500">
            {linkText}
          </a>
        </div>
      </div>
    </dd>
  </div>
);

const Dashboard: React.FC = () => {
 
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

 /* const fetchData = async () => {
    const token = localStorage.getItem('access_token');
    try {
      const response = await axios.get('https://ictinus.avado.gr/api/user', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
      console.log('User data:', response.data);

      setUsers(response.data);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };*/

 /* useEffect(() => {
    fetchData();
  }, []);*/


  return (
  
<main>
<div>

      <h2>hjg</h2>
      <pre>{JSON.stringify(users, null, 2)}</pre>
    </div>

</main>
  );
};

export default Dashboard