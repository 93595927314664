import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductData } from '../../../utils/types';
import { DescriptionList, DescriptionTerm, DescriptionDetails } from '../../../components/description-list';
import { Heading } from '../../../components/heading';
import { EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { defaultProductData } from '../../../utils/data';
import { getProductDetail } from '../../../utils/apicall/productsApi';

const ProductView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<ProductData>(defaultProductData);

  const loadProductDetails = useCallback(async (id: string) => {
    try {
      const data = await getProductDetail(id);
      setProduct(data!);
      console.log('product data:', data);
    } catch (error) {
      console.error('Failed to fetch product details', error);
    }
  }, []);

  

  useEffect(() => {
    if (id) {
      loadProductDetails(id);
    }
  }, [id, loadProductDetails]);

  return (
    <div >
      {product ? (
        <>
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Υπηρεσία: {product.title}</Heading>
            <div className="flex gap-1">
              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
               
              </div>
            </div>
          </div>
          <DescriptionList>
            <DescriptionTerm>ID</DescriptionTerm>
            <DescriptionDetails>{product.id}</DescriptionDetails>

            <DescriptionTerm>Τίτλος</DescriptionTerm>
            <DescriptionDetails>{product.title}</DescriptionDetails>

            <DescriptionTerm>Περιγραφή</DescriptionTerm>
            <DescriptionDetails>{product.description}</DescriptionDetails>

            <DescriptionTerm>Ποσότητα</DescriptionTerm>
            <DescriptionDetails>{product.qty}</DescriptionDetails>

            <DescriptionTerm>Χρέωση Μηνιαία</DescriptionTerm>
            <DescriptionDetails>{product.monthly}</DescriptionDetails>

            <DescriptionTerm>Χρέωση Τριμήνου</DescriptionTerm>
            <DescriptionDetails>{product.quadrennial}</DescriptionDetails>

            <DescriptionTerm>Χρέωση Εξαμήνου</DescriptionTerm>
            <DescriptionDetails>{product.semiannuall}</DescriptionDetails>

            <DescriptionTerm>Χρέωση Ετήσια</DescriptionTerm>
            <DescriptionDetails>{product.annually}</DescriptionDetails>

            <DescriptionTerm>Χρέωση Διετής</DescriptionTerm>
            <DescriptionDetails>{product.semiannuall}</DescriptionDetails>

            <DescriptionTerm>Χρέωση Τριετής</DescriptionTerm>
            <DescriptionDetails>{product.triennially}</DescriptionDetails>

            <DescriptionTerm>Χρέωση Τετραετής</DescriptionTerm>
            <DescriptionDetails>{product.quadrennial}</DescriptionDetails>

            <DescriptionTerm>Κωδικός Erp</DescriptionTerm>
            <DescriptionDetails>{product.erpcode}</DescriptionDetails>

            <DescriptionTerm>ID Κατηγορίας</DescriptionTerm>
            <DescriptionDetails>{product.cat_id}</DescriptionDetails>

            <DescriptionTerm>ERP</DescriptionTerm>
            <DescriptionDetails>{product.erp}</DescriptionDetails>
          </DescriptionList>
        </>
      ) : (
        <div>No product found.</div>
      )}
    </div>
  );
};

export default ProductView;
/** <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu anchor="bottom end">
                    <DropdownItem href={CONFIG.urls.getProductEditUrl(id!)}>Edit</DropdownItem>
                  </DropdownMenu>
                </Dropdown> */