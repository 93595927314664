import React, { useCallback, useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import {  ProjectsData } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import {  TrashIcon } from '@heroicons/react/16/solid';
import { FieldGroup, FieldSameLine, Fieldset, Label, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { BackendDate, DisplayDate } from '../../../utils/function';
import { Select } from '../../../components/select';
import {  defaultProjectsData, projectStatus } from '../../../utils/data';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getProjectsDetails, updateProject } from '../../../utils/apicall/projectsApi';
import { processError } from '../../../utils/error/errorUtils';
import ComboboxComponent from '../../../components/comboboxNew';
import { CONFIG } from '../../../utils/config';
import { Textarea } from '@headlessui/react';

interface DropdownOption {
  id: number;
  value: string;
}
const ProjectEdit: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {id } = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] = useState<any>();
  const [productdropdown, setProductDropdown] = useState<any>();
  const [project, setProject] = useState<ProjectsData>(defaultProjectsData);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [projectID, setProjectID] = useState<string | null>(null);


  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);
  
//******************************** */
//  GET CUSTOMER DROPDOWN
//******************************** */
 

const loadCustomerDropdown = useCallback(async () => {
  try {
    const data = await getCustomersDataDropDown();
    if (data && Array.isArray(data)) {
      const options = data.map(customer => ({
        id: customer.id,
        value: customer.title,
      }));
      setCustomerDropdown(options);
    }
  } catch (error) {
    console.error('Failed to fetch customer dropdown', error);
  }
}, []);




//******************************** */
//  GET SUBSCRIPTION
//*********************************/
  const loadProjectDetails = useCallback(async (id: string) => {
    try {
    
      const data = await getProjectsDetails(id);
       console.log(' data :', data); //print json
       console.log(' data start date:', data.start_date); //print json
       //data.start_date = BackendDate1(data.start_date);
       setProject(data);
      

       data.start_date=DisplayDate(data.start_date!)||'';
       data.due_date=DisplayDate(data.due_date!)||'';
       data.end_date=DisplayDate(data.end_date!)||'';

       const selectedCustomerOption = customerdropdown.find((customer: { id: number; }) => customer.id === data.customer_id) || null;
      
       setSelectedCustomer(selectedCustomerOption);
      
 
       console.log(data.customer_id);
    } catch (error) {
      console.error('Failed to fetch subscription', error);
      // Handle error by setting subscription to an empty array
    }
  }, [customerdropdown]);



//******************************** */
// Handle Change
//******************************** */
  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    let valueToPass: string | number = value;

      console.log('handleChange  valueToPass'+name, valueToPass);
    
      setProject({
        ...project,
        [name]: value
      });



};

const onChangeDate = (e: { target: { name: any; value: any; }; })=> {

  const { name, value } = e.target;

  let valueToPass: string | number = value;
  setProject({
        ...project,
        [name]: valueToPass // Store date as YYYY-MM-DD string
      })
    
  


};




//******************* */
// UPDATE FORM
//******************* */
  // save date
  
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
   
    e.preventDefault();
    if( (selectedCustomer)  ){
      const selectedCustomerId = selectedCustomer.id;

      const payload: ProjectsData = {
        ...project,
        id: parseInt(id!),
        customer_id: selectedCustomerId,
        start_date: BackendDate(project.start_date!),
        due_date: BackendDate(project.due_date!),
        end_date: BackendDate(project.end_date!),
      };

      try {
        const response =  await updateProject(parseInt(id!), payload);
         // Assuming 'response' contains the project data including 'projectid'
       const projectid = response?.id;
       console.log('id ', response);
       if (projectid) {
    
        const projectViewUrl = CONFIG.urls.getProjectViewUrl(projectid);
        window.location.href = projectViewUrl;
     
        setSuccessMessage("Ενημερώθηκε");
        setErrorMessage(null);
       }
       
       } catch (error) {
         console.error('Failed to create project', error);
               
        processError(error, setErrors, setErrorMessage, setSuccessMessage);
       }
     }

  
  };

  //******************* */
  // LOAD ALL FUNCTION
  //******************* */

  useEffect(() => {
    const fetchData = async () => {
      await loadCustomerDropdown();
  
    };

    fetchData();
  }, [loadCustomerDropdown]);

  useEffect(() => {
    if (id && Array.isArray(customerdropdown) ) {
      setProjectID(id);
      loadProjectDetails(id);
    }
  }, [id, customerdropdown, loadProjectDetails]);





  return (
        
    <div >
 <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>Επεξεργασία Έργου</Heading>

    </div> 

       

    <form onSubmit={handleSubmit} >
    <Fieldset>
   
        {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
          <span className="block sm:inline">{successMessage}</span>
        </div>
      )}
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
          <span className="block sm:inline">{errorMessage}</span>
        </div>
      )}
    <FieldGroup>
      
   
      <FieldSameLine>
      <Label>Τίτλος*</Label>
        <Input
          id="title"
          type="text"
          name="title"
          value={project.title!|| ''}
          onChange={handleChange}
          invalid={errors.hasOwnProperty('title')}
        />
          {errors.hasOwnProperty('title') && <ErrorMessage>{errors.title[0]}</ErrorMessage>}
         
      </FieldSameLine>

     
      
      <FieldSameLine>

      <Label htmlFor="customer_id">Πελάτης*</Label>
      <ComboboxComponent
                  id="customer_id"
                  name="customer_id"
                    options={customerdropdown}
                    selectedOption={selectedCustomer}
                    setSelectedOption={setSelectedCustomer}
                   
                    />

            {errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}
              </FieldSameLine>
   
      <FieldSameLine>
      <Label>Περιγραφή</Label>
        <Input
          id="description"
          type="notes"
          name="description"
          value={project.description!|| ''}
          onChange={handleChange}
          invalid= {errors && errors.hasOwnProperty('description')}
        />
          {errors && errors.hasOwnProperty('description') && <ErrorMessage>{errors.description[0]}</ErrorMessage>}
      </FieldSameLine>
      <FieldSameLine>

        <Label>lead_id</Label>
        <Input
          id="lead_id"
          type="text"
          name="lead_id"
          value={project.lead_id!|| ''}
          onChange={handleChange}
          invalid= {errors && errors.hasOwnProperty('lead_id')}
        />
           {errors && errors.hasOwnProperty('lead_id') && <ErrorMessage>{errors.lead_id[0]}</ErrorMessage>}
      </FieldSameLine>
     
      <FieldSameLine>

      <Label>contract_id</Label>
      <Input
        id="contract_id"
        type="text"
        name="contract_id"
        value={project.contract_id!|| ''}
        onChange={handleChange}
        invalid= {errors && errors.hasOwnProperty('contract_id')}
      />
       {errors && errors.hasOwnProperty('contract_id') && <ErrorMessage>{errors.contract_id[0]}</ErrorMessage>}
      </FieldSameLine>
    
      <FieldSameLine>
          <Label>
          proposal_id
          </Label>
          <Input
            id="proposal_id"
            type="text"
            name="proposal_id"
            value={project.proposal_id!|| ''}
            onChange={handleChange}
            invalid= {errors && errors.hasOwnProperty('proposal_id')}
          />
            {errors && errors.hasOwnProperty('proposal_id') && <ErrorMessage>{errors.proposal_id[0]}</ErrorMessage>}
      </FieldSameLine>
      <FieldSameLine>
      <Label>netvalue</Label>
        <Input
          id="netvalue"
          type="text"
          name="netvalue"
          value={project.netvalue!|| ''}
          onChange={handleChange}
          invalid= {errors && errors.hasOwnProperty('netvalue')}
        />
         {errors && errors.hasOwnProperty('netvalue') && <ErrorMessage>{errors.netvalue[0]}</ErrorMessage>}
      </FieldSameLine>

      <FieldSameLine>
      <Label>Κόστος</Label>
        <Input
          id="costvalue"
          type="text"
          name="costvalue"
          value={project.costvalue!|| ''}
          onChange={handleChange}
          invalid= {errors && errors.hasOwnProperty('costvalue')}
        />
         {errors && errors.hasOwnProperty('costvalue') && <ErrorMessage>{errors.costvalue[0]}</ErrorMessage>}
      </FieldSameLine>
      <FieldSameLine>
      <Label>Περιγραφή κόστους</Label>
        <Input
          id="cost_description"
          type="text"
          name="cost_description"
          value={project.cost_description!|| ''}
          onChange={handleChange}
          invalid= {errors && errors.hasOwnProperty('cost_description')}
        />
          {errors && errors.hasOwnProperty('cost_description') && <ErrorMessage>{errors.cost_description[0]}</ErrorMessage>}
      </FieldSameLine>

      <FieldSameLine>
        <Label>
          Status
        </Label>
        <Select
                    id="status"
                    name="status"
                    value={project.status!|| ''}
                    onChange={handleChange}
                    invalid= {errors && errors.hasOwnProperty('status')}
                  >
                    {projectStatus.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
            </Select>
            {errors && errors.hasOwnProperty('status') && <ErrorMessage>{errors.status[0]}</ErrorMessage>}
      </FieldSameLine>
      <FieldSameLine>
        <Label >
          Ημερομηνία Έναρξης
        </Label>
        <Input
          id="start_date"
          type="date"
          name="start_date"
          value={project.start_date!|| ''}
          onChange={onChangeDate}
          invalid= {errors && errors.hasOwnProperty('start_date')}
        />
          {errors && errors.hasOwnProperty('start_date') && <ErrorMessage>{errors.start_date[0]}</ErrorMessage>}
      </FieldSameLine>
      <FieldSameLine>
        <Label >
        Ημερομηνία Προθεσμίας
        </Label>
        <Input
          id="due_date"
          type="date"
          name="due_date"
          value={project.due_date!|| ''}
          onChange={onChangeDate}
          invalid= {errors && errors.hasOwnProperty('due_date')}
        />
          {errors && errors.hasOwnProperty('due_date') && <ErrorMessage>{errors.due_date[0]}</ErrorMessage>}
      </FieldSameLine>
      <FieldSameLine>
        <Label >
          Ημερομηνία Λήξης
        </Label>
        <Input
          id="end_date"
          type="date"
          name="end_date"
          value={project.end_date!|| ''}
          onChange={onChangeDate}
          invalid= {errors && errors.hasOwnProperty('end_date')}
        />
          {errors && errors.hasOwnProperty('end_date') && <ErrorMessage>{errors.end_date[0]}</ErrorMessage>}
      </FieldSameLine>
      <FieldSameLine>
        <Label >
          Σημειώσεις
        </Label>
        <Textarea
          id="notes"
          name="notes"
          data-slot="control"
          value={project.notes!|| ''}
          onChange={handleChange}
          invalid= {errors && errors.hasOwnProperty('notes')}
        />
         {errors && errors.hasOwnProperty('notes') && <ErrorMessage>{errors.notes[0]}</ErrorMessage>}
      </FieldSameLine>
     
      <FieldSameLine>
        <Button type="submit">
          Ενημέρωση
        </Button>
        {validationMessage && <p className="text-red-500">{validationMessage}</p>}
      </FieldSameLine>
    </FieldGroup>
  </Fieldset>

</form>
</div>


          
  );
};


export default ProjectEdit;
