import React, { useEffect, useState } from 'react';
import { CustomerDataDropDown, SubscriptionDataAll, ProductDataDropDown } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../../components/heading';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '../../../components/table';
import { Button } from '../../../components/button';
import PlusIcon from '@heroicons/react/20/solid/PlusIcon';
import { EllipsisHorizontalIcon, MagnifyingGlassIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/16/solid';
import { TextLink } from '../../../components/text';
import { CONFIG } from '../../../utils/config';
import { Input, InputGroup } from '../../../components/input';
import SubscriptionDelete from './subscriptionDelete';
import { getCustomerTitle, getProductTitle, FormatDisplayDateString } from '../../../utils/function';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getProductsDataDropDown } from '../../../utils/apicall/productsApi';
import { getAllSubscriptionsData } from '../../../utils/apicall/subscriptionsApi';
import SubscriptionList from './SubscriptionList';

const SubscriptionDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [subscriptions, setSubscriptions] = useState<SubscriptionDataAll[]>([]);
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [productdropdown, setProductDropdown] = useState<ProductDataDropDown[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredSubscriptions, setFilteredSubscriptions] = useState<SubscriptionDataAll[]>([]);
  const [isDeleteSubscriptionOpen, setDeleteSubscriptionOpen] = useState(false);
  const [SubscriptionID, setSubscriptionID] = useState<number>(0);

  const [sortField, setSortField] = useState<string>('id'); // Default sort field
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Default sort order

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query) {
      const filtered = subscriptions.filter((subscription) => 
        Object.values(subscription).some(value =>
          value !== null && String(value).toLowerCase().includes(query)
        ) || (getCustomerTitle(subscription.customer_id, customerdropdown).toLowerCase().includes(query))
        || getProductTitle(subscription.product_id, productdropdown).toLowerCase().includes(query)
      );
      setFilteredSubscriptions(filtered);
    } else {
      setFilteredSubscriptions(subscriptions);
    }
  };

  const loadCustomerDropdown = async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer', error);
    }
  };

  const loadProductDropdown = async () => {
    try {
      const data = await getProductsDataDropDown();
      setProductDropdown(data);
    } catch (error) {
      console.error('Failed to fetch product', error);
    }
  };

  const loadSubscription = async () => {
    try {
      const data = await getAllSubscriptionsData();
      setSubscriptions(data);
      setFilteredSubscriptions(data);
    } catch (error) {
      console.error('Failed to fetch subscription', error);
    }
  };

  const openNewSubscriptionForm = () => {
    navigate(CONFIG.urls.getSubscriptionAddUrl());
  };

  const handleOpenDeleteSubscription = (id: number) => {
    setDeleteSubscriptionOpen(true);
    setSubscriptionID(id);
  };

  const handleCloseDeleteSubscription = () => {
    setDeleteSubscriptionOpen(false);
  };

  const handleDeleteSuccess = () => {
    loadSubscription();
  };

  const sortSubscriptions = (field: string) => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(order);
    setSortField(field);

    const sortedSubscriptions = [...filteredSubscriptions].sort((a, b) => {
      let comparison = 0;

      if ( field === 'domain') {
        // Sorting strings
        comparison = (String(a[field] || '')).localeCompare(String(b[field] || ''));
      }else  if (  field === 'product') {
        // Sorting strings
        const productA = getProductTitle(a.product_id!, productdropdown);
        const productB = getProductTitle(b.product_id!, productdropdown);
        comparison = productA.localeCompare(productB);
      }else if (  field === 'customer' ) {
        // Sorting strings
        const customerA = getCustomerTitle(a.customer_id!, customerdropdown);
        const customerB = getCustomerTitle(b.customer_id!, customerdropdown);
        comparison = customerA.localeCompare(customerB);
      
      } else if (field === 'next_due_date') {
        // Sorting dates
        comparison = new Date(a.next_due_date||'').getTime() - new Date(b.next_due_date||'').getTime();
      } else if (field === 'total') {
        // Sorting numbers
        comparison = (parseFloat(a.total as any) || 0) - (parseFloat(b.total as any) || 0);
      }

      return order === 'asc' ? comparison : -comparison;
    });

    setFilteredSubscriptions(sortedSubscriptions);
  };

  useEffect(() => {
    loadSubscription();
    loadCustomerDropdown();
    loadProductDropdown();
  }, []);

  return (
    <div >
      <SubscriptionDelete open={isDeleteSubscriptionOpen} onClose={handleCloseDeleteSubscription} subscriptionID={SubscriptionID} onDeleteSuccess={handleDeleteSuccess} />
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>Συνδρομές</Heading>
        <div className="flex gap-1">
        <Button plain onClick={openNewSubscriptionForm}><PlusIcon /></Button>
        </div>
      </div>
      <div className="flex mt-4 mb-4">
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            type="search"
            name="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search..."
          />
        </InputGroup>
      </div>
          <SubscriptionList
            filteredData={filteredSubscriptions}
            customerdropdown={customerdropdown}
            productdropdown={productdropdown}
            dataDeleted={false}
            onDeleteData={(ID: any) => handleOpenDeleteSubscription(ID!)}
          />
    </div>
  );
};

export default SubscriptionDashboard;
