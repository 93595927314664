import { ProductData, ProductDataDropDown} from '../types';
import { apiClient } from './apiClient';
import { defaultProductData } from '../data';

//**********************************************/
// ****  GET ADMINS Products DATA ALL
//**********************************************/



export const getProductsData = async (): Promise<ProductData[]> => {
  try {
    const response = await apiClient.get<ProductData[]>('/admin/products');

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
    return [];
  }
};



export const getProductsDataDropDown = async (): Promise<ProductDataDropDown[]> => {
  try {
    const response = await apiClient.get<ProductDataDropDown[]>('/admin/products-dropdown');

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
    return [];
  }
};



//**********************************************/
// ****  GET ADMINS PRODUCT DATA ONE
//**********************************************/

export const getProductDetail = async (id: string): Promise<ProductData> => {
  try {
    const response = await apiClient.get<ProductData>(`/admin/products/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error)
    throw error;
    return defaultProductData;
  }
}



