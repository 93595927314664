'use client';
// ************************************
// Limeframe  by Areti
// ************************************
import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { Input } from './input';

export function InputWithDatalist<T extends string | number>({
  className,
  placeholder,
  autoFocus,
  'aria-label': ariaLabel,
  name,
  id,
  options = [],
  onChange,
  value = '', // This is the value to display in the input field
  ...props
}: {
  className?: string;
  placeholder?: string;
  autoFocus?: boolean;
  'aria-label'?: string;
  name?: string;
  id?: string;
  options?: { id: T; value: string }[];
  onChange?: (e: { target: { name: string; value: string | number; } }) => void;
  value?: string; // The display value for the input
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'placeholder' | 'value' | 'onChange'>) {
  const [inputValue, setInputValue] = useState(value); // Initialize with value prop
  const [selectedValue, setSelectedValue] = useState<T | null>(null); // Track selected ID
  const [isListOpen, setIsListOpen] = useState(false);

  useEffect(() => {
    setInputValue(value); // Update inputValue when value prop changes
  }, [value]);

  const filteredOptions = (options || []).filter(option =>
    option.value.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleItemClick = (option: { id: T; value: string }) => {
    setInputValue(option.value); // Set the input value to the option value
    setSelectedValue(option.id); // Set the selected ID
    if (onChange) {
      onChange({ target: { name: name || '', value: option.id } }); // Pass ID to onChange
    }
    setIsListOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setInputValue(value);
    setIsListOpen(true);
    setSelectedValue(null); // Reset selected value when input changes
    if (onChange) {
      onChange({ target: { name, value } });
    }
  };

  return (
    <span
      data-slot="control"
      className={clsx([
        className,
        // Basic layout
        'relative block w-full',
        // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
        'before:absolute before:inset-px before:rounded-[calc(theme(borderRadius.lg)-1px)] before:bg-white before:shadow',
        // Background color is moved to control and shadow is removed in dark mode so hide `before` pseudo
        'dark:before:hidden',
        // Focus ring
        'after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:ring-inset after:ring-transparent sm:after:focus-within:ring-2 sm:after:focus-within:ring-blue-500',
        // Disabled state
        'has-[[data-disabled]]:opacity-50 before:has-[[data-disabled]]:bg-zinc-950/5 before:has-[[data-disabled]]:shadow-none',
        // Invalid state
        'before:has-[[data-invalid]]:shadow-red-500/10',
      ])}
    >
      <Input
        type="text"
        autoFocus={autoFocus}
        aria-label={ariaLabel}
        className={clsx(className)}
        placeholder={placeholder}
        value={inputValue} // Display the value of the selected option
        onChange={handleInputChange}
        onFocus={() => setIsListOpen(true)}
        onBlur={() => setTimeout(() => setIsListOpen(false), 200)}
        name={name}
        id={id}
        {...props}
      />
      <input type="hidden" name={name} value={selectedValue ?? ''} /> {/* Hidden input for form submission */}
      {isListOpen && inputValue && (
        <ul className="absolute z-10 w-full mt-1 bg-white border border-zinc-950/10 rounded-lg shadow-lg dark:bg-zinc-800 dark:border-white/10">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <li
                key={option.id}
                onMouseDown={() => handleItemClick(option)}
                className={clsx(
                  'cursor-pointer py-2 px-3 text-zinc-950 dark:text-white hover:bg-blue-500 hover:text-white'
                )}
              >
                {option.value}
              </li>
            ))
          ) : (
            <li className="py-2 px-3 text-zinc-500 dark:text-zinc-400">No results</li>
          )}
        </ul>
      )}
    </span>
  );
}
