import {  SubscriptionDataAll} from '../types';
import {  defaultSubscriptionData } from '../data';
import { apiClient } from './apiClient';

//**********************************************/
// ****  GET ADMINS Subscription DATA ALL
//**********************************************/

export const getAllSubscriptionsData = async (): Promise<SubscriptionDataAll[]> => {
  try {
    const response = await apiClient.get<SubscriptionDataAll[]>('/admin/subscriptions');

    return response.data;
  } catch (error) {

    return [];
  }
};



export const CreateSubscription = async (subscription: any) => {
  try {

    //console.log(' creating subscription', subscription);
    const response = await apiClient.post('/admin/subscriptions', subscription);
    return response.data;
  } catch (error) {
   
    throw error;
  }
};
//**********************************************/
// ****  DELETE Subscription DATA 
//**********************************************/
export const DeleteSubscription = async (id: any) => {
  try {

    //console.log(' creating subscription', subscription);
    const response = await apiClient.delete(`/admin/subscriptions/${id}`);
    return response.data;
  } catch (error) {

    throw error;
  }
};

//**********************************************/
// ****  GET ADMINS Subscription DATA ONE
//**********************************************/
// Example API function to fetch subscription details
export const getSubscriptionDetails = async (subscription_id: string): Promise<SubscriptionDataAll> => {
  try {
    const response = await apiClient.get<SubscriptionDataAll>(`/admin/subscriptions/${subscription_id}`);
    const data = response.data;
    return data;
  } catch (error) {
  
    return defaultSubscriptionData;
  }
};

//**********************************************/
// ****  UPDATE ADMINS Subscription DATA 
//**********************************************/
export const updateSubscription = async (subscription_id: number, item: any): Promise<SubscriptionDataAll> => {
  try {
    const response = await apiClient.patch<SubscriptionDataAll>(`/admin/subscriptions/${subscription_id}`, item);
    const data = response.data;
    return data;
  } catch (error) {

    return defaultSubscriptionData;
  }
};

