import React, { useEffect, useState, ChangeEvent, useCallback } from 'react';
import { ContractsData } from '../../../utils/types';
import { FieldGroup, Fieldset, Label, FieldSameLine, ErrorMessage, } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { Button } from '../../../components/button';
import { Heading } from '../../../components/heading';
import { defaultContractsData, contractStatus} from '../../../utils/data';
import { Textarea } from '../../../components/textarea';
import { BackendDate } from '../../../utils/function';
import axios from 'axios';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { createContracts } from '../../../utils/apicall/contractsApi';
import { processError } from '../../../utils/error/errorUtils';
import ComboboxComponent from '../../../components/comboboxNew';



interface DropdownOption {
  id: number;
  value: string;
}
const ContractAdd: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [customerdropdown, setCustomerDropdown] = useState<any>();
  const [contract, setContract] = useState<ContractsData>(defaultContractsData);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [newFile, setNewFile] = useState<File | null>(null);
  const [currentFileUrl, setCurrentFileUrl] = useState<string | null>('') // The current file URL
  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      if (data && Array.isArray(data)) {
        const options = data.map(customer => ({
          id: customer.id,
          value: customer.title,
        }));
        setCustomerDropdown(options);
      }
    } catch (error) {
      console.error('Failed to fetch customer dropdown', error);
    }
  }, []);

  useEffect(() => {
    loadCustomerDropdown();
  }, []);


  const validateForm = () => {

    setValidationMessage('');
    return true;
  };


  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;

    console.log('handleChange  '+name, value);
    let valueToPass: string | number = value;

    setContract({
        ...contract,
        [name]: valueToPass
      });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        if (file) {
          setNewFile(file);
          setCurrentFileUrl(URL.createObjectURL(file)); // Preview the new file
        }
        setNewFile(file);
        const fileURL = URL.createObjectURL(file);
        setContract({
            ...contract,
            contract_file_url: fileURL,
        });
    }
  
};


const handleSubmit = async (e: { preventDefault: () => void; }) => {
   
    e.preventDefault();
    if (validateForm()) {
      try {
        const formData = new FormData();
        
        // Append contract data to FormData
        formData.append('id', contract.id?.toString() || '');
        if (selectedCustomer){
          formData.append('customer_id', selectedCustomer!.id.toString()||"" );
        }else{
          formData.append('customer_id',  '');
        }
        
        
        formData.append('description', contract.description?.toString() || '');
        formData.append('datein', BackendDate(contract.datein!)?.toString() || '');
        formData.append('doy_status', contract.doy_status?.toString() || '');
        formData.append('task_date', BackendDate(contract.task_date!)?.toString() || '');
        formData.append('netvalue', contract.netvalue?.toString() || '');
        formData.append('notes', contract.notes?.toString() || '');
        formData.append('status', contract.status?.toString() || '');
  
        // Append the new file if available
        if (newFile) {
          formData.append('contract_file', newFile);
        }
        const response = await createContracts(formData);  
            
                setSuccessMessage('Contract created successfully');
                setErrorMessage(null);
                
                } catch (error) {
                  console.error('Failed to create project', error);
               // Handle errors using the centralized error handler
        processError(error, setErrors, setErrorMessage, setSuccessMessage);
                /*   if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.errors) {
                        setErrors(error.response.data.errors);
                        setSuccessMessage(null);
                        setErrorMessage(error.response.statusText);
                        setErrorMessage('Please fix the errors and try again.');
                      } else {
                        setErrorMessage('An unexpected error occurred.');
                      }*/
                    }
                

              
    }
  }



  return (

      <div className="container mx-0 p-4">
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Νέα Σύμβαση</Heading>
            
        </div> 

        <form onSubmit={handleSubmit}>
        <Fieldset>
          {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{successMessage}</span>
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
          <FieldGroup>
            <FieldSameLine>
              <Label htmlFor="customer_id">Πελάτης</Label>
              <ComboboxComponent
                  id="customer_id"
                  name="customer_id"
                    options={customerdropdown}
                    selectedOption={selectedCustomer}
                    setSelectedOption={setSelectedCustomer}
                   
                    />
                 {errors && errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="description">Περιγραφή</Label>
              <Textarea
                id="description"
                name="description"
                value={contract.description || ''}
                onChange={handleChange}
                invalid= {errors && errors.hasOwnProperty('description')}
                />
                 {errors && errors.hasOwnProperty('description') && <ErrorMessage>{errors.description[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="datein">Ημ/νία Συμβολαίου</Label>
              <Input
                id="datein"
                type="date"
                name="datein"
                value={contract.datein || ''}
                onChange={handleChange}
                invalid= {errors && errors?.hasOwnProperty('datein')}
                />
                 {errors && errors.hasOwnProperty('datein') && <ErrorMessage>{errors?.datein[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="netvalue">Κόστος</Label>
              <Input
                id="netvalue"
                type="text"
                name="netvalue"
                value={contract.netvalue || ''}
                onChange={handleChange}
                invalid= {errors && errors?.hasOwnProperty('netvalue')}
                />
                 {errors && errors?.hasOwnProperty('netvalue') && <ErrorMessage>{errors?.netvalue[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="notes">Σημειώσεις</Label>
              <Textarea
                id="notes"
                name="notes"
                value={contract.notes || ''}
                onChange={handleChange}
                invalid= {errors && errors?.hasOwnProperty('notes')}
                />
                 {errors && errors?.hasOwnProperty('notes') && <ErrorMessage>{errors?.notes[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="status">Status</Label>
              <Select
                id="status"
                name="status"
                value={contract.status}
                onChange={handleChange}
                invalid= {errors && errors?.hasOwnProperty('status')}
              >
                {contractStatus.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
              {errors && errors?.hasOwnProperty('status') && <ErrorMessage>{errors?.status[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="doy_status">ΔΟΥ Status*</Label>
              <Input
                id="doy_status"
                type="date"
                name="doy_status"
                value={contract.doy_status || ''}
                onChange={handleChange}
                invalid= {errors && errors?.hasOwnProperty('doy_status')}
              />
                {errors && errors?.hasOwnProperty('doy_status') && <ErrorMessage>{errors?.doy_status[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="contract_file">Αρχείο</Label>
              <Input
                id="contract_file"
                type="file"
                name="contract_file"
                onChange={handleFileChange}
                invalid= {errors && errors?.hasOwnProperty('contract_file')}
                />
                  {errors && errors?.hasOwnProperty('contract_file') && <ErrorMessage>{errors?.contract_file[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Label htmlFor="task_date">Ημ/νία Υπενθύμισης για ΔΟΥ*</Label>
              <Input
                id="task_date"
                type="date"
                name="task_date"
                value={contract.task_date || ''}
                onChange={handleChange}
                invalid= {errors && errors?.hasOwnProperty('task_date')}
                />
                  {errors && errors?.hasOwnProperty('task_date') && <ErrorMessage>{errors?.task_date[0]}</ErrorMessage>}
            </FieldSameLine>

            <FieldSameLine>
              <Button type="submit">
                Υποβολή
              </Button>
              {validationMessage && <p className="text-red-500">{validationMessage}</p>}
            </FieldSameLine>
          </FieldGroup>
        </Fieldset>
      </form>
    </div>
  );
};


export default ContractAdd;
