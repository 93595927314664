import { billingCycles, contractStatus, paymentMethods,  projectStatus,  sitesStatus,  statusColors, taskStatus, sitesUpStatus, UpStatusColors, sitesType, dalertsStatus, sitesHealthyStatus, sitesHealthyStatusColors } from "./data";
import { CustomerDataDropDown, HostingDataDropDown, ProductDataDropDown } from "./types";


export const FormatDisplayDateString = (dateString: string): string => {
  if ((dateString==="")||dateString===null){return "-"};
      console.log('dateString data:', dateString);//print 
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
// input date
  export const DisplayDate = (dateString: string) => {
    if (!dateString) {
      return null;
    }
    
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };
  
  // save date
  export const BackendDate = (dateString: string) => {
    if (!dateString) {
      return null;
    }
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    };


    export const getDalertsStatusName= (statusId: string) => {
      const status = dalertsStatus.find(status => status.value === statusId);
      return status && status.value !== '0' ? status.label : 'Unknown status';
    };


    export const getSitesStatusName= (statusId: string) => {
      const status = sitesStatus.find(status => status.value === statusId);
      return status && status.value !== '0' ? status.label : 'Unknown status';
    };

    export const getSitesStatusColor= (statusId: number) => {
      const statuscolor = statusColors[statusId] 
      return statuscolor;
    };


    export const getContractStatusName= (statusId: string) => {
      const status = contractStatus.find(status => status.value === statusId);
      return status && status.value !== '0' ? status.label : 'Unknown status';
    };

    export const getContractStatusColor= (statusId: number) => {
      const statuscolor = statusColors[statusId] || 'Unknown status';
      return statuscolor;
    };

  export const getProjectStatusName= (statusId: string) => {
    const status = projectStatus.find(status => status.value === statusId);
    return status && status.value !== '0' ? status.label : 'Unknown status';
  };

  export const getProjectStatusColor= (statusId: number) => {
    const statuscolor = statusColors[statusId] 
    return statuscolor;
  };

  export const getTaskStatusName= (statusId: string) => {
    const status = taskStatus.find(status => status.value === parseInt(statusId));
    return status && status.value !== 0 ? status.label : 'Unknown status';
  };
  export const getTaskStatusColor= (statusId: number) => {
    const statuscolor = statusColors[statusId] 
    return statuscolor;
  };

  // Function to get the label from the value
  export const getBillingCycleLabel = (value: string) => {
  const cycle = billingCycles.find(cycle => cycle.value === value);
  return cycle && cycle.value !== '0' ? cycle.label : 'Unknown';
};

  // Function to get the label from the value
  export const getPaymentMethodsLabel = (value: string) => {
    const payment = paymentMethods.find(payment => payment.value === value);
    return payment && payment.value !== '0' ? payment.label : 'Unknown';
  };


  export const getCustomerTitle = (customerId: number, customerdropdown: CustomerDataDropDown[]) => {
    const customer = customerdropdown.find(c => c.id === customerId);
    return customer ? customer.title : 'Unknown Customer';
  };


  export const getProductTitle = (productId: number, productdropdown:ProductDataDropDown[]) => {
    const product = productdropdown.find(p => p.id === productId);
    return product ? product.title : 'Unknown Product';
  };


  export const mapCustomersToDropdownOptions = (data: any[]): { id: number | string; value: string }[] => {
    return data.map(customer => ({
      id: customer.id,
      value: customer.title,
    }));
  };

  export const getHostingTitle = (hostingId: number, hostingdropdown: HostingDataDropDown[]) => {
    const hosting = hostingdropdown.find(c => c.id === hostingId);
    return hosting ? hosting.title : 'Unknown Hosting';
  };
  
  export const getUpStatusName= (statusId: number) => {
    const status = sitesUpStatus.find(status => status.id === statusId);
    return status && status.id < 2 ? status.label : 'Unknown status';
  };

  export const getUpStatusColor= (statusId: number) => {
    const statuscolor = UpStatusColors[statusId] 
    return statuscolor;
  }; 
   export const getSitesTypeName= (statusId: string) => {
    const status = sitesType.find(status => status.value === statusId);
    return status && status.value !== '0' ? status.label : 'Unknown';
  };

  export const getSitesHealthyStatusName= (statusId: string) => {
    const status = sitesHealthyStatus.find(c => c.id === parseInt(statusId));
    return status  ? status.label : 'Unknown status';
  };

  export const getSitesHealthyStatusColor= (statusId: number) => {
    const statuscolor = sitesHealthyStatusColors[statusId] 
    return statuscolor;
  }; 