import React from 'react';
import logo from './logo.svg';
import './App.css';
import LayoutWrapper from './layout/LayoutWrapper';
import Dashboard from './pages/admin/dashboard';
import Login from './pages/common/login';
import {Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'; // Import BrowserRouter and other necessary components
import SLA from './pages/sla';
import RoleBasedRoute from './utils/RoleBasedRoute';
import Logout from './pages/common/logout';
import ListCustomers from './pages/admin/customer/customerList';
import AdminListUsers from './pages/AdminListUsers';
import SubscriptionView from './pages/admin/subscription/subscripionView';
import SubscriptionList from './pages/admin/subscription/SubscriptionDashboard';
import SubscriptionAdd from './pages/admin/subscription/subscriptionAdd';
import SubscriptionEdit from './pages/admin/subscription/subscriptionEdit';
import ProductView from './pages/admin/products/productView';
import ProductEdit from './pages/admin/products/productEdit';
import ListProducts from './pages/admin/products/productList';
import { CONFIG } from './utils/config';
import CustomerView from './pages/admin/customer/customerView';
import CustomerEdit from './pages/admin/customer/customerEdit';
import UserPage from './pages/UserPage';
import ProjectView from './pages/admin/projects/projectView';
import ProjectAdd from './pages/admin/projects/projectAdd';
import Support from './pages/common/support';
import AlertViewTab from './pages/admin/dalerts/dalertViewTab';
import ContractAdd from './pages/admin/contracts/contractAdd';
import ProjectDashboard from './pages/admin/projects/projectDashboard';
import ProjectEdit from './pages/admin/projects/projectEdit';
import NotFound from './pages/common/notFound';
import ContractEdit from './pages/admin/contracts/contractEdit';
import ContractView from './pages/admin/contracts/contractView';
import ContractsDashboard from './pages/admin/contracts/contractsDashboard';
import SitesDashboard from './pages/admin/sites/sitesDashboard';
import SitesAdd from './pages/admin/sites/sitesAdd';
import SitesView from './pages/admin/sites/sitesView';
import SitesEdit from './pages/admin/sites/sitesEdit';
import SubscriptionDashboard from './pages/admin/subscription/SubscriptionDashboard';
import AlertsView from './pages/admin/dalerts/dalertsView';




/***Admin
Clients
Users
***/

function App() {
  return (

    <LayoutWrapper>
   
      <Routes>
      <Route path="*" element={<NotFound />} />
        <Route path='/' element={<Login />} />
        <Route path={CONFIG.urls.getLoginUrl()}  element={<Login />} />

        <Route 
          path={CONFIG.urls.getLogoutUrl()} 
          element={
            <RoleBasedRoute allowedRoles={['user', 'admin', 'client']}>
              <Logout />
            </RoleBasedRoute>
          } 
        />
        <Route 
          path={CONFIG.urls.getDashboardUrl()} 
          element={
            <RoleBasedRoute allowedRoles={['user', 'admin']}>
              <Dashboard />
            </RoleBasedRoute>
          } 
        />

        <Route 
          path={CONFIG.urls.getSupportUrl()} 
          element={
            <RoleBasedRoute allowedRoles={['user', 'admin']}>
              <Support />
            </RoleBasedRoute>
          } 
        />

    <Route path={CONFIG.urls.getUserListUrl()} element={  
         <RoleBasedRoute allowedRoles={['admin']}>    
          <UserPage />
          </RoleBasedRoute>
 
      } />
    
    <Route path={CONFIG.urls.getCustomerListUrl()} element={  
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ListCustomers />
          </RoleBasedRoute>
 
      } />

    <Route path={CONFIG.urls.getCustomerViewUrl(':id')} element={  
         <RoleBasedRoute allowedRoles={['admin']}>    
          <CustomerView />
          </RoleBasedRoute>
 
      } />

    <Route path={CONFIG.urls.getCustomerEditUrl(':id')} element={  
         <RoleBasedRoute allowedRoles={['admin']}>    
          <CustomerEdit />
          </RoleBasedRoute>
 
      } />


    <Route path={CONFIG.urls.getProductAddUrl()} element={  
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ListProducts />
          </RoleBasedRoute>
 
      } />
    <Route path={CONFIG.urls.getProductListUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ListProducts />
          </RoleBasedRoute>
 
      } />
 

      <Route path={CONFIG.urls.getProductViewUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ProductView />
          </RoleBasedRoute>
 
      } />
  
  <Route path={CONFIG.urls.getProductEditUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ProductEdit />
          </RoleBasedRoute>
 
      } />

      <Route path={CONFIG.urls.getSubscriptionListUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SubscriptionDashboard />
          </RoleBasedRoute>
 
      } />

      <Route path={CONFIG.urls.getSubscriptionAddUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SubscriptionAdd />
          </RoleBasedRoute>
 
      } />

        <Route path={CONFIG.urls.getSubscriptionViewUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SubscriptionView />
          </RoleBasedRoute>
 
      } />

      <Route path={CONFIG.urls.getSubscriptionEditUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SubscriptionEdit />
          </RoleBasedRoute>
 
      } />

      <Route 
          path={CONFIG.urls.getAlertListUrl()} 
          element={
            <RoleBasedRoute allowedRoles={[ 'admin']}>
              <AlertViewTab />
            </RoleBasedRoute>
          } 
        />

      <Route path={CONFIG.urls.getAlertViewUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <AlertsView />
          </RoleBasedRoute>
 
      } />

    <Route path={CONFIG.urls.getProjetctListUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ProjectDashboard />
          </RoleBasedRoute>
 
      } />
      <Route path={CONFIG.urls.getProjectAddUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ProjectAdd />
          </RoleBasedRoute>
 
      } />

      <Route path={CONFIG.urls.getProjectViewUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ProjectView />
          </RoleBasedRoute>
 
      } />

        <Route path={CONFIG.urls.getProjectEditUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ProjectEdit />
          </RoleBasedRoute>
 
      } />

  
     <Route path={CONFIG.urls.getContractListUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ContractsDashboard />
          </RoleBasedRoute>
 
      } />

    <Route path={CONFIG.urls.getContractAddUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ContractAdd />
          </RoleBasedRoute>
 
      } />

<Route path={CONFIG.urls.getContractEditUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ContractEdit />
          </RoleBasedRoute>
 
      } />

<Route path={CONFIG.urls.getContractViewUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <ContractView />
          </RoleBasedRoute>
 
      } />


<Route path={CONFIG.urls.getSitesListUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SitesDashboard />
          </RoleBasedRoute>
 
      } />

<Route path={CONFIG.urls.getSitesAddUrl()} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SitesAdd />
          </RoleBasedRoute>
 
      } />

<Route path={CONFIG.urls.getSitesEditUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SitesEdit />
          </RoleBasedRoute>
 
      } />

<Route path={CONFIG.urls.getSitesViewUrl(':id')} element={
         <RoleBasedRoute allowedRoles={['admin']}>    
          <SitesView />
          </RoleBasedRoute>
 
      } />




       <Route 
          path='/a-users' 
          element={
            <RoleBasedRoute allowedRoles={['admin']}>
              <AdminListUsers />
            </RoleBasedRoute>
          } 
        />

        <Route 
          path='/sla' 
          element={
            <RoleBasedRoute allowedRoles={['user', 'admin']}>
              <SLA />
            </RoleBasedRoute>
          } 
        />
      </Routes>
   
  </LayoutWrapper>

  )
}

export default App;
