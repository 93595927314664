import {  ContractsData } from '../types';
import {  defaultContractsData } from '../data';
import { apiClient, apiClientFile } from './apiClient';



//**********************************************/
// ****  GET ADMINS CONTRACTS DATA 
//**********************************************/
//GET ALL
export const getContractData = async (): Promise<ContractsData[]> => {
  try {
    const response = await apiClient.get<ContractsData[]>('/admin/contracts/');

    return response.data;
  } catch (error) {
    return [];
  }
};

//Λίστα διαγραμμένων

export const getContractsDeleteData = async (): Promise<ContractsData[]> => {
  try {
    const response = await apiClient.get<ContractsData[]>('/admin/contracts/trashed');

    return response.data;
  } catch (error) {

    return [];
  }
};


//View Single

export const getContractDetails = async (id: string): Promise<ContractsData> => {
  try {
    const response = await apiClient.get<ContractsData>(`/admin/contracts/${id}`);
    const data = response.data;
    return data;
  } catch (error) {

    return defaultContractsData;
    };
  };

 //Create


export const createContracts= async (contracts: any) => {
  try {
    console.log(' creating contracts ...', contracts);
    const response = await apiClientFile.post('/admin/contracts', contracts);
    return response.data;
  } catch (error) {
   
    throw error;
  }
};
//Edit
export const updateContract = async (id: number, item: any): Promise<ContractsData> => {
  try {
    const response = await apiClientFile.patch<ContractsData>(`/admin/contracts/${id}`, item);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching contract information', error);
    return defaultContractsData;
  }
};


//Διαγραφή

export const deleteContracts = async (id: number) => {
  try {
    const response = await apiClient.delete(`/admin/contracts/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
   
    return defaultContractsData;
    };
  };


//Επαναφορά Διαγραφή
  export const restoredeletedContracts = async (id: string): Promise<ContractsData> => {
    try {
      const response = await apiClient.post<ContractsData>(`/admin/contracts/${id}/restore`);
      const data = response.data;
      return data;
    } catch (error) {
   

      return   defaultContractsData;
      ;
      };
    };