import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/button';
import { Dialog, DialogActions, DialogBody } from '../../../components/dialog';
import { FieldSameLine, ErrorMessage } from '../../../components/fieldset';
import { Select } from '../../../components/select';
import { TasksData } from '../../../utils/types';
import { taskStatus } from '../../../utils/data';
import { updateTask } from '../../../utils/apicall/tasksApi';
import { processError } from '../../../utils/error/errorUtils';

interface UpdateTaskProps {
  open: boolean;
  onClose: () => void;
  project_id: string;
  task_id: string;
  task_row: TasksData[];
  onUpdateTaskStatus: () => void;
}

const TaskUpdateStatus: React.FC<UpdateTaskProps> = ({ open, onClose, project_id, task_id, task_row, onUpdateTaskStatus }) => {
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [TaskID, setTaskID] = useState(0);
  const [ProjectID, setProjectID] = useState(0);
  const [tasks, setTasks] = useState<TasksData[]>([]);
  const [SelectedTask, setSelectedTask] = useState<TasksData | undefined>(undefined);

  const handleUpdateStatus = async (): Promise<void> => {
    if (!SelectedTask) {
      setErrorMessage('No task selected.');
      return;
    }
    try {
    const formattedStatus = SelectedTask.status == 0 ? 1 : SelectedTask.status;

    const payload: TasksData = {
      ...SelectedTask,
      status: formattedStatus, // Use the formattedStatus here
    }

      const response = await updateTask(ProjectID, TaskID, payload);

         console.log('response', response);
        setSuccessMessage('Task created successfully');

        setTimeout(() => {
          onClose(); // Close the dialog after the delete operation is successful
          onUpdateTaskStatus(); // Refresh the subscription list
        }, 100); // Optional delay for displaying success message
    } catch (error) {
      processError(error, setErrors, setErrorMessage, setSuccessMessage);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSelectedTask((prev) => (prev ? { ...prev, [name]: parseInt(value) } : undefined));
  };

  useEffect(() => {
    setSuccessMessage(null);
    setErrorMessage(null);
    setTaskID(parseInt(task_id));
    setProjectID(parseInt(project_id));
   // setTasks(task_row);

    const selectedTask = task_row.find(task => task.id === parseInt(task_id));
    setSelectedTask(selectedTask);
  }, [task_id, project_id, task_row]);

  return (
    <div className="container mx-0 p-4">
      <form>
        <Dialog open={open} onClose={onClose} size="lg">
          <DialogBody>
            {successMessage && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{successMessage}</span>
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}
            <FieldSameLine>
              <Select
                id="status"
                name="status"
                value={SelectedTask?.status ?? ''}
                onChange={handleChange}
                invalid={errors.hasOwnProperty('status')}
              >
                {taskStatus.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </Select>
                  {errors.status && <ErrorMessage>{errors.status[0]}</ErrorMessage>}
            </FieldSameLine>
          </DialogBody>
          <DialogActions>
            <Button onClick={onClose}>Ακύρωση</Button>
            <Button color="green" onClick={handleUpdateStatus}>Ενημέρωση</Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default TaskUpdateStatus;
