import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Badge } from '../../../components/badge';
import { Button } from '../../../components/button';
import {  getCustomerTitle, getProjectStatusName, getProjectStatusColor, getSitesStatusColor, getSitesStatusName, getHostingTitle, getSitesHealthyStatusColor, getSitesHealthyStatusName } from '../../../utils/function';
import {  CustomerDataDropDown,  HostingDataDropDown,  SitesData } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { CONFIG } from '../../../utils/config';
import { TextLink } from '../../../components/text';

interface SitesListProps {
  filteredData: SitesData[];
  customerdropdown:CustomerDataDropDown[];
  hostingdropdown:HostingDataDropDown[];
  dataDeleted?: boolean;
  onRestoreData?: (ID: number) => void;
}

enum SortableColumn {
  Domain = 'domain',
  Customer = 'customer',
  Hosting = 'hosting',
  Health ='health'
}

const SitesList: React.FC<SitesListProps> = ({ filteredData,customerdropdown, hostingdropdown, dataDeleted, onRestoreData }) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue: string = '';
        let bValue: string = '';
        switch (sortConfig.key) {
          case SortableColumn.Domain:
            aValue = a.status.toString()!;
            bValue = b.status.toString()!;
            break;
          case SortableColumn.Customer:
            const customerA = getCustomerTitle(a.customer_id!, customerdropdown);
            const customerB = getCustomerTitle(b.customer_id!, customerdropdown);
            aValue = customerA;
            bValue = customerB;
            break;
          case SortableColumn.Hosting:
            const hostingA = getHostingTitle(a.hosting_id!, hostingdropdown);
            const hostingB = getHostingTitle(b.hosting_id!, hostingdropdown);
            aValue = hostingA;
            bValue = hostingB;
            break;
            case SortableColumn.Health:
              const healthyA = getSitesHealthyStatusName(a.health?.toString()||'');
              const healthyB = getSitesHealthyStatusName(b.health?.toString()||'');
              aValue = healthyA;
              bValue = healthyB;
              break;
          default:
            return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig, customerdropdown]);
  
  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader>ID</TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Domain)} className={getClassNamesFor(SortableColumn.Domain)}>
              Domain
              {sortConfig?.key === SortableColumn.Domain && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Customer)} className={getClassNamesFor(SortableColumn.Customer)}>
              Πελάτης
              {sortConfig?.key === SortableColumn.Customer && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
           
            <TableHeader onClick={() => requestSort(SortableColumn.Hosting)} className={getClassNamesFor(SortableColumn.Hosting)}>
            Hosting
              {sortConfig?.key === SortableColumn.Hosting && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Health)} className={getClassNamesFor(SortableColumn.Health)}>
            Healthy
              {sortConfig?.key === SortableColumn.Health && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader className="relative w-0">
              <span className="sr-only">Actions</span>
            </TableHeader>
            {dataDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.id}>
             
              
              {dataDeleted && onRestoreData ? (
                <>
                 <TableCell className="font-medium">{item.id}</TableCell>
                 <TableCell className="font-medium whitespace-normal">{item.domain}</TableCell>
                 <TableCell className="font-medium whitespace-normal">{getCustomerTitle(item.customer_id!, customerdropdown)}</TableCell>
                </>
              ) : (
                <>
                  <TableCell className="font-medium"><TextLink href={CONFIG.urls.getSitesViewUrl(item.id!)}>{item.id}</TextLink></TableCell>
                  <TableCell className="font-medium whitespace-normal"><TextLink href={CONFIG.urls.getSitesViewUrl(item.id!)}>{item.domain}</TextLink></TableCell>
                  <TableCell className="font-medium whitespace-normal"><TextLink href={CONFIG.urls.getCustomerViewUrl(item.customer_id!)}>{getCustomerTitle(item.customer_id!, customerdropdown)}</TextLink></TableCell>
                
                </>
              )}
              
              <TableCell className="font-medium whitespace-normal">{getHostingTitle(item.hosting_id!, hostingdropdown)}</TableCell>
                  <TableCell className="font-medium whitespace-normal"><Badge color={getSitesHealthyStatusColor(item.health||0)}> {getSitesHealthyStatusName(item.health?.toString()|| '')} </Badge></TableCell>
                
              {dataDeleted && onRestoreData ? (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreData(item.id!) }}>Restore</Button>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                    <Dropdown>
                      <DropdownButton plain aria-label="More options">
                        <EllipsisVerticalIcon />
                      </DropdownButton>
                      <DropdownMenu anchor="bottom end">
                        <DropdownItem href={CONFIG.urls.getSitesViewUrl(item.id!)}>View</DropdownItem>
                        <DropdownItem href={CONFIG.urls.getSitesEditUrl(item.id!)}>Edit</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default SitesList;
