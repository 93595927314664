import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerData } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import { FieldGroup, FieldSameLine, Fieldset, Label, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { getCustomerDetail } from '../../../utils/apicall/customersApi';
import { defaultCustomersData } from '../../../utils/data';
import { processError } from '../../../utils/error/errorUtils';


const CustomerEdit: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {id } = useParams<{ id: string }>();
  const [customer, setCustomer] = useState<CustomerData>(defaultCustomersData);
 
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
//******************************** */
//  GET SUBSCRIPTION
//*********************************/
  const loadCustomerDetails = async (id: string) => {
    try {
  
      const data = await getCustomerDetail(id);
       setCustomer(data);

    } catch (error) {
      console.error('Failed to fetch customer', error);
      // Handle error by setting subscription to an empty array
    }
  };


//******************************** */
// Handle Change
//******************************** */
  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
 
    setCustomer({
        ...customer,
        [name]: value
      });

};


//******************* */
// Validate Form
//******************* */
const validateForm = () => {
  if (!customer.id ||
    !customer.title|| 
    !customer.phone ){
    setValidationMessage('All fields are required.');
    return false;
  }
  setValidationMessage('');
  return true;
};


//******************* */
// UPDATE FORM
//******************* */

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
   
    e.preventDefault();
    if (validateForm()) {
    }
   // processError(error, setErrors, setErrorMessage, setSuccessMessage);
  };

  //******************* */
  // LOAD ALL FUNCTION
  //******************* */
  useEffect(() => {
    if (id) {
     
      loadCustomerDetails(id!);
   
      
    }
  }, [id]);

  return (
        
    <div >
    {customer ? (
       <>
       <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
         <Heading level={2}>Επεξεργασία Πελάτη: {customer.title}</Heading>
       
       </div>
    
       <form onSubmit={handleSubmit} className='py-4'>

         <Fieldset>
           <FieldGroup>
             <FieldSameLine className="flex items-center space-x-2">
              <Label>ID</Label>
               <Input
                 id="id"
                 type="text"
                 name="id"
                 value={customer.id|| ''}
                 readOnly // This maes the field read-only
               />
             </FieldSameLine>
             <FieldSameLine className="flex items-center space-x-2">
              <Label>Είδος Πελάτη</Label>
               <Input
                 id="entrytype"
                 type="text"
                 name="entrytype"
                 value={customer.entrytype|| ''}
                 onChange={handleChange}
                 invalid= {errors && errors.hasOwnProperty('entrytype')}
                 />
                  {errors && errors.hasOwnProperty('entrytype') && <ErrorMessage>{errors.entrytype[0]}</ErrorMessage>}
             </FieldSameLine>

             <FieldSameLine className="flex items-center space-x-2">
              <Label>Υπόλοιπο</Label>
               <Input
                 id="debit"
                 type="text"
                 name="debit"
                 value={customer.debit|| ''}
                 onChange={handleChange}
                 invalid= {errors && errors.hasOwnProperty('debit')}
                 />
                   {errors && errors.hasOwnProperty('debit') && <ErrorMessage>{errors.debit[0]}</ErrorMessage>}
             </FieldSameLine>
             <FieldSameLine className="flex items-center space-x-2">
              <Label>Τηλέφωνο</Label>
               <Input
                 id="phone"
                 type="text"
                 name="phone"
                 value={customer.phone|| ''}
                 onChange={handleChange}
                 invalid= {errors && errors.hasOwnProperty('phone')}
                 />
                   {errors && errors.hasOwnProperty('phone') && <ErrorMessage>{errors.phone[0]}</ErrorMessage>}
             </FieldSameLine>
             <FieldSameLine>
               <Label>Τίτλος</Label>
               <Input
                 id="title"
                 type="text"
                 name="title"
                 value={customer.title|| ''}
                 onChange={handleChange}
                 invalid= {errors && errors.hasOwnProperty('title')}
                 />
                  {errors &&  errors.hasOwnProperty('title') && <ErrorMessage>{errors.title[0]}</ErrorMessage>}
             </FieldSameLine>
             <FieldSameLine>
               <Label>Επωνυμία</Label>
               <Input
                 id="nicename"
                 type="text"
                 name="nicename"
                 value={customer.nicename|| ''}
                 onChange={handleChange}
                 invalid= {errors && errors.hasOwnProperty('nicename')}
                 />
                   {errors && errors.hasOwnProperty('nicename') && <ErrorMessage>{errors.nicename[0]}</ErrorMessage>}
             </FieldSameLine>
      
             <FieldSameLine>
                <Label>ΑΦΜ</Label>
                <Input
                  id="vat"
                  type="text"
                  name="vat"
                  value={customer.vat|| ''}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('vat')}
                 />
                   {errors && errors.hasOwnProperty('vat') && <ErrorMessage>{errors.vat[0]}</ErrorMessage>}
              </FieldSameLine>

              <FieldSameLine className="flex items-center space-x-2">
                <Label>Email</Label>
                <Input
                    id="email"
                    type="text"
                    name="email"
                    value={customer.email|| ''}
                    onChange={handleChange}
                    invalid= {errors && errors.hasOwnProperty('email')}
                    />
                      {errors && errors.hasOwnProperty('email') && <ErrorMessage>{errors.email[0]}</ErrorMessage>}
              </FieldSameLine>
              <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                     <Heading level={2}>Υπεύθυνος επικοινωνίας</Heading>
       
                </div>
             
              <FieldSameLine>
                <Label>Όνομα</Label>
                <Input
                        id="resFirstName"
                        type="text"
                        name="resFirstName"
                        value={customer.resFirstName|| ''}
                        onChange={handleChange}
                        invalid= {errors && errors.hasOwnProperty('resFirstName')}
                        />
                         {errors && errors.hasOwnProperty('resFirstName') && <ErrorMessage>{errors.resFirstName[0]}</ErrorMessage>}
                </FieldSameLine>
                <FieldSameLine className="flex items-center space-x-2">
                <Label>Επώνυμο</Label>
                <Input
                        id="resLastName"
                        type="text"
                        name="resLastName"
                        value={customer.resLastName|| ''}
                        onChange={handleChange}
                        
                        invalid= {errors && errors.hasOwnProperty('resLastName')}
                        />
                         {errors && errors.hasOwnProperty('resLastName') && <ErrorMessage>{errors.resLastName[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label>Email</Label>
                <Input
                        id="resEmail"
                        type="text"
                        name="resEmail"
                        value={customer.resEmail|| ''}
                        onChange={handleChange}
                        invalid= {errors && errors.hasOwnProperty('resEmail')}
                        />
                          {errors && errors.hasOwnProperty('resEmail') && <ErrorMessage>{errors.resEmail[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label>Τηλέφωνο</Label>
                  <Input
                        id="resPhone"
                        type="text"
                        name="resPhone"
                        value={customer.resPhone|| ''}
                        onChange={handleChange}  
                        invalid= {errors && errors.hasOwnProperty('resPhone')}
                        />
                          {errors && errors.hasOwnProperty('resPhone') && <ErrorMessage>{errors.resPhone[0]}</ErrorMessage>}
                </FieldSameLine> 
            
              <FieldSameLine>
               <Button type="submit" color="blue">Save</Button>
             </FieldSameLine>
           </FieldGroup>
         </Fieldset>
       </form>
     </>
   ) : (
     <div>No subscription found.</div>
   )}
   </div>

          
  );
};


export default CustomerEdit;