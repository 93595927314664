export const CONFIG = {
    urls: {
      apiEndpoint: 'https://wecosys2.limeframe.gr/api',
      userEndpoint: 'https://wecosys2.limeframe.gr/api/users',
      getLoginUrl: () => `/login`,
      getLogoutUrl: () => `/logout`,
      getDashboardUrl: () => `/dashboard`,
      // Support
      getSupportUrl: () => `/support`,
      // Subscription
      getSubscriptionListUrl: () => `/subscriptions/`,
      getSubscriptionViewUrl: (id: string | number) => `/subscription/${id}`,
      getSubscriptionEditUrl: (id: string | number) => `/subscription/${id}/edit`,
      getSubscriptionAddUrl: () => `/subscription/add`,
      
      // Product
      getProductListUrl: () => `/products`,
      getProductViewUrl: (id: string | number) => `/product/${id}`,
      getProductEditUrl: (id: string | number) => `/product/${id}/edit`,
      getProductAddUrl: () => `/product/add`,
      // customer
      getCustomerListUrl: () => `/customers/`,
      getCustomerViewUrl: (id: string | number) => `/customer/${id}`,
      getCustomerEditUrl: (id: string | number) => `/customer/${id}/edit`,
      // User
      getUserListUrl: () => `/users/`,
       // Project
      getProjetctListUrl: () => `/projects`,
      getProjectViewUrl: (id: string | number) => `/project/${id}`,
      getProjectEditUrl: (id: string | number) => `/project/${id}/edit`,
      getProjectAddUrl: () => `/project/add`,
      // Contract
      getContractListUrl: () => `/contracts/`,
      getContractViewUrl: (id: string | number) => `/contracts/${id}`,
      getContractEditUrl: (id: string | number) => `/contract/${id}/edit`,
      getContractAddUrl: () => `/contracts/add`,
      // TASK
      getTaskEditUrl: (project_id: string | number,id: string | number) => `/projects/${project_id}/tasks/${id}edit`,
      // SITES
      getSitesListUrl: () => `/sites`,
      getSitesViewUrl: (id: string | number) => `/sites/${id}`,
      getSitesEditUrl: (id: string | number) => `/sites/${id}/edit`,
      getSitesAddUrl: () => `/sites/add`,
     
      // Alert
       getAlertListUrl: () => `/dalert`,
       getAlertViewUrl: (id: string | number) => `/dalert/${id}`,

      getAdminIconUrl: () => `https://img.icons8.com/fluency-systems-filled/48/737373/system-administrator-male.png`,
    },
    
  };