import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthenticated, resetRole } from '../../redux/actions/authAction';
import { useAuth } from '../../utils/authContext';


const Logout: React.FC = () => {
    const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const {logout } = useAuth();

  useEffect(() => {
    logout();

    // Redirect to the login page
    navigate('/login');
  }, [logout,dispatch, navigate]);

  return null;
};

export default Logout;

