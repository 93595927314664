
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../../components/alert';
import { Button } from '../../../components/button';
  

  interface DeleteAlertProps {
    open: boolean;
    onClose: () => void;
    title: string,
    description: string,
    id:number;
    onDeleteAccept: () => void;
  }

  const DeleteAlert: React.FC<DeleteAlertProps> = ({ open, onClose, title, description, id, onDeleteAccept }) => {
  
    const handleConfirm = () => {
    onDeleteAccept();
    onClose(); // Close the alert after successful deletion
  };

  return (
    <Alert open={open} onClose={onClose} size="lg">
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>
        {description}
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>
          Ακύρωση
        </Button>
        <Button onClick={handleConfirm}>
          Επιβεβαίωση
        </Button>
      </AlertActions>
    </Alert>
  );
};


export default DeleteAlert;