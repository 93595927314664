import { clsx } from 'clsx'
import { Link } from './link'
import { useState } from 'react';

export function Text({ className, ...props }: React.ComponentPropsWithoutRef<'p'>) {
  return (
    <p
      data-slot="text"
      {...props}
      className={clsx(className, 'text-base/6 text-zinc-500 sm:text-sm/6 dark:text-zinc-400')}
    />
  )
}

export function TextLink({ className, ...props }: React.ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Link
      {...props}
      className={clsx(
        className,
        'text-black decoration-gray-500/50 hover:text-blue-700 hover:decoration-blue-700 dark:text-gray-500 dark:decoration-gray-500/50 dark:hover:text-blue-700 dark:hover:decoration-blue-700'
      )}
    />
  )
}
// 'text-gray-500 decoration-gray-500/50 hover:text-blue-700 hover:decoration-blue-700 dark:text-gray-500 dark:decoration-gray-500/50 dark:hover:text-blue-700 dark:hover:decoration-blue-700'

//'text-blue-700 decoration-blue-700/50 data-[hover]:decoration-blue-700 dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white'

export function TextButton({ className, onClick, ...props }: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const [isActive, setIsActive] = useState(false);

  return (
    <button
      {...props}
      onClick={onClick}
      className={clsx(
        className,
        'text-black decoration-gray-500/50 hover:text-blue-700 hover:decoration-blue-700',
        'dark:text-gray-500 dark:decoration-gray-500/50 dark:hover:text-blue-700 dark:hover:decoration-blue-700',
        {
          'text-blue-700 decoration-blue-700': isActive, // Active styles
        }
      )}
      onMouseDown={() => setIsActive(true)} // Set active state on mouse down
      onMouseUp={() => setIsActive(false)}  // Reset active state on mouse up
      onMouseLeave={() => setIsActive(false)} // Ensure reset when mouse leaves
    />
  );
}

export function Strong({ className, ...props }: React.ComponentPropsWithoutRef<'strong'>) {
  return <strong {...props} className={clsx(className, 'font-medium text-zinc-950 dark:text-white')} />
}

export function Code({ className, ...props }: React.ComponentPropsWithoutRef<'code'>) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        'rounded border border-zinc-950/10 bg-zinc-950/[2.5%] px-0.5 text-sm font-medium text-zinc-950 sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white'
      )}
    />
  )
}
