import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerDataDropDown, HostingDataDropDown, SitesData, pluginsData, themesData } from '../../../utils/types';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { DescriptionList, DescriptionTerm, DescriptionDetails } from '../../../components/description-list';
import { Heading } from '../../../components/heading';
import { TextLink } from '../../../components/text';
import { EllipsisVerticalIcon, FunnelIcon, PlusIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { getCustomerTitle, getHostingTitle, getUpStatusColor, getUpStatusName, getSitesTypeName, getSitesHealthyStatusColor, getSitesHealthyStatusName } from '../../../utils/function';
import { defaultSitesData } from '../../../utils/data';
import { getHostingDataDropDown, getSitesDetails } from '../../../utils/apicall/sitesApi';
import { Button } from '../../../components/button';
import ThemesList from './themesList';
import PluginList from './pluginList';
import { Badge } from '../../../components/badge';

const SitesView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [hostingdropdown, setHostingDropdown] = useState<HostingDataDropDown[]>([]);
  const [isDeleteDataOpen, setDeleteDataOpen] = useState(false);
  const [DataID, setDataID] = useState<number>(0);
  const [data, setData] = useState<SitesData>(defaultSitesData);
  const [plugindata, setPluginData] = useState<pluginsData[]>([]);
  const [themedata, setThemeData] = useState<themesData[]>([]);

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer dropdown', error);
    }
  }, []);

  const loadDataDetails = useCallback(async (id: string) => {
    try {
      const data = await getSitesDetails(id);
      setData(data);
        if (data){
          setPluginData(data.plugins!);
          setThemeData(data.themes!);
        }
      console.log('Sites data:', data);
    } catch (error) {
      console.error('Failed to fetch Sites', error);
    }
  }, []);

  const loadHostingDropdown = useCallback(async () => {
    try {
      const data = await getHostingDataDropDown();
      setHostingDropdown(data);
    } catch (error) {
      console.error('Failed to fetch hosting', error);
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadCustomerDropdown();
      loadHostingDropdown();
      loadDataDetails(id);
    }
  }, [id, loadCustomerDropdown, loadHostingDropdown, loadDataDetails]);

  const handleOpenDeleteSites = (id: number) => {
    setDeleteDataOpen(true);
    setDataID(id);
  };

  const handleCloseDeleteSites = () => {
    setDeleteDataOpen(false);
  };

  const handleDeleteSuccess = () => {
    loadDataDetails(id!);
  };

  return (
    <div className="container mx-0 p-4">
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>{data.domain}</Heading>
        <div className="flex gap-1">
          <div className="-mx-3 -my-1.5 sm:-mx-2.5">
            <Dropdown>
              <DropdownButton plain aria-label="More options">
                <EllipsisVerticalIcon />
              </DropdownButton>
              <DropdownMenu anchor="bottom end">
                <DropdownItem href={CONFIG.urls.getSitesEditUrl(id!)}>Edit</DropdownItem>
               
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>


      <DescriptionList>
        <DescriptionTerm>ID</DescriptionTerm>
        <DescriptionDetails>{data.id}</DescriptionDetails>

        <DescriptionTerm>Domain</DescriptionTerm>
        <DescriptionDetails>{data.domain}</DescriptionDetails>

        <DescriptionTerm>Πελάτης</DescriptionTerm>
        <DescriptionDetails>
          <TextLink href={CONFIG.urls.getCustomerViewUrl(data.customer_id!)}>{getCustomerTitle(data.customer_id, customerdropdown)}</TextLink>
        </DescriptionDetails>

        <DescriptionTerm>Hosting</DescriptionTerm>
        <DescriptionDetails>
         {getHostingTitle(data.hosting_id, hostingdropdown)}
        </DescriptionDetails>
        <DescriptionTerm>Type</DescriptionTerm>
        <DescriptionDetails>
                {getSitesTypeName(data.type.toString())}
        </DescriptionDetails>
        <DescriptionTerm>Healthy</DescriptionTerm>
        <DescriptionDetails>
        <Badge color={getSitesHealthyStatusColor(data.health||0)}> {getSitesHealthyStatusName(data.health?.toString()||'')} </Badge>
        </DescriptionDetails>
        <DescriptionTerm>Σημειώσεις</DescriptionTerm>
        <DescriptionDetails>
                {data.notes}
        </DescriptionDetails>
      </DescriptionList>
      


      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>Plugin</Heading>
        <div className="-mx-3 -my-1.5 sm:-mx-2.5" style={{ display: 'none' }}>
          <Button plain><PlusIcon /></Button>
      
        </div>
      </div>
 

      <PluginList filteredData={plugindata} dataDeleted={false}/>

      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>Themes</Heading>
        <div className="-mx-3 -my-1.5 sm:-mx-2.5" style={{ display: 'none' }}>
          <Button plain ><PlusIcon /></Button>
        
        </div>
       
      </div>

      <ThemesList filteredData={themedata} dataDeleted={false} />
    </div>
  );
};

export default SitesView;
