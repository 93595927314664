
import { Alert, AlertActions, AlertDescription, AlertTitle } from '../../../components/alert';
import { Button } from '../../../components/button';
  

  interface AlertQuestionProps {
    open: boolean;
    onClose: () => void;
    title: string,
    description: string,
    onCreateAccept: () => void;
    titlebuttonAccept: string;
    titlebuttonCancel: string;
    
  }

   const QuestionAlert: React.FC<AlertQuestionProps> = ({ open, onClose, title, description,titlebuttonAccept,titlebuttonCancel,  onCreateAccept }) => {
  
    const handleConfirm = () => {
    onCreateAccept();
    onClose(); // Close the alert after successful deletion
  };

  return (
    <Alert open={open} onClose={onClose} size="lg">
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>
        {description}
      </AlertDescription>
      <AlertActions>
        <Button plain onClick={onClose}>
        {titlebuttonCancel}
        </Button>
        <Button onClick={handleConfirm}>
          {titlebuttonAccept}
        </Button>
      </AlertActions>
    </Alert>
  );
};


export default QuestionAlert;

