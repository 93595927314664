/*import axios, { AxiosError } from 'axios';
import { ApiError } from './error';


// Define a type for form errors
interface FormErrors {
  [key: string]: string[];
}

// Function to handle form and API errors
export const processError = (
  error: unknown,
  setErrors: (errors: FormErrors) => void,
  setErrorMessage: (message: string | null) => void,
  setSuccessMessage: (message: string | null) => void
) => {
  if (axios.isAxiosError(error) && error.response?.data?.errors) {
    // Set errors from API response
    const errors = error.response.data.errors as FormErrors;
    setErrors(errors);
    setSuccessMessage(null);
    setErrorMessage('Please fix the errors and try again.');
  } else if (error instanceof ApiError) {
    // Handle custom API errors
    setErrorMessage(`API Error: ${error.message}`);
  } else {
    // Handle unexpected errors
    setErrorMessage('An unexpected error occurred.');
  }
};*/
import axios from 'axios';
import { ApiError } from './error';

interface FormErrors {
  [key: string]: string[];
}

export const processError = (
  error: unknown,
  setErrors: (errors: FormErrors) => void,
  setErrorMessage: (message: string | null) => void,
  setSuccessMessage: (message: string | null) => void
) => {
  if (axios.isAxiosError(error)) {
    const statusCode = error.response?.status;
    
    if (error.response?.data) {
      // Set errors from API response
      const errors = error.response.data.errors as FormErrors;
      let message ='';
      if (error.response.data.error){
         message = error.response.data.error;
      }
      if (error.response.data.message){
         message = error.response.data.message;
      }
      console.log("test--", error.response?.data);
      setErrors(errors);
      setSuccessMessage(null);
      //setErrorMessage(`Please fix the errors and try again. (Status: ${statusCode})`);
      setErrorMessage(`${message} `);
    } else {
      // Handle generic API errors with status code
        setErrorMessage(`API Error: ${error.message}- `);
    }
  } else if (error instanceof ApiError) {
    // Handle custom API errors
    setErrorMessage(`API Error: ${error.message}`);
  } else {
    // Handle unexpected errors
    setErrorMessage('An unexpected error occurred.');
  }
};
