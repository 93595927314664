// src/components/Items.tsx
import React, { useEffect, useState } from 'react';
import { ProductData } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '../../../components/table';
import { MagnifyingGlassIcon, EllipsisVerticalIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/16/solid'
import { TextLink } from '../../../components/text';
import { useNavigate } from 'react-router-dom';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/dropdown';
import { Input, InputGroup } from '../../../components/input';
import { getProductsData } from '../../../utils/apicall/productsApi';




const ListProducts: React.FC = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<ProductData[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredProducts, setFilteredProducts] = useState<ProductData[]>([]);

  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortField, setSortField] = useState<'title' | 'annually'>('title');


  const handleSort = (field: 'title' | 'annually') => {
    const order = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(order);
    setSortField(field);

    const sorted = [...filteredProducts].sort((a, b) => {
      if (field === 'title') {
        return order === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
      } else {
        const aAnnually = parseFloat(a.annually) || 0;
        const bAnnually = parseFloat(b.annually) || 0;
        return order === 'asc' ? aAnnually - bAnnually : bAnnually - aAnnually;
      }
    });
    setFilteredProducts(sorted);
  };

  const loadProducts = async () => {
    try {
      const data = await getProductsData();
      setProducts(data);
      setFilteredProducts(data);
    } catch (error) {
      console.error('Failed to fetch customers', error);
    }
  };

  const handleCreate = async () => {
    try {
    
      //setNewItem('');
    } catch (error) {
      console.error('Failed to create item', error);
    }
  };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      setSearchQuery(query);
      if (query) {
        const filtered = products.filter((product) =>
          product.title.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredProducts(filtered);
      } else {
        setFilteredProducts(products);
      }
    };
  
  


  useEffect(() => {
    loadProducts();
   
  }, []);

  return (
      <>
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}> Υπηρεσίες</Heading>
        <div className="flex ">

        </div>
      </div>
      <div className="flex mt-4 mb-4">
        <InputGroup>

          <MagnifyingGlassIcon />
          <Input
            type="search"
            name="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search ..." />
        </InputGroup>
      </div>
   
        <Table>
          <TableHead>
            <TableRow>

              <TableHeader onClick={() => handleSort('title')} className="cursor-pointer">
                Τίτλος {sortField === 'title' && (sortOrder === 'asc' ? <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                  <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />)}
              </TableHeader>
              <TableHeader>ERP</TableHeader>
              <TableHeader onClick={() => handleSort('annually')} className="cursor-pointer">
                Ετήσια τιμή {sortField === 'annually' && (sortOrder === 'asc' ? <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                  <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />)}
              </TableHeader>
              <TableHeader className="relative w-0">
                <span className="sr-only">Actions</span>
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProducts.map((item) => (
              <TableRow key={item.id}>
                <TableCell className="font-medium whitespace-normal"><TextLink href={CONFIG.urls.getProductViewUrl(item.id!)}>{item.title}</TextLink></TableCell>
                <TableCell className="font-medium whitespace-normal">{item.erpcode}</TableCell>
                <TableCell className="font-medium whitespace-normal">{item.annually}</TableCell>
                <TableCell>

                </TableCell>
                <TableCell className="text-zinc-500">
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                  </div>

                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

     </>
);
};
export default ListProducts;
/*<DropdownItem href={CONFIG.urls.getProductEditUrl(item.id!)}>Edit</DropdownItem>
<DropdownItem href='#'>Delete</DropdownItem>
 <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu anchor="bottom end">
                    <DropdownItem href={CONFIG.urls.getProductViewUrl(item.id!)}>View</DropdownItem>
                    
                    
                  </DropdownMenu>
                </Dropdown>
              </div>
*/ 