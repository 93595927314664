import { getHostingDataDropDown } from "./apicall/sitesApi";
import { ProjectsData, TasksData, ContractsData, SubscriptionDataAll, ProductData, CustomerData, SitesData, pluginsData, themesData, DalertData } from "./types";

    //******** DEFAULT  DATA  */


    export const defaultCustomersData: CustomerData =  {
      id: 0,
      title: '',
      nicename: '',
      vat: '',
      email: '',
      phone: '',
      address: '',
      pc:'',
      city:'',
      status:0,
      status_date: '',
      erpcode:'',
      debit:'',
      entrytype:'',
      notes:'',
      erp:'',
      resFirstName:'',
      resLastName: '',
      resEmail: '',
      resPhone: '',
      resStatus:0
    }

    export const defaultProductData: ProductData =  {
      id: 0,
      title: "",
      description: "",
      qty: "",
      monthly: "",
      quarterly: "",
      semiannuall: "",
      annually: "",
      biannually: "",
      triennially: "",
      quadrennial: "",
      erpcode: "",
      cat_id: 0,
      erp: "",
      space: ""
    }

    export const defaultProjectsData: ProjectsData =  {
        lead_id: null,
        customer_id: null,
        contract_id: null,
        proposal_id: null,
        title: null,
        description: null,
        netvalue: null,
        costvalue: null,
        cost_description:null,
        domain: null,
        start_date:null,
        due_date: null,
        end_date: null,
        notes: null,
        status: 0,
        deleted_at:  null,
        created_at:  null,
        updated_at: null,
        tasks: [],
        milestones: []
      }


      export const defaultSubscriptionData: SubscriptionDataAll = {
        id:0,
        customer_id:0,
        domain:'',
        product_id:0,
        product_price: '',
        quantity:0,
        billing_cycle:'',
        tax_rate:'',
        tax:'',
        total:'',
        start_date:'',
        next_due_date:'',
        termination_date:'',
        first_payment_amount:'',
        reccuring_amount:'',
        payment_method:'',
        status:0,
        invoice_generation:0
      };

      export const defaultTaskData: TasksData =  {
        project_id: null,
        task_parent_id: null,
        title:null,
        description:null,
        status:0,
        start_date:null,
        due_date:null,
        end_date:null,
        deleted_at:  null,
        created_at:  null,
        updated_at: null,
     
      }



      export const defaultContractsData: ContractsData =  {
        customer_id: 0,
        description: '',
        datein: '',
        netvalue: 0,
        notes: '',
        status: 0,
        doy_status: 0,
        contract_file_url: '',
        task_date: ''
      }



      export const defaultSitesData: SitesData = {
        customer_id: 0,
        type: 0,
        domain: '',
        notes: '',
        status: 0,
        hosting_id: 0,
        upstatus: 0,
        health: 0
      }

      export const defaultPluginsData: pluginsData = {
        site_id: 0,
        name: '',
        domain_version:'',
        remote_version:'',
        upstatus:  0
      }
      export const defaultThemesData: themesData = {
        site_id: 0,
        name: '',
        domain_version:'',
        remote_version:'',
        upstatus:  0
      }

      export const defaultDalertData: DalertData = {
        customer_id: 0,
        due_date: '',
        due_date_5: '',
        total_due: '',
        subtotal: '',
        vat_total: '',
        total: '',
        status: 0,
        invoice_description: ''
      }



     /* export const projectStatus: { [key: number]: string } = {
        0: 'Select',
        1: 'Αναμονή έναρξης',
        2: 'Σε εξέλιξη',
        3: 'Σε παύση',
        4: 'Αναμονή υλικού',
        5: 'Ολοκληρωμένο',
        6: 'Τιμολογημένο',
        7: 'Εξοφλημένο'
      };*/




      
 type BadgeColor = 'red' | 'orange' | 'amber' | 'yellow' | 'lime' | 'green' | 'emerald' | 'teal' | 'cyan' | 'sky' | 'blue' | 'indigo' | 'violet' | 'violet900' |'purple' | 'fuchsia' | 'pink' | 'rose' | 'zinc';
      
      export const statusColors: { [key: number]: BadgeColor } = {
        0: 'zinc',
        1: 'amber',
        2: 'lime',
        3: 'red',
        4: 'cyan',
        5: 'blue',
        6: 'violet',
        7: 'violet900'
      };
          
      export const sitesUpStatus=[
        { id: 0,  label:'Healthy'},
        { id: 1, label:'Needs Update'},
      
      ];
          
  export const UpStatusColors: { [key: number]: BadgeColor } = {
    0: 'green',
    1: 'red'
  }; 



      export const taskStatus = [
        { value: 0, label: 'Select' },
        { value: 1, label: 'Αναμονή έναρξης', },
        { value: 2, label: 'Σε εξέλιξη'},
        { value: 3, label: 'Ολοκληρώθηκε'}
      ];


      export const taskStatusFilterMenu = [
        { value: 0, label: 'Όλα'},
        { value: 1, label: 'Αναμονή έναρξης'},
        { value: 2, label: 'Σε εξέλιξη'},
        { value: 3, label: 'Ολοκληρώθηκε'},
        { value: 4, label: 'Διαγραμμένα'}
      ];

      export const dalertsStatus = [
        { value: '5', label: '1η Ειδοποίηση' },
        { value: '9', label: '2η Ειδοποίηση' },
        { value: '10', label: 'Εκπρόθεσμη Ειδοποίηση' },
        { value: '99', label: 'Εξοφλημένο' },
      ];



      export const paymentMethods = [
        { value: '', label: 'Select' },
        { value: 'cash', label: 'Μετρητά' },
        { value: 'card', label: 'Πιστωτική/Χρεωστική Κάρτα' },
        { value: 'bank', label: 'Τραπεζική Κατάθεση' },
      ];
      
      export const billingCycles = [
        { value: '', label: 'Select' },
        { value: 'monthly', label: 'Μήνας' },
        { value: 'quarterly', label: 'Τρίμηνο' },
        { value: 'semiannually', label: 'Εξάμηνο' },
        { value: 'annually', label: 'Έτος' },
        { value: 'biannually', label: 'Διετία' },
        { value: 'triennially', label: 'Τριετία' },
        { value: 'quadrennial', label: 'Τετραετία' },
      ];


      export const contractStatus = [
        { value: '', label: 'Select' },
        { value: '1', label: 'Αναμονή υπογραφής' },
        { value: '2', label: 'Αναμονή υποβολή στην ΔΟY'},
        { value: '3', label: 'Υποβλήθηκε' }
  
      ];

      export const contractStatusFilterMenu = [
        { value: 0, label: 'Όλα'},
        { value: 1, label: 'Αναμονή υπογραφής'},
        { value: 2, label: 'Αναμονή υποβολή στην ΔΟY'},
        { value: 3, label: 'Υποβλήθηκε'},
        { value: 4, label: 'Διαγραμμένα'}
      ];


      export const projectStatus=[
        { value:'0', label:'Select'},
        { value: '1', label:'Αναμονή έναρξης'},
        { value: '2', label:'Σε εξέλιξη'},
        { value: '3', label:'Σε παύση'},
        { value: '4', label:'Αναμονή υλικού'},
        { value: '5', label:'Ολοκληρωμένο'},
        { value: '6',label:'Τιμολογημένο'},
        { value: '7', label:'Εξοφλημένο'}
        ];


      export const projectStatusFilterMenu = [
        { value:'0', label: 'Όλα'},
        { value: '1', label: 'Αναμονή έναρξης' },
        { value: '2', label: 'Σε εξέλιξη' },
        { value: '3', label: 'Σε παύση' },
        { value: '4', label: 'Αναμονή υλικού' },
        { value: '5', label: 'Ολοκληρωμένο' },
        { value: '6', label: 'Τιμολογημένο' },
        { value: '7', label: 'Εξοφλημένο' },
        { value:  'trashed', label: 'Διαγραμμένα' },
      ];

      export const sitesStatus=[
        { value:'0', label:'Select'},
        { value: '1', label:'Αναμονή έναρξης'},
        { value: '2', label:'Σε εξέλιξη'},
        ];


      export const sitesStatusFilterMenu = [
        { value:'0', label: 'Όλα'},
        { value: '1', label: 'Αναμονή έναρξης' },
        { value: '2', label: 'Σε εξέλιξη' },
        { value: 'trashed', label: 'Διαγραμμένα' },
      ];

        export const sitesType=[
          { value:'0', label:'Select'},
          { value: '1', label:'Website'},
          { value: '2', label:'eshop'},
          { value: '3', label:'Web App'},
          { value: '4', label:'Static Website'},
          { value: '5', label:'B2B'},
          { value: '6', label:'ThirdParty'},
          { value: '7', label:'Other'},
          { value: '8', label:'Under Construction'},
          { value: '9', label:'WP App'}
          ];  
          

          export const sitesHealthyStatus=[
            { id: 0, label:'Healthy'},
            { id: 1, label:'For Update'},
            { id: 2, label:'No sync'}
          ];
              
            export const sitesHealthyStatusColors: { [key: number]: BadgeColor } = {
            0: 'green',
            1: 'orange',
            2: 'red'
            }; 
        