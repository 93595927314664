import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Label } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

interface DropdownOption {
  id: number;
  value: string;
}

interface ComboboxComponentProps {
  id?: string;  // ID attribute for the Combobox
  name?: string;  // Name attribute for the Combobox
  options: DropdownOption[];
  selectedOption: DropdownOption | null;
  setSelectedOption: (option: DropdownOption | null) => void;
  required?: boolean;
  onChange?: (option: DropdownOption | null) => void; // Add onChange prop
}

export default function ComboboxComponent({
  id,
  name,
  options = [],
  selectedOption,
  setSelectedOption,
  required,
  onChange // Destructure onChange prop
}: ComboboxComponentProps) {
  const [query, setQuery] = useState<string>('');

  const filteredOptions = query === ''
    ? options
    : options.filter((option) => option.value.toLowerCase().includes(query.toLowerCase()));

  return (
    <Combobox
      as="div"
      value={selectedOption}
      onChange={(option: DropdownOption) => {
        setQuery('');
        setSelectedOption(option);
        if (onChange) {
          onChange(option); // Call the onChange handler
        }
      }}
      className="w-full" // Add full width to Combobox container
    >
      <Label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900"></Label>
      <div className="relative mt-2 w-full"> {/* Ensure the wrapper div is full width */}
        <ComboboxInput
          id={id}  // Apply id attribute
          name={name}  // Apply name attribute
          className="w-full 
          rounded-md border-0 bg-white 
          py-1.5 pl-3 pr-10 t
          ext-gray-900 
          shadow-sm 
          ring-1 ring-inset ring-gray-300 
          focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
          required={required}
          onChange={(event) => setQuery(event.target.value)}
          onBlur={() => setQuery('')}
          displayValue={(option: DropdownOption) => option?.value || ''}
        />
        <ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>

        {filteredOptions.length > 0 && (
          <ComboboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredOptions.map((option) => (
              <ComboboxOption
                key={option.id}
                value={option}
                className="group relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900 data-[focus]:bg-blue-600 data-[focus]:text-white"
              >
                <span className="block truncate group-data-[selected]:font-semibold">{option.value}</span>
                <span className="absolute inset-y-0 left-0 hidden items-center pl-1.5 text-blue-600 group-data-[selected]:flex group-data-[focus]:text-white">
                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </div>
    </Combobox>
  );
}
