import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/button';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '../../../components/dialog';
import { Heading } from '../../../components/heading';
import { Textarea } from '../../../components/textarea';
import { deleteContracts } from '../../../utils/apicall/contractsApi';



interface DeleteContractProps {
   contractID: number;
  open: boolean;
  onClose: () => void;
  onDeleteSuccess: () => void;
}
const ContractDelete: React.FC<DeleteContractProps> = ({ open, onClose, contractID, onDeleteSuccess }) => {
  let [isOpen, setIsOpen] = useState(false) ;
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [ContractID, setContractID] =useState(0);
  

  const handleDelete = async (contractID: number): Promise<void> => {
    try {
       const data = await deleteContracts(contractID);
      // Your async delete logic here
      console.log(`Deleting subscription with ID: ${contractID}`);
      setSuccessMessage(`Subscription with ID ${contractID} deleted successfully.`);
      setTimeout(() => {
        onClose(); // Close the dialog after the delete operation is successful
        onDeleteSuccess(); // Refresh the subscription list
      }, 2000); // Optional delay for displaying success message
     
    } catch (error) {
      setErrorMessage('Failed to delete contract');
      console.error('Failed to delete contract', error);
    }
  };



  useEffect(() => {
    setContractID(contractID);
    console.log('contractID:', contractID); //print json
  }, [contractID]);

  return (
    <div className="container mx-0 p-4">
          <Dialog open={open} onClose={onClose} size="xl">
            <DialogTitle>
              <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
                <Heading level={2}>Προσοχή διαγραφή!</Heading>
              </div>
            </DialogTitle>
              <DialogBody>
                  {successMessage && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                      <span className="block sm:inline">{successMessage}</span>
                    </div>
                  )}
                  {errorMessage && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                      <span className="block sm:inline">{errorMessage}</span>
                    </div>
                  )}
                 <p>
                      Θέλετε να διαγράψετε τo Συμβόλαιο με ID {contractID}; 
                      </p>
                      <p>   Αν θέλετε να συνεχίσετε με την διαγραφή πατήστε επιβεβαίωση. Σε διαφορετική περίπτωση ακύρωση.
                  </p>
                 
              </DialogBody>
              <DialogActions>
                <Button onClick={onClose}>Ακύρωση</Button>
                <Button color="red" onClick={() => handleDelete(contractID)}>Επιβεβαίωση</Button>
               
              </DialogActions>
          </Dialog>
  </div>
     
  );
};


export default ContractDelete;
