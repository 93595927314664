import React, { useCallback, useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import {  SubscriptionDataAll } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import {  TrashIcon } from '@heroicons/react/16/solid';
import { FieldGroup, FieldSameLine, Fieldset, Label, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { BackendDate, DisplayDate } from '../../../utils/function';
import { Select } from '../../../components/select';
import { billingCycles, paymentMethods, defaultSubscriptionData } from '../../../utils/data';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getProductsDataDropDown } from '../../../utils/apicall/productsApi';
import { getSubscriptionDetails, updateSubscription } from '../../../utils/apicall/subscriptionsApi';
import { processError } from '../../../utils/error/errorUtils';
import ComboboxComponent from '../../../components/comboboxNew';


interface DropdownOption {
  id: number;
  value: string;
}
const SubscriptionEdit: React.FC = () => {
  const [selectedCustomerID, setSelectedCustomerID] = useState<string | number | null>(null);
  const [selectedProductID, setSelectedProductID] = useState<string | number | null>(null);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {id } = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] = useState<any>();
  const [productdropdown, setProductDropdown] = useState<any>();
  const [dateN, setDateN] = useState<string | null>(null);
  const [subscription, setSubscription] = useState<SubscriptionDataAll>(defaultSubscriptionData);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<DropdownOption | null>(null);
//******************************** */
//  GET CUSTOMER DROPDOWN
//******************************** */
 
const loadCustomerDropdown = useCallback(async () => {
  try {
    const data = await getCustomersDataDropDown();
    if (data && Array.isArray(data)) {
      const options = data.map(customer => ({
        id: customer.id,
        value: customer.title,
      }));
      setCustomerDropdown(options);
    }
  } catch (error) {
    console.error('Failed to fetch customer dropdown', error);
  }
}, []);

const loadProductDropdown = useCallback(async () => {
  try {
    const data = await getProductsDataDropDown();
    if (data && Array.isArray(data)) {
      const options = data.map(product => ({
        id: product.id.toString(),
        value: product.title,
      }));
      setProductDropdown(options);
    }
  } catch (error) {
    console.error('Failed to fetch product dropdown', error);
  }
}, []);

//******************************** */
//  GET SUBSCRIPTION
//*********************************/
const loadSubscriptionDetails = useCallback(async (subscription_id: string) => {
  try {
    const data = await getSubscriptionDetails(subscription_id);
    if (data) {
      setSubscription(data);
      const selectedCustomerOption = customerdropdown.find((customer: { id: number; }) => customer.id === data.customer_id) || null;
      const selectedProductOption = productdropdown.find((product: { id: string; }) => product.id === data.product_id.toString()) || null;

      setSelectedCustomer(selectedCustomerOption);
      setSelectedProduct(selectedProductOption);

      data.start_date = DisplayDate(data.start_date) || '';
      data.next_due_date = DisplayDate(data.next_due_date!) || '';
      data.termination_date = DisplayDate(data.termination_date!) || '';

      setSubscription(data);
    }
  } catch (error) {
    console.error('Failed to fetch subscription', error);
  }
}, [customerdropdown, productdropdown]);




//******************************** */
// Handle Change
//******************************** */
const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const { name, value } = e.target;
  setSubscription(prev => ({
    ...prev,
    [name]: value
  }));
};



const onChangeDate = (e: { target: { name: any; value: any; }; })=> {

  const { name, value } = e.target;

  let valueToPass: string | number = value;
      setSubscription({
        ...subscription,
        [name]: valueToPass // Store date as YYYY-MM-DD string
      })
    
  


};

//******************* */
// Validate Form
//******************* */
  const validateForm = () => {
   
    setValidationMessage('');
    return true;
  };


//******************* */
// UPDATE FORM
//******************* */
  // save date
  
  const handleSubmit = async (e: { preventDefault: () => void; }) => {
   
    e.preventDefault();
    if( (selectedCustomer) && (selectedProduct) ){
      const selectedCustomerId = selectedCustomer.id;
      const selectedProductId = selectedProduct.id;
    //Y-m-d H:i
        const formattedstartDate = BackendDate(subscription.start_date);
        const formattednextDate = BackendDate(subscription.next_due_date||'');
        const formattedtermDate = BackendDate(subscription.termination_date||'');
        console.log('hours:',formattedstartDate);
        const id =subscription.id!;

        // Construct the payload dynamically
       const payload: SubscriptionDataAll = {
          customer_id: selectedCustomerId,
          domain: subscription.domain!,
          product_id: selectedProductId,
          quantity: subscription.quantity!,
          billing_cycle: subscription.billing_cycle!,
          start_date: formattedstartDate|| '',
          first_payment_amount: subscription.first_payment_amount!,
          payment_method: subscription.payment_method!,
          product_price: subscription.product_price!,
          id: id,
          tax_rate: subscription.tax_rate!,
          tax: subscription.tax!,
          total: subscription.total!,
          next_due_date: formattednextDate|| '',
          termination_date: formattedtermDate|| '',
          reccuring_amount:subscription.reccuring_amount!,
          status: 0,
          invoice_generation: 0,
          created_at: '',
          updated_at: '',
          deleted_at: ''
        };

        /*if (subscription.product_price) {
          payload.product_price = subscription.product_price;
        }*/

        console.log('Form detail ---', payload);



        try {
        const reposnse = await updateSubscription(id,payload);
         // setSubscription((prevItems) => [...prevItems, item]);
          setSuccessMessage('Subscription created successfully');
          setErrorMessage(null);
          console.log('Form submitted:', subscription);
        } catch (error) {
          console.error('Failed to create subscription', error);
          processError(error, setErrors, setErrorMessage, setSuccessMessage);
        }
  }
  
  };


  //******************* */
  // LOAD ALL FUNCTION
  //******************* */
  useEffect(() => {
    const fetchData = async () => {
      await loadCustomerDropdown();
      await loadProductDropdown();
    };

    fetchData();
  }, [loadCustomerDropdown, loadProductDropdown]);

  useEffect(() => {
    if (id && Array.isArray(customerdropdown) && Array.isArray(productdropdown)) {
      loadSubscriptionDetails(id);
    }
  }, [id, customerdropdown, productdropdown, loadSubscriptionDetails]);


  return (
        
    <div >
    {subscription ? (
      <>
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Επεξεργασία Συνδρομής: {subscription.domain}</Heading>
          <div className="flex gap-1">
            <Button color="red"><TrashIcon /></Button>
          </div>
        </div>
       
        <form onSubmit={handleSubmit}>
 
          <Fieldset>
          {successMessage && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{successMessage}</span>
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}
            <FieldGroup>
              <FieldSameLine className="flex items-center space-x-2">
               <Label htmlFor="id">ID</Label>
                <Input
                  id="id"
                  type="text"
                  name="id"
                  value={subscription.id}
                  invalid= {errors && errors.hasOwnProperty('id')}
                  readOnly
                  />
                     {errors && errors.hasOwnProperty('id') && <ErrorMessage>{errors.id[0]}</ErrorMessage>}
              </FieldSameLine>

              <FieldSameLine>
                <Label htmlFor="domain">Domain</Label>
                <Input
                  id="domain"
                  type="text"
                  name="domain"
                  value={subscription.domain}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('domain')}
                  />
                     {errors && errors.hasOwnProperty('domain') && <ErrorMessage>{errors.domain[0]}</ErrorMessage>}
              </FieldSameLine>


              <FieldSameLine>
              <Label htmlFor="customer_id">Πελάτης</Label>
              <ComboboxComponent
                  id="customer_id"
                  name="customer_id"
                    options={customerdropdown}
                    selectedOption={selectedCustomer}
                    setSelectedOption={setSelectedCustomer}
                   
                    />
                   {errors && errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}
             </FieldSameLine>

              <FieldSameLine>
              <Label htmlFor="product_id">Υπηρεσία</Label>
              <ComboboxComponent
                  id="product_id"
                  name="product_id"
                    options={productdropdown}
                    selectedOption={selectedProduct}
                    setSelectedOption={setSelectedProduct}
                    
                    />
                   {errors && errors.hasOwnProperty('product_id') && <ErrorMessage>{errors.product_id[0]}</ErrorMessage>}
             </FieldSameLine>


             
              <FieldSameLine>
                <Label htmlFor="product_price">Τιμή</Label>
                <Input
                  id="product_price"
                  type="text"
                  name="product_price"
                  value={subscription.product_price}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('product_price')}
                  />
                     {errors && errors.hasOwnProperty('product_price') && <ErrorMessage>{errors.product_price[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="quantity">Ποσότητα</Label>
                <Input
                  id="quantity"
                  type="number"
                  name="quantity"
                  value={subscription.quantity}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('quantity')}
                  />
                     {errors && errors.hasOwnProperty('quantity') && <ErrorMessage>{errors.quantity[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="billing_cycle">Κύκλος</Label>
                <Select
                    id="billing_cycle"
                    name="billing_cycle"
                    value={subscription.billing_cycle}
                    onChange={handleChange}
                    invalid= {errors && errors.hasOwnProperty('billing_cycle')}
                  >
                    {billingCycles.map((cycle, index) => (
                      <option key={index} value={cycle.value}>
                        {cycle.label}
                      </option>
                    ))}
            </Select>
            {errors && errors.hasOwnProperty('billing_cycle') && <ErrorMessage>{errors.billing_cycle[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="tax_rate">Συν.ΦΠΑ</Label>
                <Input
                  id="tax_rate"
                  type="text"
                  name="tax_rate"
                  value={subscription.tax_rate}
                  onChange={handleChange}
                  invalid={errors.hasOwnProperty('tax_rate')}
                  />
                    {errors.hasOwnProperty('tax_rate') && <ErrorMessage>{errors.tax_rate[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="tax">ΦΠΑ</Label>
                <Input
                  id="tax"
                  type="text"
                  name="tax"
                  value={subscription.tax}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('tax')}
                  />
                   {errors && errors.hasOwnProperty('tax') && <ErrorMessage>{errors.tax[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="total">Σύνολο</Label>
                <Input
                  id="total"
                  type="text"
                  name="total"
                  value={subscription.total}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('total')}
                  />
                    {errors && errors.hasOwnProperty('total') && <ErrorMessage>{errors.total[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="start_date"> Ημ/νια εναρξ.</Label>
                <Input
                  id="start_date"
                  type="date"
                  name="start_date"
                  value={subscription.start_date||''}
                  onChange={onChangeDate}
                  invalid= {errors && errors.hasOwnProperty('start_date')}
                  />
                    {errors && errors.hasOwnProperty('start_date') && <ErrorMessage>{errors.start_date[0]}</ErrorMessage>}
   
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="next_due_date">Επόμενη χρέωση</Label>
                <Input
                  id="next_due_date"
                  type="date"
                  name="next_due_date"
                  value={subscription.next_due_date||''}
                  onChange={onChangeDate}
                  invalid= {errors && errors.hasOwnProperty('next_due_date')}
                  />
                     {errors && errors.hasOwnProperty('next_due_date') && <ErrorMessage>{errors.next_due_date[0]}</ErrorMessage>}
   
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="termination_date">Ημ/νία διακοπής</Label>
                <Input
                  id="termination_date"
                  type="date"
                  name="termination_date"
                  value={subscription.termination_date||''}
                  onChange={onChangeDate}
                  invalid= {errors && errors.hasOwnProperty('termination_date')}
                  />
                     {errors && errors.hasOwnProperty('termination_date') && <ErrorMessage>{errors.termination_date[0]}</ErrorMessage>}
              </FieldSameLine>


              <FieldSameLine>
                <Label htmlFor="first_payment_amount">Πρώτη χρέωση</Label>
                <Input
                  id="first_payment_amount"
                  type="text"
                  name="first_payment_amount"
                  value={subscription.first_payment_amount}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('first_payment_amount')}
                  />
                     {errors && errors.hasOwnProperty('first_payment_amount') && <ErrorMessage>{errors.first_payment_amount[0]}</ErrorMessage>}
              
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="reccuring_amount">Επαναλαμβανόμενη χρεώση</Label>
                <Input
                  id="reccuring_amount"
                  type="text"
                  name="reccuring_amount"
                  value={subscription.reccuring_amount}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('reccuring_amount')}
                  />
                     {errors && errors.hasOwnProperty('reccuring_amount') && <ErrorMessage>{errors.reccuring_amount[0]}</ErrorMessage>}
              
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="payment_method">Τρόπος πληρωμής</Label>
                <Select
                  id="payment_method"
                  name="payment_method"
                  value={subscription.payment_method}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('payment_method')}
                >
                  {paymentMethods.map((method, index) => (
                    <option key={index} value={method.value}>
                      {method.label}
                    </option>
                  ))}
                </Select>
                {errors && errors.hasOwnProperty('payment_method') && <ErrorMessage>{errors.payment_method[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="status">Κατάσταση</Label>
                <Input
                  id="status"
                  type="number"
                  name="status"
                  value={subscription.status}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('status')}
                  />
                     {errors && errors.hasOwnProperty('status') && <ErrorMessage>{errors.status[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="invoice_generation">Δημ. Ειδοποιητηρίου</Label>
                <Input
                  id="invoice_generation"
                  type="number"
                  name="invoice_generation"
                  value={subscription.invoice_generation}
                  onChange={handleChange}
                  invalid= {errors && errors.hasOwnProperty('invoice_generation')}
                  />
                     {errors && errors.hasOwnProperty('invoice_generation') && <ErrorMessage>{errors.invoice_generation[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Button type="submit" color="blue">Ενημέρωση</Button>
              </FieldSameLine>
            </FieldGroup>
          </Fieldset>
        </form>
      </>
    ) : (
      <div>No subscription found.</div>
    )}
    </div>


          
  );
};


export default SubscriptionEdit;
