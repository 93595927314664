import React, { useCallback, useEffect, useState } from 'react';
import { CustomerDataDropDown,   ContractsData } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../../../components/heading';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '../../../components/table';
import { Button } from '../../../components/button';
import { PlusIcon, MagnifyingGlassIcon, ChevronUpIcon, ChevronDownIcon, FunnelIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Input, InputGroup } from '../../../components/input';
import { getCustomerTitle,  FormatDisplayDateString } from '../../../utils/function';
import ContractDelete from './contractDelete';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getContractData, getContractsDeleteData, restoredeletedContracts } from '../../../utils/apicall/contractsApi';
import ContractList from './contractsList';
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from '../../../components/dropdown';
import { Select } from '../../../components/select';
import { contractStatusFilterMenu } from '../../../utils/data';

const ContractsDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [contracts, setContracts] = useState<ContractsData[]>([]);
  const [contractsDeleted, setContractsDeleted] = useState<ContractsData[]>([]);
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredContracts, setFilteredContracts] = useState<ContractsData[]>([]);
  const [isDeleteContractOpen, setDeleteContractOpen] = useState(false);
  const [ContractID, setContractID] = useState<number>(0);
    const [selectedFilter, setSelectedFilter] = useState<string>('0');

  /*const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

   if (query) {
      const filtered = contracts.filter((contracts) => 
        Object.values(contracts).some(value =>
          value !== null && String(value).toLowerCase().includes(query)
        ) || (getCustomerTitle(contracts.customer_id!, customerdropdown).toLowerCase().includes(query))
      );
      setFilteredContracts(filtered);
    } else {
      setFilteredContracts(contracts);
    }
  };*/


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
  setSearchQuery(query);

  if (query) {
    const filtered = contracts.filter((contract) => {
      const customerTitle = getCustomerTitle(contract.customer_id!, customerdropdown);

      return (
        (customerTitle && customerTitle.toLowerCase().includes(query)) 
       
      );
    });
    setFilteredContracts(filtered);
  } else {
    setFilteredContracts(contracts);
  }
  };

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer', error);
    }
  }, []);


  const loadContract = useCallback(async () => {
    try {
      const data = await getContractData();
      setContracts(data);
      setFilteredContracts(data);
    } catch (error) {
      console.error('Failed to fetch contracts', error);
    }
  }, []);


  const loadDeletedContract = useCallback(async () => {
    try {
      const data = await getContractsDeleteData();
      setContractsDeleted(data);
      setFilteredContracts(data);
    } catch (error) {
      console.error('Failed to fetch contracts deleted' , error);
    }
  }, []);


  const handleCreate = () => {
    navigate(CONFIG.urls.getContractAddUrl());
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const valueToFilter = e.target.value;
    setSelectedFilter(valueToFilter);
    
  };

  const handleOpenDeleteContract = (id: number) => {
    setDeleteContractOpen(true);
    setContractID(id);
  };

  const handleCloseDeleteContract = () => {
    setDeleteContractOpen(false);
  };

  const handleDeleteSuccess = () => {
    loadContract();
  };

  const restoreContractDel = (id: number) => async () => {
    try {
     await restoredeletedContracts(id.toString());
     loadListByStatus('4');
    } catch (error) {
      console.error('Failed to restore Task', error);
    }
  };

  const loadListByStatus = useCallback(async (status: string) => {
    try {
      if (status === '4') {
        loadDeletedContract();
      } else if (status === '0') {
       loadContract();
      } else {
        const filtered = contracts.filter(contract => contract.status === parseInt(status));
        setFilteredContracts(filtered);
      }
    } catch (error) {
      console.error('Failed to fetch Project List By status', error);
    }
  }, [])
  
  useEffect(() => {
    loadContract();
    loadCustomerDropdown();
  }, [loadContract,loadCustomerDropdown]);

  useEffect(() => {
    loadListByStatus(selectedFilter);
}, [loadListByStatus, selectedFilter]);


const heading = selectedFilter === '4' ? 'Διαγραμμένες Συμβάσεις' : 'Συμβάσεις';

  return (
    <div className="container mx-0 p-4">
      <ContractDelete open={isDeleteContractOpen} onClose={handleCloseDeleteContract} contractID={ContractID} onDeleteSuccess={handleDeleteSuccess} />
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <Heading level={2}>{heading}</Heading>
        <div className="flex gap-1">
          <Button plain onClick={handleCreate}><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
            <DropdownMenu anchor="bottom end">
              <DropdownItem onClick={(e: { preventDefault: () => any; }) => e.preventDefault()}>
                <Select
                  id="filterbystatus"
                  name="filterbystatus"
                  value={selectedFilter}
                  onChange={handleFilterChange}
                >
                  {contractStatusFilterMenu.map((status) => (
                    <option key={status.value} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </Select>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown> 
        </div>
      </div>
    
     
      <div className="flex mt-4 mb-4">
        <InputGroup>
          <MagnifyingGlassIcon />
          <Input
            type="search"
            name="search"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search contract..."
          />
        </InputGroup>
      </div>
        <ContractList
            filteredData={filteredContracts}
            customerdropdown={customerdropdown}
            dataDeleted={false}
            onRestoreData={(ContractID: any) => restoreContractDel(ContractID!)}
          />
    </div>
  );
};

export default ContractsDashboard;
