import {ProjectsData, TasksData } from '../types';
import { defaultProjectsData, defaultTaskData,  } from '../data';
import { apiClient } from './apiClient';


//**********************************************/
// ****  Add Project
//**********************************************/

export const CreateProject = async (project: any) => {
  try {

    //console.log(' creating subscription', subscription);
    const response = await apiClient.post('/admin/projects', project);
    return response.data;
  } catch (error) {
  
    throw error;
  }
};
//**********************************************/
// ****  Get Project Details
//**********************************************/

export const getProjectsDetails = async (id: string): Promise<ProjectsData> => {
  try {
    const response = await apiClient.get<ProjectsData>(`/admin/projects/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
   
    return defaultProjectsData;
    };
  };

  /**********************************************/
// ****  Get Project List
//**********************************************/

export const getProjectsList = async (): Promise<ProjectsData[]> => {
  try {
    const response = await apiClient.get<ProjectsData[]>('/admin/projects');

    return response.data;
  } catch (error) {

    return [];
  }
};

  /**********************************************/
// ****  Get List Project By Status & deleted
//**********************************************/

export const getProjectsListByStatus = async (status_id: string): Promise<ProjectsData[]> => {
  try {
    // /api/min/projects/?status=X
    const response = await apiClient.get<ProjectsData[]>(`/admin/projects/?status=${status_id}`);
    return response.data;
  } catch (error) {
 
    return [];
  }
};


//**********************************************/
// ****  Update Project Data 
//**********************************************/
export const updateProject = async (project_id: number, item: any): Promise<ProjectsData> => {
  try {
    const response = await apiClient.put<ProjectsData>(`/admin/projects/${project_id}`, item);
    const data = response.data;
    return data;
  } catch (error) {
   
    return defaultProjectsData;
  }
};     

 //**********************************************/
// ****  Delete Project  
//**********************************************/

export const deleteProject = async (project_id: string) => {
  try {
    const response = await apiClient.delete(`/admin/projects/${project_id}`);
    const data = response.data;
    return data;
  } catch (error) {
   
    return defaultProjectsData;
    };
  };


//**********************************************/
// **** Restore Project Task  
//**********************************************/
export const restoreProjectDeleted = async (project_id: number): Promise<ProjectsData> => {
  try {
    const response = await apiClient.patch<ProjectsData>(`/admin/projects/${project_id}/restore`);
    const data = response.data;
    return data;
  } catch (error) {
  
    return defaultProjectsData;
    };
  };
