import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Badge } from '../../../components/badge';
import { Button } from '../../../components/button';
import {  getCustomerTitle, getProjectStatusName, getProjectStatusColor, getProductTitle, FormatDisplayDateString } from '../../../utils/function';
import {  CustomerDataDropDown, ProductDataDropDown, ProjectsData, SubscriptionDataAll } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { CONFIG } from '../../../utils/config';
import { TextLink } from '../../../components/text';

interface SubscriptionListProps {
  filteredData: SubscriptionDataAll[];
  customerdropdown:CustomerDataDropDown[];
  productdropdown:ProductDataDropDown[];
  dataDeleted?: boolean;
  onRestoreData?: (ID: number) => void;
  onDeleteData?: (ID: number) => void;
}

enum SortableColumn {
  Id = 'id',
  Domain = 'title',
  Customer = 'customer',
  Product = 'product',
  Next_due_date = 'next_due_date',
  Total = 'total'
}

const SubscriptionList: React.FC<SubscriptionListProps> = ({ filteredData,customerdropdown, productdropdown, dataDeleted, onRestoreData, onDeleteData }) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue: any;
        let bValue: any;
  
        switch (sortConfig.key) {
          case SortableColumn.Id:
            aValue = a.id || 0;
            bValue = b.id || 0;
            break;
          case SortableColumn.Domain:
            aValue = a.domain ?? '';
            bValue = b.domain ?? '';
            break;
          case SortableColumn.Customer:
            const customerA = getCustomerTitle(a.customer_id!, customerdropdown);
            const customerB = getCustomerTitle(b.customer_id!, customerdropdown);
            aValue = customerA;
            bValue = customerB;
            break;
          case SortableColumn.Product:
            const productA = getProductTitle(a.product_id!, productdropdown);
            const productB = getProductTitle(b.product_id!, productdropdown);
            aValue = productA;
            bValue = productB;
            break;
          case SortableColumn.Next_due_date:
            aValue = new Date(a.next_due_date ?? '').getTime();
            bValue = new Date(b.next_due_date ?? '').getTime();
            break;
          case SortableColumn.Total:
            aValue = parseFloat(a.total as any) || 0;
            bValue = parseFloat(b.total as any) || 0;
            break;
          default:
            return 0;
        }
  
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig, customerdropdown, productdropdown]);
  
  
  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
          <TableHeader onClick={() => requestSort(SortableColumn.Id)} className={getClassNamesFor(SortableColumn.Id)}>
              ID
              {sortConfig?.key === SortableColumn.Id && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Domain)} className={getClassNamesFor(SortableColumn.Domain)}>
            Domain
              {sortConfig?.key === SortableColumn.Domain && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Customer)} className={getClassNamesFor(SortableColumn.Customer)}>
              Πελάτης
              {sortConfig?.key === SortableColumn.Customer && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Product)} className={getClassNamesFor(SortableColumn.Product)}>
              Υπηρεσία
              {sortConfig?.key === SortableColumn.Product && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
           
            <TableHeader onClick={() => requestSort(SortableColumn.Next_due_date)} className={getClassNamesFor(SortableColumn.Next_due_date)}>
            Επόμενη χρέωση
              {sortConfig?.key === SortableColumn.Next_due_date && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Total)} className={getClassNamesFor(SortableColumn.Total)}>
           Σύνολο
              {sortConfig?.key === SortableColumn.Total && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader className="relative w-0">
              <span className="sr-only">Actions</span>
            </TableHeader>
            {dataDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.id}>
             
              
              {dataDeleted && onRestoreData ? (
                <>
                 <TableCell className="font-medium">{item.id}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.domain}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{getCustomerTitle(item.customer_id!, customerdropdown)}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{getProductTitle(item.product_id!, productdropdown)}</TableCell>
                </>
              ) : ( 
                <>
              <TableCell ><TextLink href={CONFIG.urls.getSubscriptionViewUrl(item.id!)}>{item.id}</TextLink></TableCell>
              <TableCell ><TextLink href={CONFIG.urls.getSubscriptionViewUrl(item.id!)}>{item.domain}</TextLink></TableCell>
              <TableCell ><TextLink href={CONFIG.urls.getCustomerViewUrl(item.customer_id!)}>{getCustomerTitle(item.customer_id, customerdropdown)}</TextLink></TableCell>
              <TableCell ><TextLink href={CONFIG.urls.getProductViewUrl(item.product_id!)}>{getProductTitle(item.product_id, productdropdown)}</TextLink></TableCell>
                </>
              )}
              
              <TableCell>{FormatDisplayDateString(item.next_due_date||'')}</TableCell>
              <TableCell >{item.total} €</TableCell>

              
              {dataDeleted && onRestoreData ? (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreData(item.id!) }}>Restore</Button>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                    <Dropdown>
                      <DropdownButton plain aria-label="More options">
                        <EllipsisVerticalIcon />
                      </DropdownButton>
                      <DropdownMenu anchor="bottom end">
                        <DropdownItem href={CONFIG.urls.getSubscriptionViewUrl(item.id!)}>View</DropdownItem>
                        <DropdownItem href={CONFIG.urls.getSubscriptionEditUrl(item.id!)}>Edit</DropdownItem>
                      
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div></div>
    </>
  );
};

export default SubscriptionList;
