// Define a custom error class for API errors
export class ApiError extends Error {
    statusCode: number;
  
    constructor(message: string, statusCode: number) {
      super(message);
      this.statusCode = statusCode;
      this.name = 'ApiError';
    }
  }
  
  // Define a custom error class for general module errors
  export class ModuleError extends Error {
    constructor(message: string) {
      super(message);
      this.name = 'ModuleError';
    }
  }
  
  // Error handler function
  export const handleError = (error: unknown): void => {
    if (error instanceof ApiError) {
      console.error(`API Error: ${error.message} (Status: ${error.statusCode})`);
      // Here, you can implement additional logic for API errors, e.g., showing notifications
    } else if (error instanceof ModuleError) {
      console.error(`Module Error: ${error.message}`);
      // Handle module-specific errors
    } else if (error instanceof Error) {
      console.error(`General Error: ${error.message}`);
      // Handle general errors
    } else {
      console.error('Unknown error occurred', error);
    }
  };
  