import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Button } from '../../../components/button';
import {   FormatDisplayDateString } from '../../../utils/function';
import {   mailogsData } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import{ HoverableRow } from '../../../components/tooltip';

interface AlertMailogsListProps {
  filteredData: mailogsData[];
  dataDeleted?: boolean;
  onRestoreData?: (ID: number) => void;
}

enum SortableColumn {
  ID = 'id',
  Recipient = 'recipient',
  Title = 'title',
  Send_date = 'send_date',
}


const AlertMailogsList: React.FC<AlertMailogsListProps> = ({ filteredData, dataDeleted, onRestoreData }) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue: any;
        let bValue: any;
        switch (sortConfig.key) {
          case SortableColumn.ID:
            aValue = a.id || 0;
            bValue = b.id || 0;
            break;
          case SortableColumn.Recipient:
            aValue = a.recipient!.toString()! ;
            bValue = b.recipient!.toString()!;
            break;
          case SortableColumn.Title:
            aValue = a.title!.toString()!;
            bValue = b.title!.toString()!;
            break;
          case SortableColumn.Send_date:
            aValue = new Date(a.send_date ?? '').getTime();
            bValue = new Date(b.send_date ?? '').getTime();
            break;
          default:
            return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig]);
  
  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
         
            <TableHeader onClick={() => requestSort(SortableColumn.Recipient)} className={getClassNamesFor(SortableColumn.Recipient)}>
            Παραλήπτης
              {sortConfig?.key === SortableColumn.Recipient&& (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Title)} className={getClassNamesFor(SortableColumn.Title)}>
            Τίτλος
              {sortConfig?.key === SortableColumn.Title && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Send_date)} className={getClassNamesFor(SortableColumn.Send_date)}>
            Ημ/νια αποστολής
              {sortConfig?.key === SortableColumn.Send_date && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader className="relative w-0">
             
            </TableHeader>
            {dataDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.id} message={item.message|| ''}> 
           
              {dataDeleted && onRestoreData ? (
                <>
               
                 <TableCell className="font-medium whitespace-normal">{item.recipient}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.title}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{FormatDisplayDateString(item.send_date||'')}</TableCell>
                
                </>
              ) : (
                <>
                 
                  <TableCell className="font-medium whitespace-normal">{item.recipient}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.title}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{FormatDisplayDateString(item.send_date||'')}</TableCell>
                
                </>
              )}
              
            
              
              {dataDeleted && onRestoreData ? (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreData(item.id!) }}>Restore</Button>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                  
                  </div>
                </TableCell>
              )}
        
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AlertMailogsList;
