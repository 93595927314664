import {  TasksData } from '../types';
import { defaultTaskData } from '../data';
import { apiClient } from './apiClient';

//**********************************************/
// ****  Add Task
//**********************************************/
export const CreateTask = async (project_id: number, item: any) => {
  try {
    const response = await apiClient.post(`/admin/projects/${project_id}/tasks`, item);
    return response.data;
  } catch (error) {

    throw error;
  }
};
//**********************************************/
// ****   Get Task Details
//**********************************************/
export const getTaskDetails = async (task_id: number, project_id: number): Promise<TasksData> => {
  try {
    const response = await apiClient.get<TasksData>(`/admin/projects/${project_id}/tasks/${task_id}`);
    const data = response.data;
    return data;
  } catch (error) {

    return defaultTaskData;
    };
  };
//**********************************************/
// ****  Get List Task 
//**********************************************/

  export const getTaskList = async (project_id: string): Promise<TasksData[]> => {
    try {
      const response = await apiClient.get<TasksData[]>(`/admin/projects/${project_id}/tasks`);
      const data = response.data;
      return data;
    } catch (error) {
  
      return [];
      };
    };

//**********************************************/
// **** Get List Task By Status
//**********************************************/
    export const getTaskListByStatus = async (project_id: number, status_id: number ): Promise<TasksData[]> => {
      try {
        const response = await apiClient.get<TasksData[]>(`/admin/projects/${project_id}/tasks/?status=${status_id}`);
        const data = response.data;
        return data;
      } catch (error) {
        return [];
        };
      };


     
 //**********************************************/
// ****  Update Task Data 
//**********************************************/
export const updateTask = async (project_id: number, task_id:number, item: any): Promise<TasksData> => {
  try {
    console.log(`Update status task with ID INSIDE : `, item);
    const response = await apiClient.put<TasksData>(`/admin/projects/${project_id}/tasks/${task_id}`, item);
    const data = response.data;
    return data;
  } catch (error) {
    return defaultTaskData;
  }
};     

     
 //**********************************************/
// ****  Delete Task  
//**********************************************/

export const deleteTask = async (project_id: number, task_id:number): Promise<TasksData> => {
  try {
    const response = await apiClient.delete<TasksData>(`/admin/projects/${project_id}/tasks/${task_id}`);
    const data = response.data;
    return data;
  } catch (error) {
    return defaultTaskData;
    };
  };

//**********************************************/
// **** List Delete Task  
//**********************************************/
  export const getTaskListDeleted = async (project_id: number ): Promise<TasksData[]> => {
    try {
      const response = await apiClient.get<TasksData[]>(`/admin/projects/${project_id}/tasks/trashed`);
      const data = response.data;
      return data;
    } catch (error) {
      return [];
      };
    };
//**********************************************/
// **** Restore Delete Task  
//**********************************************/
export const restoreTaskDeleted = async (project_id: number, task_id:number ): Promise<TasksData> => {
  try {
    const response = await apiClient.patch<TasksData>(`/admin/projects/${project_id}/tasks/${task_id}/restore`);
    const data = response.data;
    return data;
  } catch (error) {
    return defaultTaskData;
    };
  };
