import axios from 'axios';
import { CustomerData, CustomerDataDropDown } from '../types';
import { apiClient } from './apiClient';
import { defaultCustomersData } from '../data';



//**********************************************/
// ****  GET ADMINS CUSTOMER DATA
//**********************************************/


export const getCustomersData = async (): Promise<CustomerData[]> => {
  try {
    const response = await apiClient.get<CustomerData[]>('/admin/customers');

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};


export const getCustomersDataDropDown = async (): Promise<CustomerDataDropDown[]> => {
  try {
    const response = await apiClient.get<CustomerDataDropDown[]>('/admin/customers-dropdown');

    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};

//

export const syncCustomerData = async (afm: any) => {
  try {
    const response = await apiClient.get(`/admin/customer-sync/${afm}`);
    return response.data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};



export const deleteCustomerData = async (id: number) => {
  try {
    await apiClient.delete(`/admin/customers/${id}`);
  } catch (error) {
    console.error(error)
    throw error;
  }
};


//**********************************************/
// ****  UPDATE ADMINS Subscription DATA 
//**********************************************/
// Example API function to fetch subscription details
export const updateCustomer = async (id: number, item: any): Promise<CustomerData> => {
  try {
    const response = await apiClient.patch<CustomerData>(`/admin/customers/${id}`, item);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error)
    throw error;
  }
};


//**********************************************/
// ****  GET ADMINS PRODUCT DATA ONE
//**********************************************/

export const getCustomerDetail = async (id: string): Promise<CustomerData> => {
  try {
    const response = await apiClient.get<CustomerData>(`/admin/customers/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error(error)
    throw error;
    return defaultCustomersData;
  }
}

