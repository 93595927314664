import axios from 'axios';
import {DalertData } from '../types';
import { apiClient } from './apiClient';


/**********************************************/
// ****  UPDATE ADMINS ALERTS DATA 
//**********************************************/

export const getAlertsDataStatus = async (status_id: string): Promise<DalertData[]> => {
  try {
    const response = await apiClient.get<DalertData[]>(`/admin/dalerts/?status=${status_id}`);

    return response.data;
  } catch (error) {
 
    return [];
};
};

export const getAlertsDetails = async (id: string): Promise<DalertData> => {
  try {
    const response = await apiClient.get<DalertData>(`/admin/dalerts/${id}`);
    const data = response.data;
    return data;
  } catch (error) {

    throw error;
  };
};
//**********************************************/
// ****  Renew Subscription DATA 
//**********************************************/


export const renewSubscriptions = async (dalert_id: string)=> {
  try {
    const response = await apiClient.post('/admin/subscriptions/renew', {
      "dalert_id": parseInt(dalert_id)
    });
    const data = response.data;
    return data;
  } catch (error) {
  
    console.error(error)
    throw error;
    return null;
  }
};



//**********************************************/
// ****  Έκδοση Τιμολογίου 
//**********************************************/
export const createInvoice = async (id: string) => {
  try {
    const response = await apiClient.post(`/admin/create-invoice/${id}`);
    const data = response.data;
    return data;
  } catch (error) {

    throw error;
  };
};