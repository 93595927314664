import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Badge } from '../../../components/badge';
import { Button } from '../../../components/button';
import {   getHostingTitle, getUpStatusName, getUpStatusColor } from '../../../utils/function';
import {   HostingDataDropDown,  SitesData, pluginsData, DalertDetailData } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { CONFIG } from '../../../utils/config';
import { TextLink } from '../../../components/text';

interface DAlertsDetailListProps {
  filteredData: DalertDetailData[];
  dataDeleted?: boolean;
  onRestoreData?: (ID: number) => void;
}

enum SortableColumn {
  ID = 'id',
  Description = 'description',
  Amount = 'amount',
}


const DAlertsDetailList: React.FC<DAlertsDetailListProps> = ({ filteredData, dataDeleted, onRestoreData }) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue: any;
        let bValue: any;
        switch (sortConfig.key) {
          case SortableColumn.ID:
            aValue = a.id || 0;
            bValue = b.id || 0;
            break;
          case SortableColumn.Description:
            aValue = a.description!.toString()! ;
            bValue = b.description!.toString()!;
            break;
          case SortableColumn.Amount:
            aValue = parseFloat(a.amount as any) || 0;
            bValue = parseFloat(b.amount as any) || 0;
            break;
          default:
            return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig]);
  
  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
          
            <TableHeader onClick={() => requestSort(SortableColumn.Description)} className={getClassNamesFor(SortableColumn.Description)}>
            Περιγραφή
              {sortConfig?.key === SortableColumn.Description&& (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Amount)} className={getClassNamesFor(SortableColumn.Amount)}>
            Ποσό
              {sortConfig?.key === SortableColumn.Amount && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
           
            <TableHeader className="relative w-0">
             
            </TableHeader>
            {dataDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.id}>
             
              
              {dataDeleted && onRestoreData ? (
                <>
                 
                 <TableCell className="font-medium whitespace-normal">{item.description}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.amount} €</TableCell>
                
                </>
              ) : (
                <>
                 
                  <TableCell className="font-medium whitespace-normal">{item.description}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.amount} €</TableCell>
                </>
              )}
              
            
              
              {dataDeleted && onRestoreData ? (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreData(item.id!) }}>Restore</Button>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                  
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DAlertsDetailList;
