import React, { ChangeEvent, FormEvent, useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductData } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/16/solid';
import { FieldGroup, FieldSameLine, Fieldset, Label, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { getProductDetail } from '../../../utils/apicall/productsApi';
import { defaultProductData } from '../../../utils/data';

const ProductEdit: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<Map<string, string>>(new Map());
  const {id } = useParams<{ id: string }>();
  const [product, setProduct] = useState<ProductData>(defaultProductData);

//******************************** */
//  GET SUBSCRIPTION
//*********************************/
  const loadProductDetails = useCallback(async (product_id: string) => {
    try {
  
      const data = await getProductDetail(product_id);
       setProduct(data);

    } catch (error) {
      console.error('Failed to fetch subscription', error);
      // Handle error by setting subscription to an empty array
    }
  }, []);


//******************************** */
// Handle Change
//******************************** */
  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
 
    setProduct({
        ...product,
        [name]: value
      });

};


//******************* */
// Validate Form
//******************* */
const validateForm = () => {
  if (!product.id ||
    !product.title|| 
    !product.description ){
    setValidationMessage('All fields are required.');
    return false;
  }
  setValidationMessage('');
  return true;
};


//******************* */
// UPDATE FORM
//******************* */

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
   
    e.preventDefault();
    if (validateForm()) {
    }
  
  };

  //******************* */
  // LOAD ALL FUNCTION
  //******************* */
  useEffect(() => {
    if (id) {
     
      loadProductDetails(id!);
   
      
    }
  }, [id, loadProductDetails]);

  return (
        
    <div >
    {product ? (
       <>
       <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
         <Heading level={2}> {product.title}</Heading>
         <div className="flex gap-1">
        
         </div>
       </div>
      
       <form onSubmit={handleSubmit} >

         <Fieldset >
           <FieldGroup >
             <FieldSameLine >
              <Label >ID</Label>
               <Input 
                 id="id"
                 type="text"
                 name="id"
                 value={product.id}
                 readOnly // This maes the field read-only
                 invalid={errors && errors.has('id')}
                 />
                 {errors && errors.has('id') && <ErrorMessage>{errors.get('id')}</ErrorMessage>}
             </FieldSameLine>
             <FieldSameLine>
               <Label>Τίτλος</Label>
               <Input
                 id="title"
                 type="text"
                 name="dotitlemain"
                 value={product.title}
                 onChange={handleChange}
                 required
                 invalid={errors && errors.has('title')}
                  />
                  {errors && errors.has('title') && <ErrorMessage>{errors.get('title')}</ErrorMessage>}
             </FieldSameLine>
      
             <FieldSameLine>
                <Label>Περιγραφή</Label>
                <Input
                  id="description"
                  type="text"
                  name="description"
                  value={product.description}
                  onChange={handleChange}
                  required
                  invalid={errors && errors.has('description')}
                  />
                  {errors && errors.has('description') && <ErrorMessage>{errors.get('description')}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine className="flex items-center space-x-2">
                <Label>Χρέωση Μηνιαία</Label>
                <Input
                    id="monthly"
                    type="text"
                    name="monthly"
                    value={product.monthly|| ''}
                    onChange={handleChange}
                    invalid={errors && errors.has('monthly')}
                    />
                    {errors && errors.has('monthly') && <ErrorMessage>{errors.get('monthly')}</ErrorMessage>}
                
              </FieldSameLine>
              <FieldSameLine>
                <Label>Χρέωση Τριμήνου</Label>
                <Input
                        id="quadrennial"
                        type="text"
                        name="quadrennial"
                        value={product.quadrennial|| ''}
                        onChange={handleChange}
                        invalid={errors && errors.has('quadrennial')}
                        />
                        {errors && errors.has('quadrennial') && <ErrorMessage>{errors.get('quadrennial')}</ErrorMessage>}
                </FieldSameLine>
                <FieldSameLine className="flex items-center space-x-2">
                <Label>Χρέωση Εξαμήνου</Label>
                <Input
                        id="semiannuall"
                        type="text"
                        name="semiannuall"
                        value={product.semiannuall|| ''}
                        onChange={handleChange}
                        invalid={errors && errors.has('semiannuall')}
                        />
                        {errors && errors.has('semiannuall') && <ErrorMessage>{errors.get('semiannuall')}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label>Χρέωση Ετήσια</Label>
                <Input
                        id="annually"
                        type="text"
                        name="annually"
                        value={product.annually|| ''}
                        onChange={handleChange}
                        invalid={errors && errors.has('annually')}
                        />
                        {errors && errors.has('annually') && <ErrorMessage>{errors.get('annually')}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label>Χρέωση Διετής</Label>
                  <Input
                        id="biannually"
                        type="text"
                        name="biannually"
                        value={product.biannually|| ''}
                        onChange={handleChange}  
                        invalid={errors && errors.has('biannually')}
                        />
                        {errors && errors.has('biannually') && <ErrorMessage>{errors.get('biannually')}</ErrorMessage>}
                </FieldSameLine> 
              <FieldSameLine>
                <Label>Χρέωση Τριετής</Label>
                <Input
                        id="triennially"
                        type="text"
                        name="triennially"
                        value={product.triennially|| ''}
                        onChange={handleChange}  
                        invalid={errors && errors.has('triennially')}
                        />
                        {errors && errors.has('triennially') && <ErrorMessage>{errors.get('triennially')}</ErrorMessage>}

              </FieldSameLine>

              <FieldSameLine>
                <Label>Χρέωση Τετραετής</Label>
                <Input
                        id="quadrennial"
                        type="text"
                        name="quadrennial"
                        value={product.quadrennial|| ''}
                        onChange={handleChange}  
                        invalid={errors && errors.has('quadrennial')}
                        />
                        {errors && errors.has('quadrennial') && <ErrorMessage>{errors.get('quadrennial')}</ErrorMessage>}

              </FieldSameLine>

              <FieldSameLine>
                  <Label>Κωδικός Erp</Label>
                  <Input
                        id="erpcode"
                        type="text"
                        name="erpcode"
                        value={product.erpcode}
                        onChange={handleChange}  
                        invalid={errors && errors.has('erpcode')}
                        />
                        {errors && errors.has('erpcode') && <ErrorMessage>{errors.get('erpcode')}</ErrorMessage>}
              </FieldSameLine>

             <FieldSameLine>
                 <Label>ID Κατηγορίας</Label>
                 <Input
                       id="cat_id"
                       type="text"
                       name="cat_id"
                       value={product.cat_id}
                       onChange={handleChange}  
                       invalid={errors && errors.has('cat_id')}
                       />
                       {errors && errors.has('cat_id') && <ErrorMessage>{errors.get('cat_id')}</ErrorMessage>}
               </FieldSameLine>
               <FieldSameLine>
                   <Label>ERP</Label>
                   <Input
                       id="erp"
                       type="text"
                       name="erp"
                       value={product.erp}
                       onChange={handleChange}  
                       invalid={errors && errors.has('erp')}
                       />
                       {errors && errors.has('erp') && <ErrorMessage>{errors.get('erp')}</ErrorMessage>}
                </FieldSameLine>
            
              <FieldSameLine>
              <Button
              type="submit">
              Ενημέρωση
            </Button>
             </FieldSameLine>
           </FieldGroup>
         </Fieldset>
       </form>
     </>
   ) : (
     <div>No subscription found.</div>
   )}
   </div>

          
  );
};


export default ProductEdit;