import React, { useEffect, useState, useCallback } from 'react';
import { TasksData } from '../../../utils/types';
import {  FieldGroup, Fieldset, Label, FieldSameLine, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { Button } from '../../../components/button';
import { defaultTaskData, taskStatus } from '../../../utils/data';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '../../../components/dialog';
import { Heading } from '../../../components/heading';
import { BackendDate, DisplayDate } from '../../../utils/function';
import { TrashIcon, XMarkIcon, CheckIcon } from '@heroicons/react/20/solid';
import DeleteAlert from '../../common/alertdialog/deleteAlert';
import { deleteTask, getTaskDetails, updateTask } from '../../../utils/apicall/tasksApi';
import { processError } from '../../../utils/error/errorUtils';

interface TaskEditProps {
  project_id: number;
  task_id: number;
  open: boolean;
  onClose: () => void;
  onAddSuccess: () => void;
  onDeleteSuccess: () => void;
}

const TaskEditPopup: React.FC<TaskEditProps> = ({ open, onClose, onAddSuccess, onDeleteSuccess, project_id, task_id }) => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [task, setTask] = useState<TasksData>(defaultTaskData);
  const [ProjectID, setProjectID] = useState<number>(0);
  let [isAlertDeleteOpen, setIsAlertDeleteOpen] = useState(false)



  const handleCloseDeleteAlert = () => {
    setIsAlertDeleteOpen(false);
  };

  const handleAlertDeleteAccept = () => {
   
      setIsAlertDeleteOpen(false);
       handleDelete(project_id,task_id);

    }



  const loadTaskDetails = useCallback(async (id: number, project_id: number) => {
    try {
      const data = await getTaskDetails(id, project_id);
      data.due_date = DisplayDate(data.due_date!);
      data.end_date = DisplayDate(data.end_date!);
      data.start_date = DisplayDate(data.start_date!);
      setTask(data);
    } catch (error) {
      console.error('Failed to fetch task', error);
    }
  }, []);

  const validateForm = () => {
    setValidationMessage('');
    return true;
  };



  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setTask({
      ...task,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      const formattedStartDate = BackendDate(task.start_date!);
      const formattedEndDate = BackendDate(task.end_date!);
      const formattedDueDate = BackendDate(task.due_date!);
      const formattedStatus = task.status == 0 ? 1 : task.status;
      /*let payload: TasksData = {
        project_id: ProjectID,
        task_parent_id: null,
        title: task.title,
        description: task.description,
        status: task.status,
        start_date: formattedStartDate,
        due_date: formattedDueDate,
        end_date: formattedEndDate,
      };*/

      const payload: TasksData = {
        ...task,
        project_id: ProjectID,
        start_date: formattedStartDate,
        due_date: formattedDueDate,
        end_date: formattedEndDate,
        status: formattedStatus, // Use the formattedStatus here
      };

      try {
        console.log('Form submitted updated:', task);
        const response = await updateTask(ProjectID, task.id!, payload);
        setSuccessMessage('Task updated successfully');
        setTimeout(() => {
          onClose(); // Close the dialog after the delete operation is successful
          onAddSuccess(); // Refresh the subscription list
        }, 100); // Optional delay for displaying success message
       
      } catch (error) {
        processError(error, setErrors, setErrorMessage, setSuccessMessage);
      }
    }
  };

  const onChangeDate = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
    setTask({
      ...task,
      [name]: value // Store date as YYYY-MM-DD string
    });
  };

  useEffect(() => {
    if (open && task_id && project_id) {
      loadTaskDetails(task_id, project_id);
      setTask(defaultTaskData);
      setProjectID(project_id);
      setValidationMessage('');
      setSuccessMessage(null);
      setErrorMessage(null);
    }
  }, [open, project_id, task_id, loadTaskDetails]);

  const handleDelete = async (project_id: number, task_id: number): Promise<void> => {
 
    setSuccessMessage(null); // Clear any previous success messages
    try {
      const data = await deleteTask(project_id, task_id);
      console.log(`Deleting task with ID: ${task_id}`);
      setSuccessMessage(`Task with ID ${task_id} deleted successfully.`);
      setTimeout(() => {
        onClose(); // Close the dialog after the delete operation is successful
        onDeleteSuccess(); // Refresh the subscription list
      }, 100); // Optional delay for displaying success message
    } catch (error) {
      console.error('Failed to create task', error);
      processError(error, setErrors, setErrorMessage, setSuccessMessage);
    }
  };

  return (
    <div className="container mx-0 p-4">
      <Dialog open={open} onClose={onClose} size="xl" className="relative">
        <DialogTitle>
          <div className="relative flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Επεξεργασία Task</Heading>
            <Button
              onClick={onClose}
              plain
              className="absolute top-0 right-4 p-4 w-8 h-8 flex items-center justify-center  rounded-full shadow-md"
            >
              <XMarkIcon className="w-10 h-10 " style={{ color: '#1E90FF' }}/>
            </Button>
          </div>
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogBody>
            <Fieldset>
              {successMessage && (
                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                  <span className="block sm:inline">{successMessage}</span>
                </div>
              )}
              {errorMessage && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                  <span className="block sm:inline">{errorMessage}</span>
                </div>
              )}
              <FieldGroup>
                <FieldSameLine>
                  <Label htmlFor="title">Τίτλος*</Label>
                  <Input
                    id="title"
                    type="text"
                    name="title"
                    value={task.title || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('title')}
                    required
                    />
                    {errors && errors.hasOwnProperty('title') && <ErrorMessage>{errors.title[0]}</ErrorMessage>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="description">Περιγραφή</Label>
                  <Input
                    id="description"
                    type="text"
                    name="description"
                    value={task.description || ''}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('description')}
                    />
                    {errors && errors.hasOwnProperty('description') && <ErrorMessage>{errors.description[0]}</ErrorMessage>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="status">Status</Label>
                  <Select
                    id="status"
                    name="status"
                    value={task.status}
                    onChange={handleChange}
                    invalid={errors && errors.hasOwnProperty('status')}
                  >
                    {taskStatus.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                    
                  </Select>
                  {errors && errors.hasOwnProperty('status') && <ErrorMessage>{errors.status[0]}</ErrorMessage>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="start_date">Ημ/νία Έναρξης</Label>
                  <Input
                    id="start_date"
                    type="date"
                    name="start_date"
                    value={task.start_date! || ''}
                    onChange={onChangeDate}
                    invalid={errors && errors.hasOwnProperty('start_date')}
                  />
                   {errors && errors.hasOwnProperty('start_date') && <ErrorMessage>{errors.start_date[0]}</ErrorMessage>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="due_date">Ημ/νία Προθεσμίας</Label>
                  <Input
                    id="due_date"
                    type="date"
                    name="due_date"
                    value={task.due_date! || ''}
                    onChange={onChangeDate}
                    invalid={errors && errors.hasOwnProperty('due_date')}
                  />
                  {errors && errors.hasOwnProperty('due_date') && <ErrorMessage>{errors.due_date[0]}</ErrorMessage>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="end_date">Ημ/νία Λήξης</Label>
                  <Input
                    id="end_date"
                    type="date"
                    name="end_date"
                    value={task.end_date! || ''}
                    onChange={onChangeDate}
                    invalid={errors && errors.hasOwnProperty('end_date')}
                    />
                    {errors && errors.hasOwnProperty('end_date') && <ErrorMessage>{errors.end_date[0]}</ErrorMessage>}
                </FieldSameLine>
              </FieldGroup>
            </Fieldset>
          </DialogBody>

          <DialogActions>
            <Button
              //handleDelete(ProjectID, task.id!)
              onClick={() => setIsAlertDeleteOpen(true)}
               className="p-4 w-12 h-12 flex items-center justify-center"
            >
              Διαγραφή
            
            </Button>
           
                <DeleteAlert
                    open={isAlertDeleteOpen}
                    onClose={() => handleCloseDeleteAlert()}
                    title="Εστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το task?"
                    description={`Θέλετε να διαγράψετε το task με ID ${task_id}? Εαν θέλετε να προχωρήσετε, πατήστε επιβεβαίωση.`}
                    id={task_id} // Pass the ID of the item you want to delete
                    onDeleteAccept={handleAlertDeleteAccept} 
                    
                    />
              
            <Button
              type="submit"
              
              className="p-4 w-12 h-12 flex items-center justify-center"
            >
              Ενημέρωση
            </Button>
            {validationMessage && <p className="text-red-500">{validationMessage}</p>}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default TaskEditPopup;
//style={{ color: '#1E90FF' }}