// src/components/Items.tsx
import React, { useEffect, useState } from 'react';
import { CustomerData } from '../utils/types';
import { Table, TableHead, TableRow, TableHeader, TableBody, TableCell } from '../components/table';
import { Button } from '../components/button';
import PlusIcon from '@heroicons/react/20/solid/PlusIcon';
import { Heading } from '../components/heading';
import { getCustomersData } from '../utils/apicall/customersApi';


// AdminListCustomers
const AdminListUsers: React.FC = () => {
  const [users, setUsers] = useState<CustomerData[]>([]);
  const [newuser, setNewUser] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [emptyData, setEmptyData] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
//LOAD FROM ASYNC TASK
  const loadUsers = async () => {
    try {
      const data = await getCustomersData();

          if (data.length > 0) {
          //  setCustomers(data);
          } else {
            setEmptyData('No users data found.');
          }
        } catch (error) {
          setError('An error occurred while fetching customer data.');
        } finally {
          setLoading(false);
        }


  };


   
 
//LOAD 
  useEffect(() => {
    loadUsers();
  }, []);
//CHECK
        if (loading) {
          return <div>Loading...</div>;
        }

        if (error) {
          return <div>{error}</div>;
        }
        if (emptyData) {
          return  <div className="container mx-auto p-1">
                  <Heading  level={2}>Χρήστες</Heading>
                  
                  <Table>
                        <TableHead>
                          <TableRow>
                            <TableHeader>ID</TableHeader>
                            <TableHeader>Username</TableHeader>
                            <TableHeader>Email</TableHeader>
                            <TableHeader>Phone</TableHeader>
                            <TableHeader>Role</TableHeader>
                            <TableHeader>Ενέργειες</TableHeader>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users!.map((item)  => (
                            <TableRow key={item.id}>
                              <TableCell className="font-medium">{item.id}</TableCell>
                              <TableCell className="font-medium">{item.title}</TableCell>
                              <TableCell className="font-medium">{item.title}</TableCell>
                              <TableCell className="text-zinc-500">{item.city}</TableCell>
                              <TableCell className="text-zinc-500">{item.debit}</TableCell>
                              <TableCell className="text-zinc-500">
                                
                            </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
  
          <p></p>  
          </div>;
        }



  return (

    <div className="container mx-auto p-1">
    <Heading  level={2}>Χρήστες</Heading>
    <Button  color="sky"><PlusIcon />Νέος χρήστης</Button>
    <Table>
          <TableHead>
            <TableRow>
              <TableHeader>ID</TableHeader>
              <TableHeader>Username</TableHeader>
              <TableHeader>Email</TableHeader>
              <TableHeader>Phone</TableHeader>
              <TableHeader>Role</TableHeader>
              <TableHeader>Ενέργειες</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {users!.map((item)  => (
              <TableRow key={item.id}>
                <TableCell className="font-medium">{item.id}</TableCell>
                <TableCell className="font-medium">{item.title}</TableCell>
                <TableCell className="font-medium">{item.title}</TableCell>
                <TableCell className="text-zinc-500">{item.city}</TableCell>
                <TableCell className="text-zinc-500">{item.debit}</TableCell>
                <TableCell className="text-zinc-500">
                 
              </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>


</div>
);
};


export default AdminListUsers;
