import { UserData } from "../../utils/types";

// Define action types
export const SET_ROLE = 'SET_ROLE';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const RESET_ROLE = 'RESET_ROLE';
export const SET_USER_INFO = 'SET_USER_INFO';

// Define action creators

interface SetRoleAction {
  type: typeof SET_ROLE;
  payload: string;
}

interface SetAuthenticatedAction {
  type: typeof SET_AUTHENTICATED;
  payload: boolean;
}

interface ResetRoleAction {
  type: typeof RESET_ROLE;
}

interface SetUserInfoAction {
  type: typeof SET_USER_INFO;
  payload: UserData;
}

export type AuthActionTypes = SetRoleAction | SetAuthenticatedAction | ResetRoleAction|SetUserInfoAction;

export const setRole = (role: string): SetRoleAction => ({
  type: SET_ROLE,
  payload: role,
});

export const setAuthenticated = (isAuthenticated: boolean): SetAuthenticatedAction => ({
  type: SET_AUTHENTICATED,
  payload: isAuthenticated,
});

export const resetRole = (): ResetRoleAction => ({
  type: RESET_ROLE,
});

// Define action creators
export const setUserInfo = (userInfo: UserData): SetUserInfoAction  => ({
  type: SET_USER_INFO,
  payload: userInfo,
});



