import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ContractsData, CustomerDataDropDown } from '../../../utils/types';
import { Heading } from '../../../components/heading';
import { TrashIcon } from '@heroicons/react/16/solid';
import { FieldGroup, FieldSameLine, Fieldset, Label, ErrorMessage } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Button } from '../../../components/button';
import { BackendDate, DisplayDate, getCustomerTitle } from '../../../utils/function';
import { Select } from '../../../components/select';
import { defaultContractsData, contractStatus } from '../../../utils/data';
import { Textarea } from '../../../components/textarea';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getContractDetails, updateContract } from '../../../utils/apicall/contractsApi';
import { Dropdown } from '../../../components/dropdown';
import { processError } from '../../../utils/error/errorUtils';
import ComboboxComponent from '../../../components/comboboxNew';

interface DropdownOption {
  id: number;
  value: string;
}

const ContractEdit: React.FC = () => {
  const [selectedCustomerID, setSelectedCustomerID] = useState<string | number | null>(null);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { id } = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] = useState<any>();
  const [customerdropdown1, setCustomerDropdown1] = useState<CustomerDataDropDown[]>([]);
  const [contract, setContract] = useState<ContractsData>(defaultContractsData);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [contractID, setContractID] = useState<string | null>(null);
  const [newFile, setNewFile] = useState<File | null>(null);
  const [currentFileUrl, setCurrentFileUrl] = useState<string | null>('') // The current file URL
  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<DropdownOption | null>(null);
  // Load customer dropdown
  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      if (data && Array.isArray(data)) {
        const options = data.map(customer => ({
          id: customer.id,
          value: customer.title,
        }));
        setCustomerDropdown(options);
        setCustomerDropdown1(data);
      }
    } catch (error) {
      console.error('Failed to fetch customer dropdown', error);
    }
  }, []);
  

  // Load contract details
  const loadContractDetails = useCallback(async (contract_id: string) => {
    try {
      const data = await getContractDetails(contract_id);
      setContract(data);
      setSelectedCustomerID(data.customer_id);
      data.datein = DisplayDate(data.datein || '');
      data.task_date = DisplayDate(data.task_date || '');
      setCurrentFileUrl(data.contract_file_url);
      const selectedCustomerOption = customerdropdown.find((customer: { id: number; }) => customer.id === data.customer_id) || null;
      

      setSelectedCustomer(selectedCustomerOption);


    } catch (error) {
      console.error('Failed to fetch contract', error);
    }
  }, [customerdropdown]);

  // Handle form field changes
  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setContract({
      ...contract,
      [name]: value
    });
    if (name === "customer_id") {
      setSelectedCustomerID(value);
    }
  };

  // Handle date changes
  const onChangeDate = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setContract({
      ...contract,
      [name]: value
    });
  };

  // Handle file selection
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setNewFile(file);
      setCurrentFileUrl(URL.createObjectURL(file)); // Preview the new file
    }
  };


  // Validate form
  const validateForm = () => {
    setValidationMessage('');
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if ( selectedCustomer){
      try {
        const selectedCustomerId = selectedCustomer.id;
        const formData = new FormData();
        
        // Append contract data to FormData
        formData.append('id', contract.id?.toString() || '');
        formData.append('customer_id', selectedCustomerId.toString() || '');
        formData.append('description', contract.description?.toString() || '');
        formData.append('datein', BackendDate(contract.datein!)?.toString() || '');
        formData.append('doy_status', contract.doy_status?.toString() || '');
        formData.append('task_date', BackendDate(contract.task_date!)?.toString() || '');
        formData.append('netvalue', contract.netvalue?.toString() || '');
        formData.append('notes', contract.notes?.toString() || '');
        formData.append('status', contract.status?.toString() || '');
  
        // Append the new file if available
        if (newFile) {
          formData.append('contract_file', newFile);
        }
  
        // Send the FormData object with the HTTP request
        await updateContract(contract.id!, formData);

        setSuccessMessage("Ενημερώθηκε");
        setErrorMessage(null);
      } catch (error) {
       
        processError(error, setErrors, setErrorMessage, setSuccessMessage);

        console.error('setErrorMessage', setErrorMessage);


      }
    }

  };
  useEffect(() => {
    const fetchData = async () => {
      await loadCustomerDropdown();
     
    };

    fetchData();
  }, [loadCustomerDropdown]);

  useEffect(() => {
    if (id && Array.isArray(customerdropdown)) {
      setContractID(id);
      loadContractDetails(id);
    }
  }, [id, customerdropdown, loadContractDetails]);





  return (
    <div className="container ml-0 mr-auto p-4 ">
      {contract ? (
        <>
          <div className="flex w-full flex-wrap items-end justify-betwee(n gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>{getCustomerTitle(contract.customer_id!, customerdropdown1)}</Heading>
            <div className="flex gap-1">
              <Button color="red"><TrashIcon /></Button>
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <Fieldset className='bg-white'>
              <FieldGroup>
                <FieldSameLine>
                  <Label htmlFor="customer_id">Πελάτης</Label>
                  <ComboboxComponent
                  id="customer_id"
                  name="customer_id"
                    options={customerdropdown}
                    selectedOption={selectedCustomer}
                    setSelectedOption={setSelectedCustomer}
                   
                    />
                  {errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}
                </FieldSameLine>
                <FieldSameLine>
                  <Label htmlFor="description">Περιγραφή</Label>
                  <Textarea
                    id="description"
                    name="description"
                    value={contract.description || ''}
                    onChange={handleChange}
                    invalid={errors.hasOwnProperty('description')}
                  />
                  {errors.hasOwnProperty('description') && <ErrorMessage>{errors.description[0]}</ErrorMessage>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="datein">Ημ/νία Συμβολαίου*</Label>
                  <Input
                    id="datein"
                    type="date"
                    name="datein"
                    value={contract.datein || ''}
                    onChange={handleChange}
                    invalid={errors.hasOwnProperty('datein')}
                  />
                  {errors.datein && <p className="text-red-500">{errors.datein[0]}</p>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="netvalue">Κόστος</Label>
                  <Input
                    id="netvalue"
                    type="text"
                    name="netvalue"
                    value={contract.netvalue || ''}
                    onChange={handleChange}
                    invalid={errors.hasOwnProperty('netvalue')}
                  />
                  {errors.netvalue && <ErrorMessage>{errors.netvalue[0]}</ErrorMessage>}
                </FieldSameLine>

                <FieldSameLine>
                  <Label htmlFor="notes">Σημειώσεις</Label>
                  <Textarea
                    id="notes"
                    name="notes"
                    value={contract.notes || ''}
                    onChange={handleChange}
                    invalid={errors.hasOwnProperty('notes')}
                  />
                  {errors.notes && <ErrorMessage>{errors.notes[0]}</ErrorMessage>}
                </FieldSameLine>
                <FieldSameLine>
                  <Label htmlFor="status">Status</Label>
                  <Select
                    id="status"
                    name="status"
                    value={contract.status|| ''}
                    onChange={handleChange}
                    invalid={errors.hasOwnProperty('status')}
                  >
                    {contractStatus.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.label}
                      </option>
                    ))}
                  </Select>
                  {errors.status && <p className="text-red-500">{errors.status[0]}</p>}
                </FieldSameLine>
                <FieldSameLine>
                  <Label htmlFor="doy_status">ΔΟΥ status*</Label>
                  <Input
                    id="doy_status"
                    name="doy_status"
                    value={contract.doy_status|| ''}
                    onChange={handleChange}
                    invalid={errors.hasOwnProperty('doy_status')}
                  />
                  {errors.doy_status && <p className="text-red-500">{errors.doy_status[0]}</p>}
                </FieldSameLine>
                <FieldSameLine>
                    <Label htmlFor="contract_file">Συμβόλαιο Αρχείο</Label>
                    <div className="flex flex-col gap-2">
                      {/* Display the current file link or message */}
                      <Input
                        id="contract_file"
                        type="file"
                        name="contract_file"
                        onChange={handleFileChange}
                        invalid={errors.hasOwnProperty('contract_file')}
                      />
                        <div className="file-display mb-2">
                        {currentFileUrl ? (
                          <a href={currentFileUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                            {newFile ? newFile.name : currentFileUrl}
                          </a>
                        ) : (
                          <p>No file available</p>
                        )}
                      </div>
                      
    
                            {/* Display errors */}
                            {errors.contract_file && <ErrorMessage>{errors.contract_file[0]}</ErrorMessage>}
                          </div>
                        </FieldSameLine>
                <FieldSameLine>
                  <Label htmlFor="task_date">Ημερομηνία Έργου</Label>
                  <Input
                    id="task_date"
                    type="date"
                    name="task_date"
                    value={contract.task_date|| ''}
                    onChange={onChangeDate}
                    invalid={errors.hasOwnProperty('task_date')}
                  />
                  {errors.task_date && <ErrorMessage>{errors.task_date[0]}</ErrorMessage>}
                </FieldSameLine>
                <FieldSameLine>
                  <Button type="submit" color="blue">Ενημέρωση</Button>
                </FieldSameLine>
              </FieldGroup>
            </Fieldset>
          </form>
        </>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ContractEdit;
