import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectsData, TasksData, milestonesData, CustomerDataDropDown } from '../../../utils/types';
import { DescriptionList, DescriptionTerm, DescriptionDetails } from '../../../components/description-list';
import { Heading } from '../../../components/heading';
import { TextLink } from '../../../components/text';
import { Button } from '../../../components/button';
import { EllipsisVerticalIcon, FunnelIcon, TrashIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Select } from '../../../components/select';
import TaskAddPopup from '../tasks/taskAddPopup';
import TaskEditPopup from '../tasks/taskEditPopup';
import TaskUpdateStatus from '../tasks/taskUpdateStatus';
import { Table, TableBody, TableCell, TableHeader, TableRow } from '../../../components/table';
import TableHead from '@mui/material/TableHead/TableHead';
import TaskList from '../tasks/taskList';
import { taskStatusFilterMenu } from '../../../utils/data';
import { getTaskStatusColor, getTaskStatusName, getCustomerTitle, FormatDisplayDateString } from '../../../utils/function';
import { Badge } from '../../../components/badge';
import { Label } from '../../../components/fieldset';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getTaskListByStatus, getTaskListDeleted, restoreTaskDeleted } from '../../../utils/apicall/tasksApi';
import { getProjectsDetails, deleteProject } from '../../../utils/apicall/projectsApi';
import DeleteAlert from '../../common/alertdialog/deleteAlert';
import { processError } from '../../../utils/error/errorUtils';

const ProjectView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [project, setProject] = useState<ProjectsData | null>(null);
  const [tasks, setTasks] = useState<TasksData[]>([]);
  const [tasksDeleted, setTasksDeleted] = useState<TasksData[]>([]);
  const [milestonesData, setMilestones] = useState<milestonesData[]>([]);
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [isAddTaskOpen, setAddTaskOpen] = useState(false);
  const [isEditTaskOpen, setEditTaskOpen] = useState(false);
  const [ProjectID, setProjectID] = useState<number | null>(null);
  const [TaskID, setTaskID] = useState<number>(0);
  const [isUpdateStatusTaskOpen, setUpdateStatusTaskOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<number>(0);
  let [isAlertDeleteOpen, setIsAlertDeleteOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});

  const handleCloseDeleteAlert = () => {
    setIsAlertDeleteOpen(false);
  };

  const handleAlertDeleteAccept = () => {
    if (id) {
      setIsAlertDeleteOpen(false);
      handleDelete(id);
    }
  };

  const handleDelete = async (project_id: string): Promise<void> => {
    try {
      const data = await deleteProject(project_id);
      if (data.message) {
        setSuccessMessage(data.message);
        navigate(CONFIG.urls.getProjetctListUrl());
      }
    } catch (error) {
      processError(error, setErrors, setErrorMessage, setSuccessMessage);
      console.error('Failed to delete project', error);
    }
  };

 /* const handleOpenAddTask = (projectid: number) => {
    setAddTaskOpen(true);
  };*/

  const handleOpenAddTask = () => {
    if (ProjectID !== null) {
      setAddTaskOpen(true);
    }
  };

  const handleOpenEditTask = (taskid: number) => {
    if (selectedFilter === 4) {
      // Handle the case for deleted tasks
    } else {
      setTaskID(taskid);
      setEditTaskOpen(true);
    }
  };

  const handleCloseEditTask = () => {
    setEditTaskOpen(false);
  };

  const handleCloseAddTask = () => {
    setAddTaskOpen(false);
  };

  const handleAddTaskSuccess = () => {
    if (ProjectID ) {
      loadProjectDetails(ProjectID.toString());
       loadCustomerDropdown();
      
        loadTaskListStatus(ProjectID, selectedFilter);
     
    }
  };

  const handleDeleteTaskSuccess = () => {
    if (ProjectID) {
     loadProjectDetails(ProjectID.toString());
      loadCustomerDropdown();
      loadTaskListStatus(ProjectID, selectedFilter);
    }
  };

  const handleOpenUpdateStatusTask = (taskid: number) => {
    if (selectedFilter === 4) {
      // Handle the case for deleted tasks
    } else {
      console.log('handleOpenUpdateStatusTask',taskid );
      setTaskID(taskid);
      setUpdateStatusTaskOpen(true);
    }
  };

  const handleCloseUpdateStatusTask = () => {
    setUpdateStatusTaskOpen(false);
  };

  const handleUpdateStatusSuccess = () => {
    if (ProjectID) {
      loadProjectDetails(ProjectID.toString());
      loadCustomerDropdown();
      loadTaskListStatus(parseInt(ProjectID.toString(), 10), selectedFilter);
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const valueToFilter = parseInt(e.target.value, 10);
    setSelectedFilter(valueToFilter);
  };

  const loadTaskListStatus = useCallback(async (projectid: number, status: number) => {
    try {
      if (status!==0)
      {
        const data = await getTaskListByStatus(projectid, status);
        setTasks(data);
      }
      if (status==0)
      {
        const data = await getTaskListByStatus(projectid, 9);
        setTasks(data);
      }
    } catch (error) {
      console.error('Failed to fetch Task List By status', error);
    }
  }, []);

  const loadTaskListDeleted = useCallback(async (projectid: number) => {
    try {
      const data = await getTaskListDeleted(projectid);
      setTasksDeleted(data);
    } catch (error) {
      console.error('Failed to fetch Task List Deleted', error);
    }
  }, []);

  const restoreTaskDel = (projectid: number) => async (taskid: number) => {
    try {
      await restoreTaskDeleted(projectid, taskid);
      loadTaskListDeleted(projectid);
    } catch (error) {
      console.error('Failed to restore Task', error);
    }
  };

  const loadProjectDetails = useCallback(async (id: string) => {
    try {
      const data = await getProjectsDetails(id);
      if (data) {
        setProject(data);
        setProjectID(data.id ?? null); // Set ProjectID here
        setMilestones(data.milestones ?? []);
      } else {
        setProject(null);
        navigate(CONFIG.urls.getProjetctListUrl());
      }
    } catch (error) {
      console.error('Failed to fetch project', error);
      setProject(null);
      navigate(CONFIG.urls.getProjetctListUrl());
    }
  }, [navigate]);

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer dropdown', error);
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadProjectDetails(id);
    }
  }, [id, loadProjectDetails]);

  useEffect(() => {
    if (project) {
      loadCustomerDropdown();
    }
  }, [project, loadCustomerDropdown]);

  useEffect(() => {
    const defaultFilter = Object.keys(taskStatusFilterMenu)[0];
    setSelectedFilter(parseInt(defaultFilter, 10));
  }, []);

  useEffect(() => {
    if (project && selectedFilter === 4) {
      loadTaskListDeleted(project.id!);
    } else if (project && selectedFilter !== null) {
      loadTaskListStatus(project.id!, selectedFilter);
    }
  }, [project, selectedFilter, loadTaskListStatus, loadTaskListDeleted]);

  if (!project) {
    return <div className="text-center">Project not found or has been deleted.</div>;
  }

  const heading = selectedFilter === 4 ? 'Διαγραμμένα Tasks' : 'Tasks';

  return (
    <>
 
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>{project.title}</Heading>
          <div className="flex gap-1">
            <div className="-mx-3 -my-1.5 sm:-mx-2.5">
              <DeleteAlert
                open={isAlertDeleteOpen}
                onClose={() => handleCloseDeleteAlert()}
                title="Εστε βέβαιοι ότι θέλετε να διαγραψετε αυτο το έργο?"
                description={`Θέλετε να διαγράψετε το έργο με ID ${id}? Εαν θέλετε να προχωρήσετε, πατήστε επιβεβαίωση.`}
                id={parseInt(id!)}
                onDeleteAccept={handleAlertDeleteAccept}
              />
              <Dropdown>
                <DropdownButton plain aria-label="More options">
                  <EllipsisVerticalIcon />
                </DropdownButton>
                <DropdownMenu anchor="bottom end">
                  <DropdownItem href={CONFIG.urls.getProjectEditUrl(id!)}>Επεξεργασία</DropdownItem>
                  <DropdownItem onClick={() => setIsAlertDeleteOpen(true)}>Διαγραφή</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        <DescriptionList>
          <DescriptionTerm>ID</DescriptionTerm>
          <DescriptionDetails>{project.id}</DescriptionDetails>
          <DescriptionTerm>Τίτλος</DescriptionTerm>
          <DescriptionDetails><div>{project.title}</div></DescriptionDetails>
          <DescriptionTerm>Πελάτης</DescriptionTerm>
          <DescriptionDetails><TextLink href={CONFIG.urls.getCustomerViewUrl(project.customer_id!)}>{getCustomerTitle(project.customer_id!, customerdropdown)}</TextLink></DescriptionDetails>
          <DescriptionTerm>Περιγραφή</DescriptionTerm>
          <DescriptionDetails>{project.description!}</DescriptionDetails>
          <DescriptionTerm>Status</DescriptionTerm>
          <DescriptionDetails><Badge color={getTaskStatusColor(project.status!)!}>{getTaskStatusName(project.status.toString())}</Badge></DescriptionDetails>
          <DescriptionTerm>Ημ/νία έναρξης</DescriptionTerm>
          <DescriptionDetails><div>{FormatDisplayDateString(project.start_date!)}</div></DescriptionDetails>
          <DescriptionTerm>Ημ/νία προθεσμία</DescriptionTerm>
          <DescriptionDetails><div>{FormatDisplayDateString(project.due_date!)}</div></DescriptionDetails>
          <DescriptionTerm>Ημ/νία ολοκλήρωσης</DescriptionTerm>
          <DescriptionDetails><div>{FormatDisplayDateString(project.end_date!)}</div></DescriptionDetails>
          <DescriptionTerm>Σημειώσεις</DescriptionTerm>
          <DescriptionDetails><div>{project.notes}</div></DescriptionDetails>
       
       
       
       
        </DescriptionList>
 

      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
        <Heading level={2}>{heading}</Heading>
        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
          <Button plain onClick={() => handleOpenAddTask()}><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
           
            <DropdownMenu anchor="bottom end">
              <DropdownItem onClick={(e: { preventDefault: () => any; }) => e.preventDefault()}>
                <Select
                  id="filterbystatus"
                  name="filterbystatus"
                  value={selectedFilter}
                  onChange={handleFilterChange}
                >
                  {taskStatusFilterMenu.map((status, index) => (
                    <option key={index} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </Select>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>

      {selectedFilter === 4 ? (
        tasksDeleted.length > 0 ? (
          <TaskList
            tasks={tasksDeleted}
            tasksDeleted={true}
            onEditTask={handleOpenEditTask}
            onUpdateTaskStatus={handleOpenUpdateStatusTask}
            onRestoreTask={taskId => restoreTaskDel(ProjectID!)(taskId)}
          />
        ) : (
          <div className="text-center">No deleted tasks available.</div>
        )
      ) : (
        tasks.length > 0 ? (
          <TaskList
            tasks={tasks}
            tasksDeleted={false}
            onEditTask={handleOpenEditTask}
            onUpdateTaskStatus={handleOpenUpdateStatusTask}
          />
        ) : (
          <div className="text-center">No tasks available.</div>
        )
      )}

      <div className="popup-container">
        {isAddTaskOpen && ProjectID !== null && (
          <TaskAddPopup
            open={isAddTaskOpen}
            onClose={handleCloseAddTask}
            project_id={ProjectID}
            onAddSuccess={handleAddTaskSuccess}
          />
        )}
      </div>
      <div className="popup-container">
        {isEditTaskOpen && ProjectID !== null && (
          <TaskEditPopup
            open={isEditTaskOpen}
            onClose={handleCloseEditTask}
            project_id={ProjectID}
            task_id={TaskID}
            onAddSuccess={handleAddTaskSuccess}
            onDeleteSuccess={handleDeleteTaskSuccess}
          />
        )}
      </div>
      <div className={`popup-container ${isUpdateStatusTaskOpen ? 'open' : ''}`}>
        <TaskUpdateStatus
          open={isUpdateStatusTaskOpen}
          onClose={handleCloseUpdateStatusTask}
          project_id={ProjectID?.toString() ?? ''}
          task_id={TaskID.toString()}
          task_row={tasks}
          onUpdateTaskStatus={handleUpdateStatusSuccess}
        />
      </div>

      <div >
        {milestonesData.length > 0 ? (
          <>
            <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
              <Heading level={2}>Milestones</Heading>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeader>ID</TableHeader>
                  <TableHeader></TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {milestonesData.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell className="font-medium">{item.id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <div className="text-center">No milestones available.</div>
        )}
      </div>
    </>
  );
};

export default ProjectView;
/*  <DescriptionTerm>Contract ID</DescriptionTerm>
          <DescriptionDetails>{project.contract_id}</DescriptionDetails>
          <DescriptionTerm>Proposal ID</DescriptionTerm>
          <DescriptionDetails><div>{project.proposal_id}</div></DescriptionDetails>
          <DescriptionTerm>Proposal ID</DescriptionTerm>
          <DescriptionDetails><div>{project.proposal_id}</div></DescriptionDetails>   */