import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerDataDropDown, ProductDataDropDown, SubscriptionDataAll } from '../../../utils/types';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { DescriptionList, DescriptionTerm, DescriptionDetails } from '../../../components/description-list';
import { Heading } from '../../../components/heading';
import { TextLink } from '../../../components/text';
import { EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { FormatDisplayDateString, getBillingCycleLabel, getCustomerTitle, getPaymentMethodsLabel, getProductTitle } from '../../../utils/function';
import SubscriptionDelete from './subscriptionDelete';
import { defaultSubscriptionData } from '../../../utils/data';
import { getSubscriptionDetails } from '../../../utils/apicall/subscriptionsApi';
import { getProductsDataDropDown } from '../../../utils/apicall/productsApi';


const SubscriptionView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [productdropdown, setProductDropdown] = useState<ProductDataDropDown[]>([]);
  const [isDeleteSubscriptionOpen, setDeleteSubscriptionOpen] = useState(false);
  const [SubscriptionID, setSubscriptionID] = useState<number>(0);

  const [subscription, setSubscription] = useState<SubscriptionDataAll>(defaultSubscriptionData);

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer dropdown', error);
    }
  }, []);

  const loadProductDropdown = useCallback(async () => {
    try {
      const data = await getProductsDataDropDown();
      setProductDropdown(data);
    } catch (error) {
      console.error('Failed to fetch product dropdown', error);
    }
  }, []);

  const loadSubscriptionDetails = useCallback(async (subscription_id: string) => {
    try {
      const data = await getSubscriptionDetails(subscription_id);
      setSubscription(data);
      console.log('Subscription data1:', data); //print json
    } catch (error) {
      console.error('Failed to fetch subscription', error);
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadCustomerDropdown();
      loadProductDropdown();
      loadSubscriptionDetails(id);
    }
  }, [id, loadCustomerDropdown, loadProductDropdown, loadSubscriptionDetails]);

  const handleOpenDeleteSubscription = (id: number) => {
    setDeleteSubscriptionOpen(true);
    setSubscriptionID(id);
  };

  const handleCloseDeleteSubscription = () => {
    setDeleteSubscriptionOpen(false);
  };

  const handleDeleteSuccess = () => {
    loadSubscriptionDetails(id!);
  };



  return (
    <div >
      {subscription ? (
        <>
         <SubscriptionDelete open={isDeleteSubscriptionOpen} onClose={handleCloseDeleteSubscription} subscriptionID={SubscriptionID} onDeleteSuccess={handleDeleteSuccess} />
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Συνδρομή: {subscription.domain}</Heading>
            <div className="flex gap-1">
              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu anchor="bottom end">
                    <DropdownItem href={CONFIG.urls.getSubscriptionEditUrl(id!)}>Edit</DropdownItem>
                    <DropdownItem onClick={() => handleOpenDeleteSubscription(parseInt(id!))}>Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
             
            </div>
          </div>
          <DescriptionList>
            <DescriptionTerm>ID</DescriptionTerm>
            <DescriptionDetails>{subscription.id}</DescriptionDetails>

            <DescriptionTerm>DOMAIN</DescriptionTerm>
            <DescriptionDetails>{subscription.domain}</DescriptionDetails>

            <DescriptionTerm>ΠΕΛΑΤΗΣ</DescriptionTerm>
            <DescriptionDetails>
              <TextLink href={CONFIG.urls.getCustomerViewUrl(subscription.customer_id!)}>{getCustomerTitle(subscription.customer_id,customerdropdown)}</TextLink>
            </DescriptionDetails>

            <DescriptionTerm>ΠΡΟΪΟΝ/ΥΠΗΡΕΣΙΑ</DescriptionTerm>
            <DescriptionDetails>
              <TextLink href={CONFIG.urls.getProductViewUrl(subscription.product_id!)}>{getProductTitle(subscription.product_id, productdropdown)}</TextLink>
            </DescriptionDetails>

            <DescriptionTerm>ΤΙΜΗ</DescriptionTerm>
            <DescriptionDetails>{subscription.product_price}€</DescriptionDetails>

            <DescriptionTerm>ΠΟΣΟΤΗΤΑ</DescriptionTerm>
            <DescriptionDetails>{subscription.quantity}</DescriptionDetails>

            <DescriptionTerm>ΚΥΚΛΟΣ</DescriptionTerm>
            <DescriptionDetails>{getBillingCycleLabel(subscription.billing_cycle)}</DescriptionDetails>

            <DescriptionTerm>Συν.ΦΠΑ</DescriptionTerm>
            <DescriptionDetails>{subscription.tax_rate}%</DescriptionDetails>

            <DescriptionTerm>ΦΠΑ</DescriptionTerm>
            <DescriptionDetails>{subscription.tax}€</DescriptionDetails>

            <DescriptionTerm>Σύνολο</DescriptionTerm>
            <DescriptionDetails>{subscription.total}€</DescriptionDetails>

            <DescriptionTerm>ΗΜ/ΝΙΑ ΕΝΑΡΞ.</DescriptionTerm>
            <DescriptionDetails>{FormatDisplayDateString(subscription.start_date)}</DescriptionDetails>

            <DescriptionTerm>ΕΠΟΜΕΝΗ ΧΡΕΩΣΗ</DescriptionTerm>
            <DescriptionDetails>{FormatDisplayDateString(subscription.next_due_date||'')}</DescriptionDetails>

            <DescriptionTerm>ΗΜ/ΝΙΑ ΔΙΑΚΟΠΗΣ</DescriptionTerm>
            <DescriptionDetails>{FormatDisplayDateString(subscription.termination_date||'')}</DescriptionDetails>

            <DescriptionTerm>ΠΡΩΤΗ ΧΡΕΩΣΗ</DescriptionTerm>
            <DescriptionDetails>{subscription.first_payment_amount}€</DescriptionDetails>

            <DescriptionTerm>ΕΠΑΝΑΛΑΜΒΑΝΟΜΕΝΗ ΧΡΕΩΣΗ</DescriptionTerm>
            <DescriptionDetails>{subscription.product_price}€</DescriptionDetails>

            <DescriptionTerm>ΤΡΟΠΟΣ ΠΛΗΡΩΜΗΣ</DescriptionTerm>
            <DescriptionDetails>{getPaymentMethodsLabel(subscription.payment_method)}</DescriptionDetails>

            <DescriptionTerm>ΚΑΤΑΣΤΑΣΗ</DescriptionTerm>
            <DescriptionDetails>{subscription.status}</DescriptionDetails>

            <DescriptionTerm>ΔΗΜ. ΕΙΔΟΠΟΙΗΤΗΡΙΟΥ</DescriptionTerm>
            <DescriptionDetails>{subscription.invoice_generation}</DescriptionDetails>
          </DescriptionList>
        </>
      ) : (
        <div>No subscription found.</div>
      )}
    </div>
  );
};

export default SubscriptionView;
