import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProductDataDropDown, CustomerData, Users, ContractsData, ProjectsData, ProposalsData, DalertData, SubscriptionDataAll, MailogsData, CustomerDataDropDown, SitesData, HostingDataDropDown } from '../../../utils/types';
import { DescriptionList, DescriptionTerm, DescriptionDetails } from '../../../components/description-list';
import { Heading } from '../../../components/heading';
import { TextLink } from '../../../components/text';
import { Button } from '../../../components/button';
import { EllipsisHorizontalIcon, EllipsisVerticalIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { PlusIcon, FunnelIcon } from '@heroicons/react/20/solid';
import { FormatDisplayDateString, getProductTitle } from '../../../utils/function';
import { getCustomerDetail, getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getProductsDataDropDown } from '../../../utils/apicall/productsApi';
import ProjectList from '../projects/projectList';
import AlertViewContent from '../dalerts/dalertViewContentList';
import AlertListCustomer from '../dalerts/dalertListCustomer';
import SitesList from '../sites/sitesList';
import { getHostingDataDropDown } from '../../../utils/apicall/sitesApi';
import SubscriptionList from '../subscription/SubscriptionList';

const CustomerView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{id: string }>();
  const [customer, setCustomer] = useState<CustomerData>();
  const [subscription, setSubscription] = useState<SubscriptionDataAll[]>([]);
  const [Dalerts,setDalerts] = useState<DalertData[]>([]);
  const [MaiLogs,setMailLogs] = useState<MailogsData[]>([]);
  const [Projects,setProjects] = useState<ProjectsData[]>([]);
  const [Proposals,setProposals] = useState<ProposalsData[]>([]);
  const [Contracts,setContracts] = useState<ContractsData[]>([]);
  const [Sites,setSites] = useState<SitesData[]>([]);
  const [users, setUsers] = useState<Users[]>([]);
  const [productdropdown, setProductDropdown] = useState<ProductDataDropDown[]>([]);
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<ProjectsData[]>([]);
  const [hostingdropdown, setHostingDropdown] = useState<HostingDataDropDown[]>([]);

  const loadProductDropdown = async () => {
    try {
      const data = await getProductsDataDropDown();
      setProductDropdown(data);
    } catch (error) {
      console.error('Failed to fetch product', error);
    }
  };
  const loadCustomerDetails = async (id: string) => {
    try {
      //SubscriptionDataAll;
      const data = await getCustomerDetail(id);
       setCustomer(data!);
       setSubscription(data.subscriptions!);
       setProjects(data.projects || []); // Assuming `data.projects` exists
       setDalerts(data.dalerts!);
       setUsers(data.users!);
       setMailLogs(data.mailogs!);
       setSites(data.sites || []); // Assuming `data.sites` exists
       console.log('customer data1:', data); //print json
    } catch (error) {
      console.error('Failed to fetch customer', error);
      // Handle error by setting subscription to an empty array
    }
  };
  const loadHostingDropdown = useCallback(async () => {
    try {
      const data = await getHostingDataDropDown();
      setHostingDropdown(data);
    } catch (error) {
      console.error('Failed to fetch hosting', error);
    }
  }, []);
 
  useEffect(() => {
    if (id) {
      loadCustomerDetails(id);
      loadProductDropdown();
      loadHostingDropdown();
      loadCustomerDropdown();
    }
  }, [id]);


  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer', error);
    }
  }, []);


  return (
   
    <><div className="container mx-0 p-4">
      {customer ? (
        <> <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}> Πελάτης: {customer.title}</Heading>
          <div className="flex gap-1">
            <div className="-mx-3 -my-1.5 sm:-mx-2.5">
            
            </div>
          </div>
        </div>


          <DescriptionList>

            <DescriptionTerm>ID</DescriptionTerm>
            <DescriptionDetails>{customer.id}</DescriptionDetails>

            <DescriptionTerm>Πληροφορίες</DescriptionTerm>
            <DescriptionDetails><div>{customer.title} </div>
              <div> {customer.vat} </div>
              <div>{customer.email}</div> </DescriptionDetails>

            <DescriptionTerm>Είδος πελάτη</DescriptionTerm>
            <DescriptionDetails>{customer.entrytype}</DescriptionDetails>

            <DescriptionTerm>Υπόλοιπο</DescriptionTerm>
            <DescriptionDetails>{customer.debit}</DescriptionDetails>

            <DescriptionTerm>Στοιχεία Επικοινωνίας</DescriptionTerm>
            <DescriptionDetails> <div>{customer.address}</div>
              <div>{customer.city}</div>
            </DescriptionDetails>

            <DescriptionTerm>Τηλέφωνο</DescriptionTerm>
            <DescriptionDetails>{customer.phone}</DescriptionDetails>

          </DescriptionList></>
      ) : (
        <div></div>
      )}


    </div>
    
    <div >
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Συνδρομές</Heading>
          <div className="flex gap-1">
          <Button plain><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
          </Dropdown>
          </div>
        </div>
  
        <SubscriptionList
            filteredData={subscription||[]}
            customerdropdown={customerdropdown}
            productdropdown={productdropdown}
            dataDeleted={false}
          />

      </div>

     
      <div >
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Ειδοποιητήρια</Heading>
          <div className="flex gap-1">
          <Button plain><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
          </Dropdown>
          </div>
        </div>

        <AlertListCustomer filteredData={Dalerts} customerdropdown={customerdropdown} type={"1"} />

      </div>

     
      <div >
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Sites</Heading>
          <div className="flex gap-1">
          <Button plain><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
          </Dropdown>
          </div>
        </div>
  
        <SitesList
            filteredData={Sites||[]}
            customerdropdown={customerdropdown}
            hostingdropdown={hostingdropdown}
            dataDeleted={false}
          />

      </div>

      <div >
      <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Έργα</Heading>
          <div className="flex gap-1">
          <Button plain><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
          </Dropdown>
          </div>
        </div>
  
        <ProjectList
            filteredData={Projects||[]}
            customerdropdown={customerdropdown}
            dataDeleted={false}
          />

      </div>

     
      <div >
        {Proposals ? (
          <>  <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Proposals</Heading>
          <div className="flex gap-1">
          <Button plain><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
          </Dropdown>
          </div>
        </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>ID  </TableHeader>
                <TableHeader>Περιγραφή </TableHeader>
                <TableHeader>Ημ/νία</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
                {Proposals.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className="font-medium">{item.id}</TableCell>
               
                
                </TableRow>
              ))}
            </TableBody>
            </Table></>
        ) : (
          <div></div>
        )}


      </div>
      <div>
        {Contracts ? (
          <>  <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Συμβάσεις</Heading>
          <div className="flex gap-1">
          <Button plain><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
          </Dropdown>
          </div>
        </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>ID  </TableHeader>
                <TableHeader>Περιγραφή </TableHeader>
                <TableHeader>Ημ/νία</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
                {Contracts.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className="font-medium">{item.id}</TableCell>
               
                
                </TableRow>
              ))}
            </TableBody>
            </Table></>
        ) : (
          <div></div>
        )}


      </div>

      <div >
        {users ? (
          <>  <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
          <Heading level={2}>Χρήστες</Heading>
          <div className="flex gap-1">
          <Button plain><PlusIcon /></Button>
          <Dropdown>
            <DropdownButton plain aria-label="More options">
              <FunnelIcon />
            </DropdownButton>
          </Dropdown>
          </div>
        </div>

          <Table>
            <TableHead>
              <TableRow>
                <TableHeader>ID  </TableHeader>
                <TableHeader>όνομα </TableHeader>
                <TableHeader>Email </TableHeader>
                <TableHeader>Ρόλος</TableHeader>
                <TableHeader></TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
                {users.map((item) => (
                <TableRow key={item.id}>
                  <TableCell className="font-medium">{item.id}</TableCell>
                  <TableCell className="font-medium">{item.name}</TableCell>
                  <TableCell className="font-medium">{item.email}</TableCell>
                  <TableCell className="font-medium">{item.role}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            </Table></>
        ) : (
          <div></div>
        )}


      </div>
      </>

  );
};

export default CustomerView;