import React, { ReactNode, useEffect } from 'react';
import { Avatar } from '../components/avatar'
import { PiUserGearFill, PiListPlusFill, PiProjectorScreenChartFill } from "react-icons/pi";
import { AiFillProduct } from "react-icons/ai";
import { MdMarkEmailRead } from "react-icons/md";

import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../components/dropdown'
import { SidebarLayout } from '../components/sidebar-layout'
import { Navbar, NavbarItem, NavbarSection, NavbarSpacer } from '../components/navbar'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../components/sidebar'  
import * as Headless from '@headlessui/react'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  ListBulletIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
  UsersIcon
} from '@heroicons/react/16/solid'
import {
  Cog6ToothIcon,
  HomeIcon,
  InboxIcon,
  MagnifyingGlassIcon,
  MegaphoneIcon,
  QuestionMarkCircleIcon,
  SparklesIcon,
  Square2StackIcon,
  TicketIcon,
} from '@heroicons/react/20/solid'
import { useSelector } from 'react-redux';
import { RootState } from '../redux/reducers';
import { useDispatch } from 'react-redux';
import { useAuth } from '../utils/authContext';
import { setUserInfo } from '../redux/actions/authAction';
import { Divider } from '../components/divider';
import { CONFIG } from '../utils/config';


interface LayoutProps {
    children: ReactNode;
  }

const AdminLayout: React.FC<LayoutProps> = ({ children }) => {
  const dispatch = useDispatch<any>();
  const userInfo = useSelector((state: RootState) => state.auth.userInfo);

  
  useEffect(() => {
    // Check localStorage for user info on component mount
    const storedUserInfo = localStorage.getItem('userInfo');
    if (storedUserInfo) {
      dispatch(setUserInfo(JSON.parse(storedUserInfo)));
    }
  }, [dispatch]);



  return (
    <SidebarLayout
    
      navbar={
        <Navbar>
         
          <NavbarSpacer />
          <NavbarSection>
            <NavbarItem href="/search" aria-label="Search">
              <MagnifyingGlassIcon />
              </NavbarItem>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src="/profile-photo.jpg" square />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem href="/my-profile">
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/privacy-policy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/logout">
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar>
        
          <SidebarHeader>
          <img className="max-w-28 pl-3 pt-3" src="/limeframelogo2023.svg" />
          </SidebarHeader>
        
          <SidebarBody>
      
            <SidebarSection>
              <SidebarItem href="/dashboard">
                <HomeIcon />
                <SidebarLabel>Home</SidebarLabel>
              </SidebarItem>
              <Divider />
                
              <SidebarSpacer />
                  <SidebarItem href={CONFIG.urls.getCustomerListUrl()}>
                    <UserIcon />
                    <SidebarLabel>Πελάτες</SidebarLabel>
                  </SidebarItem>
                  <SidebarItem href={CONFIG.urls.getUserListUrl()}>
                    <UsersIcon />
                    <SidebarLabel>Χρήστες</SidebarLabel>
                  </SidebarItem>
                  <SidebarItem href="#">
                    <PiUserGearFill data-slot="icon" />
                    <SidebarLabel>Διαχειριστές</SidebarLabel>
                  </SidebarItem>
                  <Divider />
                  <SidebarSpacer />
                 <SidebarItem href={CONFIG.urls.getProductListUrl()}>
                    <AiFillProduct data-slot="icon"  />
                    <SidebarLabel>Υπηρεσίες</SidebarLabel>
                  </SidebarItem>
                  <SidebarItem href={CONFIG.urls.getSubscriptionListUrl()}>
                    <PiListPlusFill data-slot="icon"  />
                    <SidebarLabel>Συνδρομές</SidebarLabel>
                  </SidebarItem>
                  <SidebarItem href={CONFIG.urls.getAlertListUrl()}>
                    <MdMarkEmailRead data-slot="icon"/>
                    <SidebarLabel>Ειδοποιητήρια</SidebarLabel>
                  </SidebarItem> 
                  <Divider />
                  <SidebarSpacer />
                  <SidebarItem href={CONFIG.urls.getSitesListUrl()}>
                    <PiProjectorScreenChartFill data-slot="icon"/>
                    <SidebarLabel>Sites</SidebarLabel>
                  </SidebarItem> 
                  <SidebarItem href={CONFIG.urls.getProjetctListUrl()}>
                    <PiProjectorScreenChartFill data-slot="icon"/>
                    <SidebarLabel>Έργα</SidebarLabel>
                  </SidebarItem> 
                  <SidebarItem href={CONFIG.urls.getContractListUrl()}>
                    <PiProjectorScreenChartFill data-slot="icon"/>
                    <SidebarLabel>Συμβάσεις</SidebarLabel>
                  </SidebarItem> 
                  <Divider />
      
            </SidebarSection>
           
            <SidebarSpacer />
            <SidebarSection>
              <SidebarItem href={CONFIG.urls.getSupportUrl()}>
                <QuestionMarkCircleIcon />
                <SidebarLabel>Support</SidebarLabel>
              </SidebarItem>
              <SidebarItem href="/changelog">
                <SparklesIcon />
                <SidebarLabel>Changelog</SidebarLabel>
              </SidebarItem>
            </SidebarSection>
          </SidebarBody>
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem}>
              {userInfo ? (
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar src="/profile-photo.jpg" className="size-10" square alt="" />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">{userInfo?.user?.name ?? ''}</span>
                    <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                    {userInfo?.user?.email ?? ''}
                    </span>
                  </span>
                </span>
                ) : (
                  <div>Loading...</div> // Or some placeholder text
                )}
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem href="/my-profile">
                  <UserIcon />
                  <DropdownLabel>My profile</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/Settings">
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href="/privacy-policy">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/share-feedback">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedback</DropdownLabel>
                </DropdownItem>
                <DropdownItem href="/share-feedbackgr">
                  <LightBulbIcon />
                  <DropdownLabel>Share feedbacktretqety</DropdownLabel>
                </DropdownItem>
                <DropdownDivider />
                <DropdownItem href={CONFIG.urls.getLogoutUrl()}>
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel>Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      {/* The page content */}
   
      {children}

    </SidebarLayout>
    
  )
}

export default AdminLayout