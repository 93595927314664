import React, { useCallback, useEffect, useState } from 'react';
import {SubscriptionDataAll } from '../../../utils/types';
import {  Field, FieldGroup, Fieldset, Label, ErrorMessage, } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { Button } from '../../../components/button';
import { Heading } from '../../../components/heading';
import { BackendDate } from '../../../utils/function';
import { billingCycles, paymentMethods, defaultSubscriptionData } from '../../../utils/data';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getProductsDataDropDown } from '../../../utils/apicall/productsApi';
import { CreateSubscription } from '../../../utils/apicall/subscriptionsApi';
import { processError } from '../../../utils/error/errorUtils';
import ComboboxComponent from '../../../components/comboboxNew';

interface DropdownOption {
  id: number;
  value: string;
}

const AddSubscription: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [customerdropdown, setCustomerDropdown] = useState<any>();
  const [productdropdown, setProductDropdown] = useState<any>();
  const [newsubscription, setNewSubscription] = useState<SubscriptionDataAll>(defaultSubscriptionData);

  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);
  const [selectedProduct, setSelectedProduct] = useState<DropdownOption | null>(null);

  const loadCustomerDropdown = async () => {
    try {
      const data = await getCustomersDataDropDown();
    
      const options = data.map(customer => ({
        id: customer.id,
        value: customer.title,
      }));
      setCustomerDropdown(options);
    } catch (error) {
      console.error('Failed to fetch customer', error);
    }
  };
 
  const loadProductDropdown = async () => {
    try {
      const data = await getProductsDataDropDown();
        // Convert productdropdown to match Datalist options format
        const options = data.map(product => ({
          id: product.id,
          value: product.title,
        }));
        setProductDropdown(options);
    } catch (error) {
      console.error('Failed to fetch product', error);
    }
  };




  useEffect(() => {
    loadCustomerDropdown();
    loadProductDropdown();
   
  }, []);


  const validateForm = () => {
   

    setValidationMessage('');
    return true;
  };




  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    const { name, value } = e.target;
  
    setNewSubscription(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  const handleSubmit = async (e: { preventDefault: () => void; }) => {
   
    e.preventDefault();
    if (validateForm()) {
    //Y-m-d H:i

    setSuccessMessage(null); // Clear any previous success messages
    let payload: SubscriptionDataAll;
    console.log('Form submitted  :', newsubscription.customer_id);
    const formattedstartDate = BackendDate(newsubscription.start_date);


    
    if( (selectedCustomer) && (selectedProduct) ){
      const selectedCustomerId = selectedCustomer.id;
      const selectedProductId = selectedProduct.id;
      console.log("Selected selectedCustomerId:", selectedCustomerId);
      console.log("Selected selectedProductId:", selectedProductId);

      if (newsubscription.product_price!== undefined) {
       

        // Construct the payload dynamically
         payload = {
          customer_id: selectedCustomerId!,
          domain: newsubscription.domain!,
          product_id: selectedProductId,
          quantity: newsubscription.quantity!,
          billing_cycle: newsubscription.billing_cycle!,
          start_date:formattedstartDate|| '',
          first_payment_amount: newsubscription.first_payment_amount!,
          payment_method: newsubscription.payment_method!,
          product_price: newsubscription.product_price!
        };
      }
        else{
           payload = {
            customer_id: selectedCustomerId!,
            domain: newsubscription.domain!,
            product_id: selectedProductId,
            quantity: newsubscription.quantity!,
            billing_cycle: newsubscription.billing_cycle!,
            start_date:formattedstartDate|| '',
            first_payment_amount: newsubscription.first_payment_amount!,
            payment_method: newsubscription.payment_method!,
          };
        }
      
        console.log('Form detail ---', payload);
        try {
         
          const reposnse = await CreateSubscription(payload);
       
          setSuccessMessage('Subscription created successfully');
         
        } catch (error) {
          processError(error, setErrors, setErrorMessage, setSuccessMessage);
        }
      }else{

      }
    
  }
  
  };


  return (

      <div className="container mx-0 p-4">
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Νέα Συνδρομή</Heading>
        </div> 

        <form onSubmit={handleSubmit}>
        <Fieldset>
       
            {successMessage && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{successMessage}</span>
            </div>
          )}
          {errorMessage && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
        <FieldGroup>
          



          <Field>
            <Label htmlFor="domain">Domain*</Label>
            <Input
              id="domain"
              type="text"
              name="domain"
              value={newsubscription.domain}
              onChange={handleChange}
              invalid={errors && errors.hasOwnProperty('domain')}
              />
              {errors && errors.hasOwnProperty('domain') && <ErrorMessage>{errors.domain[0]}</ErrorMessage>}
          </Field>
    
          <Field>
            <Label htmlFor="customer_id">Πελάτης</Label>
            <ComboboxComponent
              id="customer_id"
              name="customer_id"
                options={customerdropdown}
                selectedOption={selectedCustomer}
                setSelectedOption={setSelectedCustomer}
                />
               {errors && errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}
          </Field>


        <Field>
         
           <Label htmlFor="product_id">Υπηρεσία</Label>
          <ComboboxComponent
            id="product_id"
            name="product_id"
              options={productdropdown}
              selectedOption={selectedProduct}
              setSelectedOption={setSelectedProduct}
              />
            {errors && errors.hasOwnProperty('product_id') && <ErrorMessage>{errors.product_id[0]}</ErrorMessage>}
            </Field>
          <Field>
            <Label htmlFor="product_price">
              Τιμή
            </Label>
            <Input
              id="product_price"
              type="text"
              name="product_price"
              value={newsubscription.product_price}
              onChange={handleChange}
              invalid={errors && errors.hasOwnProperty('product_price')}
              />
              {errors && errors.hasOwnProperty('product_price') && <ErrorMessage>{errors.product_price[0]}</ErrorMessage>}
          </Field>
          <Field>
              <Label htmlFor="quantity">
                Ποσότητα
              </Label>
              <Input
                id="quantity"
                type="number"
                name="quantity"
                value={newsubscription.quantity}
                onChange={handleChange}
               
                invalid={errors && errors.hasOwnProperty('quantity')}
              />
              {errors && errors.hasOwnProperty('quantity') && <ErrorMessage>{errors.quantity[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label  htmlFor="billing_cycle">
              Κύκλος Πληρωμής*
            </Label>
            <Select
              id="billing_cycle"
              name="billing_cycle"
              value={newsubscription.billing_cycle}
              onChange={handleChange}
             
              invalid={errors && errors.hasOwnProperty('billing_cycle')}
            >
              {billingCycles.map((cycle, index) => (
                <option key={index} value={cycle.value}>
                  {cycle.label}
                </option>
              ))}
            </Select>
            {errors && errors.hasOwnProperty('billing_cycle') && <ErrorMessage>{errors.billing_cycle[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label htmlFor="start_date">
              Ημερομηνία Έναρξης
            </Label>
            <Input
              id="start_date"
              type="date"
              name="start_date"
              value={newsubscription.start_date}
              onChange={handleChange}
           
              invalid={errors && errors.hasOwnProperty('start_date')}
            />
              {errors && errors.hasOwnProperty('start_date') && <ErrorMessage>{errors.start_date[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label htmlFor="first_payment_amount">
              Ποσό πρώτης χρέωσης
            </Label>
            <Input
              id="first_payment_amount"
              type="text"
              name="first_payment_amount"
              value={newsubscription.first_payment_amount}
              onChange={handleChange}  
              
              invalid={errors && errors.hasOwnProperty('first_payment_amount')}
              />
                {errors && errors.hasOwnProperty('first_payment_amount') && <ErrorMessage>{errors.first_payment_amount[0]}</ErrorMessage>}
          </Field>
          <Field>
            <Label htmlFor="payment_method">
              Τρόπος Πληρωμής
            </Label>
            <Select
                  id="payment_method"
                  name="payment_method"
                  value={newsubscription.payment_method}
                  onChange={handleChange}
                  invalid={errors && errors.hasOwnProperty('payment_method')}
                >
                  {paymentMethods.map((method, index) => (
                    <option key={index} value={method.value}>
                      {method.label}
                    </option>
                  ))}
                </Select>
                {errors && errors.hasOwnProperty('payment_method') && <ErrorMessage>{errors.payment_method[0]}</ErrorMessage>}
              </Field>
          <Field>
            <Button type="submit">
              Υποβολή
            </Button>
            {validationMessage && <p className="text-red-500">{validationMessage}</p>}
          </Field>
        </FieldGroup>
      </Fieldset>

    </form>
    </div>
  );
};


export default AddSubscription;
