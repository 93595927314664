import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerDataDropDown,  ContractsData } from '../../../utils/types';
import { DescriptionList, DescriptionTerm, DescriptionDetails } from '../../../components/description-list';
import { Heading } from '../../../components/heading';
import { EllipsisVerticalIcon } from '@heroicons/react/16/solid';
import { CONFIG } from '../../../utils/config';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { FormatDisplayDateString, getCustomerTitle } from '../../../utils/function';
import { defaultContractsData } from '../../../utils/data';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { getContractDetails } from '../../../utils/apicall/contractsApi';

const ContractView: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [customerdropdown, setCustomerDropdown] = useState<CustomerDataDropDown[]>([]);
  const [contract, setContract] = useState<ContractsData>(defaultContractsData);

  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
      setCustomerDropdown(data);
    } catch (error) {
      console.error('Failed to fetch customer dropdown', error);
    }
  }, []);


  const loadContractDetails = useCallback(async (subscription_id: string) => {
    try {
      const data = await getContractDetails(subscription_id);
      setContract(data);
      console.log('Contract data1:', data); //print json
    } catch (error) {
      console.error('Failed to fetch Contract', error);
    }
  }, []);

  useEffect(() => {
    if (id) {
      loadCustomerDropdown();
      loadContractDetails(id);
    }
  }, [id, loadCustomerDropdown, loadContractDetails]);




  return (
    <div className="container mx-0 p-4">
      {contract ? (
        <>
          <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
            <Heading level={2}>Συμβόλαιο: {getCustomerTitle(contract.customer_id!, customerdropdown)}</Heading>
            <div className="flex gap-1">
              <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                <Dropdown>
                  <DropdownButton plain aria-label="More options">
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu anchor="bottom end">
                    <DropdownItem href={CONFIG.urls.getSubscriptionEditUrl(id!)}>Edit</DropdownItem>
                    <DropdownItem href="#">Delete</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <DescriptionList>
            <DescriptionTerm>ID</DescriptionTerm>
            <DescriptionDetails>{contract.id}</DescriptionDetails>

            <DescriptionTerm>Περιγραφή</DescriptionTerm>
            <DescriptionDetails>{contract.description!}</DescriptionDetails>

            <DescriptionTerm>Ημ/νία Συμβολαίου*</DescriptionTerm>
            <DescriptionDetails> {FormatDisplayDateString(contract.datein!)||''}
            </DescriptionDetails>

            <DescriptionTerm> Κόστος</DescriptionTerm>
            <DescriptionDetails>
            {contract.netvalue!}€
            </DescriptionDetails>

            <DescriptionTerm>Σημειώσεις</DescriptionTerm>
            <DescriptionDetails>{contract.notes!}</DescriptionDetails>

            <DescriptionTerm>Status</DescriptionTerm>
            <DescriptionDetails>{contract.status!}</DescriptionDetails>

            <DescriptionTerm>ΔΟΥ Status*</DescriptionTerm>
            <DescriptionDetails>{contract.doy_status!}</DescriptionDetails>

            <DescriptionTerm>Αρχείο</DescriptionTerm>
            <DescriptionDetails>    {contract.contract_file_url && (
                <div>
                    <p><a href={contract.contract_file_url} target="_blank" rel="noopener noreferrer">View File</a></p>
                </div>
            )}</DescriptionDetails>

           
            <DescriptionTerm> Ημ/νία Υπενθύμισης για ΔΟΥ*</DescriptionTerm>
            <DescriptionDetails>{FormatDisplayDateString(contract.task_date!)}</DescriptionDetails>

          </DescriptionList>
        </>
      ) : (
        <div>No subscription found.</div>
      )}
    </div>
  );
};

export default ContractView;
