import React, { useEffect, useState, useCallback } from 'react';
import { SitesData } from '../../../utils/types';
import { Field, FieldGroup, Fieldset, Label, Legend, ErrorMessage, FieldSameLine } from '../../../components/fieldset';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { Button } from '../../../components/button';
import { Heading } from '../../../components/heading';
import { defaultSitesData, projectStatus, sitesType } from '../../../utils/data';
import { getCustomersDataDropDown } from '../../../utils/apicall/customersApi';
import { CONFIG } from '../../../utils/config';
import { processError } from '../../../utils/error/errorUtils';
import { CreateSites, getHostingDataDropDown } from '../../../utils/apicall/sitesApi';
import ComboboxComponent from '../../../components/comboboxNew';
import { Textarea } from '../../../components/textarea';
interface DropdownOption {
  id: number;
  value: string;
}

const SiteAdd: React.FC = () => {
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] }>({});
  const [data, setData] = useState<SitesData>(defaultSitesData)
  const [customerdropdown, setCustomerDropdown] = useState<any>();
  const [HostingDropdown, setHostingDropdown] = useState<any>();

  const [selectedCustomer, setSelectedCustomer] = useState<DropdownOption | null>(null);
  const [selectedHosting, setSelectedHosting] = useState<DropdownOption | null>(null);
 
  const loadCustomerDropdown = useCallback(async () => {
    try {
      const data = await getCustomersDataDropDown();
    
      const options = data.map(customer => ({
        id: customer.id,
        value: customer.title,
      }));
      setCustomerDropdown(options);
    } catch (error) {
      console.error('Failed to fetch customer', error);
    }
  }, []);

  const loadhostingDropdown = useCallback(async () => {
    try {
      const data = await getHostingDataDropDown();
      const options = data.map(data => ({
        id: data.id,
        value: data.title,
      }));
     
      setHostingDropdown(options);
    } catch (error) {
      console.error('Failed to fetch hosting', error);
    }
  }, []);

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    let valueToPass: string | number = value;

    setData({
      ...data,
      [name]: valueToPass,
    });
  };


  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if( (selectedCustomer) && (selectedHosting) ){
      const selectedCustomerId = selectedCustomer.id;
      const selectedHostingId = selectedHosting.id;

      const payload: SitesData = {
        ...data,
        customer_id: selectedCustomerId!,
        hosting_id: selectedHostingId!,
      };
    
      try {
       const response =  await CreateSites(payload);
        // Assuming 'response' contains the project data including 'projectid'
      const id = response?.id;
      console.log('id ', response);
      if (id) {
        const sitesViewUrl = CONFIG.urls.getSitesViewUrl(id);
        window.location.href = sitesViewUrl;
      }
        setSuccessMessage(null);
        setErrorMessage(null);
      } catch (error) {
        console.error('Failed to create sites', error);
        processError(error, setErrors, setErrorMessage, setSuccessMessage);
      }
    }
    
  };
  useEffect(() => {
    loadCustomerDropdown();
    loadhostingDropdown();
  }, [loadCustomerDropdown, loadhostingDropdown]);


  return (
        <div className="container mx-0 p-4">
        <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-8  dark:border-white/10">
          <Heading level={2}>Νέo Sites</Heading>
        </div>

        <form onSubmit={handleSubmit}>
          <Fieldset>

            {successMessage && (
              <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{successMessage}</span>
              </div>
            )}
            {errorMessage && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
                <span className="block sm:inline">{errorMessage}</span>
              </div>
            )}
            <FieldGroup>
              <FieldSameLine>
                <Label htmlFor="customer_id">Πελάτης*</Label>
                <ComboboxComponent
                    id="customer_id"
                    name="customer_id"
                      options={customerdropdown}
                      selectedOption={selectedCustomer}
                      setSelectedOption={setSelectedCustomer}
                      />
                    {errors && errors.hasOwnProperty('customer_id') && <ErrorMessage>{errors.customer_id[0]}</ErrorMessage>}
               
              </FieldSameLine>

              <FieldSameLine>
                <Label>
                  Τύπος
                </Label>
                <Select
                  id="type"
                  name="type"
                  value={data.type! || ''}
                  onChange={handleChange}
                  invalid={errors && errors.hasOwnProperty('type')}
                >
                  {sitesType.map((status, index) => (
                    <option key={index} value={status.value}>
                      {status.label}
                    </option>
                  ))}
                </Select>
                {errors.hasOwnProperty('type') && <ErrorMessage>{errors.type[0]}</ErrorMessage>}
              </FieldSameLine>

              <FieldSameLine>
                <Label>Domain</Label>
                <Input
                  id="domain"
                  name="domain"
                  value={data.domain! || ''}
                  onChange={handleChange}
                  invalid={errors && errors.hasOwnProperty('domain')} />
               {errors && errors.hasOwnProperty('domain') && <ErrorMessage>{errors.domain[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label>Σημειώσεiς</Label>
                <Textarea
                  id="notes"
                  name="notes"
                  data-slot="control"
                  value={data.notes! || ''}
                  onChange={handleChange}
                  invalid={errors && errors.hasOwnProperty('notes')} />
                {errors && errors.hasOwnProperty('notes') && <ErrorMessage>{errors.notes[0]}</ErrorMessage>}
              </FieldSameLine>
              <FieldSameLine>
                <Label htmlFor="hosting_id">Hosting</Label>
                <ComboboxComponent
                     id="hosting_id"
                  name="hosting_id"
                    options={HostingDropdown}
                    selectedOption={selectedHosting}
                    setSelectedOption={setSelectedHosting}
                    />
                  {errors && errors.hasOwnProperty('hosting_id') && <ErrorMessage>{errors.hosting_id[0]}</ErrorMessage>}
             
                </FieldSameLine>
              <FieldSameLine>
                <Button type="submit">
                  Υποβολή
                </Button>
                {validationMessage && <p className="text-red-500">{validationMessage}</p>}
              </FieldSameLine>
            </FieldGroup>
          </Fieldset>

        </form>
      </div>
  );
};


export default SiteAdd;


