import axios from 'axios';
import { LoginResponse } from '../types';
import { apiClient } from './apiClient';




//**********************************************/
// ****  LOGIN
//**********************************************/
export const loginApiCall = async (email: string, password: string): Promise<string | null> => {
  try {
    const response = await apiClient.post<LoginResponse>('/login', {
      email,
      password,
    });
    localStorage.setItem('token', response.data.token);
    console.log('token: ', response.data.token);
    return response.data.token;
  } catch (error) {
  
    console.error(error)
    throw error;
    return null;
  }
};


