import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthenticated, setRole, resetRole, setUserInfo } from '../redux/actions/authAction';
import { getUserData } from './apicall/userApi';



interface AuthContextType {
  isAuthenticated: boolean;
  role: string;
  loginauth: (token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch<any>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem('token');
    return !!token;
  });
  const [role, setRoleState] = useState<string>(() => {
    const storedRole = localStorage.getItem('role') || 'guest'; // Provide a default value
    return storedRole;
  });

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(setAuthenticated(true));
      dispatch(setRole(role));
    } else {
      dispatch(setAuthenticated(false));
      dispatch(resetRole());
    }
  }, [isAuthenticated, role, dispatch]);

  const loginauth = async (token: string) => {
    localStorage.setItem('token', token);
    const userData = await getUserData(token); // Assuming getUserData fetches user role
    const roleNames =userData?.roles?.join(', ') ?? '';
    localStorage.setItem('role',roleNames);
    localStorage.setItem('userInfo', JSON.stringify(userData));
    setRoleState(roleNames);
    setIsAuthenticated(true);
    dispatch(setUserInfo(userData!));
  };


  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userInfo');
    setIsAuthenticated(false);
    setRoleState('guest');
    dispatch(resetRole());
  };



  return (
    <AuthContext.Provider value={{ isAuthenticated, role, loginauth, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
