'use client'

import { clsx } from 'clsx'
import type React from 'react'
import { createContext, useContext, useState } from 'react'
import { Link } from './link'
import { Textarea } from '@headlessui/react'

const TableContext = createContext<{ bleed: boolean; dense: boolean; grid: boolean; striped: boolean }>({
  bleed: false,
  dense: false,
  grid: false,
  striped: false,
})

export function Table({
  bleed = false,
  dense = false,
  grid = false,
  striped = false,
  className,
  children,
  ...props
}: { bleed?: boolean; dense?: boolean; grid?: boolean; striped?: boolean } & React.ComponentPropsWithoutRef<'div'>) {
  return (
    <TableContext.Provider value={{ bleed, dense, grid, striped } as React.ContextType<typeof TableContext>}>
      <div className="flow-root">
        <div {...props} className={clsx(className, '-mx-[--gutter] overflow-x-auto whitespace-normal bg-white rounded-2xl')}>
          <div className={clsx('inline-block min-w-full align-middle', !bleed && 'sm:px-[--gutter]')}>
            <table className="min-w-full divide-y divide-gray-200 table-fixed">{children}</table>
          </div>
        </div>
      </div>
    </TableContext.Provider>
  )
}

export function TableHead({ className, ...props }: React.ComponentPropsWithoutRef<'thead'>) {
  return <thead {...props} className={clsx(className, 'text-zinc-500 dark:text-zinc-400')} />
}

export function TableBody(props: React.ComponentPropsWithoutRef<'tbody'>) {
  return <tbody {...props} />
}


const TableRowContext = createContext<{ href?: string; target?: string; title?: string; message?: string }>({
  href: undefined,
  target: undefined,
  title: undefined,
  message: undefined,
});

export function TableRow({
  href,
  target,
  title,
  message,
  className,
  ...props
}: { href?: string; target?: string; title?: string; message?: string } & React.ComponentPropsWithoutRef<'tr'>) {
  let { striped } = useContext(TableContext);
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const handleMouseEnter = (event: React.MouseEvent) => {
    setIsHovered(true);
    
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: rect.top + window.scrollY - 10, // Position above the row
      left: rect.left + window.scrollX + rect.width / 2, // Centered horizontally
    });
  };

  return (
    <TableRowContext.Provider value={{ href, target, title, message } as React.ContextType<typeof TableRowContext>}>
      <tr
        {...props}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsHovered(false)}
        className={clsx(
          className,
          href &&
            'has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500 dark:focus-within:bg-white/[2.5%] text-xs',
          striped && 'even:bg-zinc-950/[2.5%] dark:even:bg-white/[2.5%]',
          href && striped && 'hover:bg-zinc-950/5 dark:hover:bg-white/5',
          href && !striped && 'hover:bg-zinc-950/[2.5%] dark:hover:bg-white/[2.5%]',
          'pb-14',
          'pt-8',
          'hover:bg-gray-50',
          'relative'
        )}
      >
        {props.children}
        {isHovered && message && (
          <div
          className="fixed p-2 bg-gray-50 text-sm rounded shadow-lg z-50 border border-black-300" // Added border for outline
          style={{ top: tooltipPosition.top, left: tooltipPosition.left, transform: "translateX(-50%)" }}
          dangerouslySetInnerHTML={{ __html: message }}
          >

          </div>
        )}
      </tr>
    </TableRowContext.Provider>
  );
}






/*const TableRowContext = createContext<{ href?: string; target?: string; title?: string }>({
  href: undefined,
  target: undefined,
  title: undefined,
})

export function TableRow({
  href,
  target,
  title,
  className,
  ...props
}: { href?: string; target?: string; title?: string } & React.ComponentPropsWithoutRef<'tr'>) {
  let { striped } = useContext(TableContext)

  return (
    <TableRowContext.Provider value={{ href, target, title } as React.ContextType<typeof TableRowContext>}>
      <tr
        {...props}
        className={clsx(
          className,
          href &&
            'has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500 dark:focus-within:bg-white/[2.5%] text-xs',
          striped && 'even:bg-zinc-950/[2.5%] dark:even:bg-white/[2.5%]',
          href && striped && 'hover:bg-zinc-950/5 dark:hover:bg-white/5',
          href && !striped && 'hover:bg-zinc-950/[2.5%] dark:hover:bg-white/[2.5%]',
            'pb-14',
            'pt-8',
            'hover:bg-gray-50'
        )}
      />
    </TableRowContext.Provider>
  )
}*/

/*export function TableHeader({ className, ...props }: React.ComponentPropsWithoutRef<'th'>) {
  let { bleed, grid } = useContext(TableContext)

  return (
    <th
      {...props}
      className={clsx(
        className,
        'border-b border-b-zinc-950/10 px-4 py-2 font-medium first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] dark:border-b-white/10',
        grid && 'border-l border-l-zinc-950/5 first:border-l-0 dark:border-l-white/5',
        !bleed && 'sm:first:pl-1 sm:last:pr-1'
      )}
    />
  )
}*/

export function TableHeader({ className, children, ...props }: React.ComponentPropsWithoutRef<'th'>) {
  const { bleed, grid } = useContext(TableContext);

  return (
    <th
      {...props}
      className={clsx(
        className,
        'text-black text-sm font-black border-b border-b-zinc-950/10 px-4 py-4 font-medium first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))] dark:border-b-white/10 ',
        grid && 'border-l border-l-zinc-950/5 first:border-l-0 dark:border-l-white/5',
        !bleed && 'sm:first:pl-8 sm:last:pr-8',
        'relative' // Ensure proper positioning for child elements
      )}
    >
      <div className="flex items-center cursor-pointer">
        {children}
      </div>
    </th>
  );
}

export function TableCell({ className, children, ...props }: React.ComponentPropsWithoutRef<'td'>) {
  let { bleed, dense, grid, striped } = useContext(TableContext)
  let { href, target, title } = useContext(TableRowContext)
  let [cellRef, setCellRef] = useState<HTMLElement | null>(null)

  return (
    <td
      ref={href ? setCellRef : undefined}
      {...props}
      className={clsx(
        className,
        'text-sm text-black relative px-4 first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))]',
        !striped && 'border-b border-zinc-950/5 dark:border-white/5',
        grid && 'border-l border-l-zinc-950/5 first:border-l-0 dark:border-l-white/5',
        dense ? 'py-2.5' : 'py-2.5', // 'py-2' Ορίζει το padding ΑΡΑ ΥΨΟΣ ΓΡΑΜΜΗΣ
        !bleed && 'sm:first:pl-8 sm:last:pr-8'
      )}
    >
      {href && (
        <Link
          data-row-link
          href={href}
          target={target}
          aria-label={title}
          tabIndex={cellRef?.previousElementSibling === null ? 0 : -1}
          className="absolute inset-0 focus:outline-none"
        />
      )}
      {/* Render children normally if it's a ReactNode or using dangerouslySetInnerHTML if it's a string */}
      {typeof children === 'string' ? (
        <div dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        children
      )}
    </td>
  )


  /*export function TableCell({ className, children, ...props }: React.ComponentPropsWithoutRef<'td'>) {
    let { bleed, dense, grid, striped } = useContext(TableContext)
    let { href, target, title } = useContext(TableRowContext)
    let [cellRef, setCellRef] = useState<HTMLElement | null>(null)
  
    return (
      <td
        ref={href ? setCellRef : undefined}
        {...props}
        className={clsx(
          className,
          'text-sm text-black relative px-4 first:pl-[var(--gutter,theme(spacing.2))] last:pr-[var(--gutter,theme(spacing.2))]',
          !striped && 'border-b border-zinc-950/5 dark:border-white/5',
          grid && 'border-l border-l-zinc-950/5 first:border-l-0 dark:border-l-white/5',
          dense ? 'py-2.5' : 'py-2.5', // 'py-2' Ορίζει το padding ΑΡΑ ΥΨΟΣ ΓΡΑΜΜΗΣ
          !bleed && 'sm:first:pl-8 sm:last:pr-8'
        )}
      >
        {href && (
          <Link
            data-row-link
            href={href}
            target={target}
            aria-label={title}
            tabIndex={cellRef?.previousElementSibling === null ? 0 : -1}
            className="absolute inset-0 focus:outline-none"
          />
        )}
        {children}
      </td>
    )*/
}
