import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/table';
import { Badge } from '../../../components/badge';
import { Button } from '../../../components/button';
import {  getCustomerTitle, getProjectStatusName, getProjectStatusColor, getSitesStatusColor, getSitesStatusName, getHostingTitle, getUpStatusName, getUpStatusColor } from '../../../utils/function';
import {  CustomerDataDropDown,  HostingDataDropDown,  SitesData, themesData } from '../../../utils/types';
import { ChevronUpIcon, ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { Dropdown, DropdownButton, DropdownMenu, DropdownItem } from '../../../components/dropdown';
import { CONFIG } from '../../../utils/config';
import { TextLink } from '../../../components/text';


interface ThemesListProps {
  filteredData: themesData[];
  dataDeleted?: boolean;
  onRestoreData?: (ID: number) => void;
}

enum SortableColumn {
  ID = 'id',
  Plugin_name = 'name',
  Site_version = 'domain_version',
  Current_version = 'remote_version',
  Upstatus = 'upstatus'
}

const ThemesList: React.FC<ThemesListProps> = ({ filteredData,dataDeleted, onRestoreData }) => {
  const [sortConfig, setSortConfig] = useState<{ key: SortableColumn; direction: 'ascending' | 'descending' } | null>(null);

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue: string = '';
        let bValue: string = '';
        switch (sortConfig.key) {
          case SortableColumn.ID:
            aValue = a.id!.toString()!;
            bValue = b.id!.toString()!;
            break;
          case SortableColumn.Plugin_name:
            aValue = a.name!.toString()! ;
            bValue = b.name!.toString()!;
            break;
          case SortableColumn.Site_version:
            aValue = a.domain_version!.toString()!;
            bValue = b.domain_version!.toString()!;
            break;
          case SortableColumn.Current_version:
            aValue = a.remote_version!.toString()!;
            bValue = b.remote_version!.toString()!;
            break;
          default:
            return 0;
        }
        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig]);
  
  const requestSort = (key: SortableColumn) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (key: SortableColumn) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === key ? sortConfig.direction : undefined;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
          <TableHeader onClick={() => requestSort(SortableColumn.Plugin_name)} className={getClassNamesFor(SortableColumn.Plugin_name)}>
           ID
              {sortConfig?.key === SortableColumn.Plugin_name&& (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Plugin_name)} className={getClassNamesFor(SortableColumn.Plugin_name)}>
            Themes name
              {sortConfig?.key === SortableColumn.Plugin_name&& (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader onClick={() => requestSort(SortableColumn.Site_version)} className={getClassNamesFor(SortableColumn.Site_version)}>
            Site Version
              {sortConfig?.key === SortableColumn.Site_version && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
           
            <TableHeader onClick={() => requestSort(SortableColumn.Current_version)} className={getClassNamesFor(SortableColumn.Current_version)}>
            Current Version
              {sortConfig?.key === SortableColumn.Current_version && (
                sortConfig.direction === 'ascending' ? 
                <ChevronUpIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> :
                <ChevronDownIcon className="h-5 w-5 text-gray-400 ml-2" aria-hidden="true" />
              )}
            </TableHeader>
            <TableHeader className="relative w-0">
             
            </TableHeader>
            {dataDeleted && <TableHeader></TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((item) => (
            <TableRow key={item.id}>
             
              
              {dataDeleted && onRestoreData ? (
                <>
                 <TableCell className="font-medium">{item.id}</TableCell>
                 <TableCell className="font-medium whitespace-normal">{item.name}</TableCell>
      
                <TableCell className="font-medium whitespace-normal"> <Badge color={getUpStatusColor(item.upstatus)}>
                      {item.upstatus}
                    </Badge></TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.domain_version}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.remote_version}</TableCell>
                
                </>
              ) : (
                <>
                  <TableCell className="font-medium"><TextLink href={CONFIG.urls.getSitesViewUrl(item.id!)}>{item.id}</TextLink></TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.name}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.domain_version}</TableCell>
                  <TableCell className="font-medium whitespace-normal">{item.remote_version}</TableCell>
                  <TableCell className="font-medium whitespace-normal"> <Badge color={getUpStatusColor(item.upstatus)}>
                      {getUpStatusName(item.upstatus)}
                    </Badge></TableCell>
                </>
              )}
              
            
              
              {dataDeleted && onRestoreData ? (
                <TableCell>
                  <Button onClick={() => { console.log('Restore button clicked'); onRestoreData(item.id!) }}>Restore</Button>
                </TableCell>
              ) : (
                <TableCell>
                  <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                  
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};


export default ThemesList;
